// extracted by mini-css-extract-plugin
export var Body_Medium_XS = "typography-module--Body_Medium_XS--cadd2";
export var Interface_SemiBold_L = "typography-module--Interface_SemiBold_L--52185";
export var Interface_SemiBold_S = "typography-module--Interface_SemiBold_S--af7a9";
export var Neutral_5 = "#707070";
export var Neutral_8 = "#0C0C0C";
export var Red_5 = "#BC2323";
export var breakpointSM = "768px";
export var breakpoints = "\"@theme/breakpoints.module.css\"";
export var colors = "\"@theme/colors.module.css\"";
export var style_compact = "InputSection-module--style_compact--aa0f1";
export var style_component = "InputSection-module--style_component--69a37 typography-module--typeface_base--8d193";
export var style_content = "InputSection-module--style_content--dd8f1";
export var style_description = "InputSection-module--style_description--62821 typography-module--Body_Medium_XS--cadd2";
export var style_error = "InputSection-module--style_error--5055a typography-module--Interface_SemiBold_S--af7a9";
export var style_invalid = "InputSection-module--style_invalid--95976";
export var style_label = "InputSection-module--style_label--d7b02 typography-module--Interface_SemiBold_L--52185";
export var style_section = "InputSection-module--style_section--5c30d";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "\"@theme/typography.module.css\"";