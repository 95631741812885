import React from 'react'

import { bleachedSilk, squant } from '@theme/colors.module.css'

const EditIcon = ({
  fillColor = bleachedSilk,
  strokeColor = squant,
  ...rest
}) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15" cy="15" r="15" fill={fillColor} />
    <path d="M15 19H19" stroke={strokeColor} strokeLinejoin="round" />
    <path
      d="M17 11.2903C17.1768 11.1044 17.4166 11 17.6666 11C17.7904 11 17.913 11.0256 18.0274 11.0755C18.1418 11.1253 18.2457 11.1983 18.3333 11.2903C18.4208 11.3824 18.4903 11.4916 18.5376 11.6119C18.585 11.7321 18.6094 11.861 18.6094 11.9912C18.6094 12.1214 18.585 12.2503 18.5376 12.3705C18.4903 12.4908 18.4208 12.6 18.3333 12.6921L12.7778 18.5328L11 19.0001L11.4444 17.131L17 11.2903Z"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default EditIcon
