import React, { FunctionComponent } from 'react'

import { FaqCard } from '@components/redesign/FaqCard'
import { CardList } from '@components/redesign/TwoColumnLayout/CardList'

import { IFaqCardListProps } from './FaqCardList.types'

import { style_contact, style_contactButton } from './FaqCardList.module.css'

const FaqCardList: FunctionComponent<IFaqCardListProps> = ({
  selectedCard,
  navigateTo
}) => {
  const handleJobNavigate = slug => () => {
    navigateTo && navigateTo(slug)
  }

  return (
    <CardList selectedCard={selectedCard}>
      <FaqCard
        title="About X-Team"
        tags={['Culture', 'Values', 'Philosophy', 'Perks']}
        answers={5}
        isSelected={selectedCard === 'about-x-team'}
        onClick={handleJobNavigate('about-x-team')}
      />
      <FaqCard
        title="Applying for an X-Team job"
        tags={['Opportunities', 'Hiring Process', 'Expectations']}
        answers={7}
        isSelected={selectedCard === 'applying-for-an-x-team-job'}
        onClick={handleJobNavigate('applying-for-an-x-team-job')}
      />
      <FaqCard
        title="Working for X-Team"
        tags={['Responsibilities', 'Projects', 'Flexibilitiy', 'Partners']}
        answers={4}
        isSelected={selectedCard === 'working-for-x-team'}
        onClick={handleJobNavigate('working-for-x-team')}
      />
      <FaqCard
        title="Finances & Contracts"
        tags={['Legals', 'Hours', 'Payments', 'Taxes', 'Contracts']}
        answers={6}
        isSelected={selectedCard === 'finances-and-contracts'}
        onClick={handleJobNavigate('finances-and-contracts')}
      />
      <div className={style_contact}>
        <span>Still have unsolved questions?</span>
        <a
          href="mailto:support@x-team.com"
          target="_blank"
          rel="noreferrer"
          className={style_contactButton}
        >
          Drop us an email
        </a>
      </div>
    </CardList>
  )
}

export default FaqCardList
