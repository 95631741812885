import React from 'react'

import { Neutral_8 } from '@theme/colors.module.css'

const XTeamLogo = ({ fill = Neutral_8, ...props }) => (
  <svg
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.3075 14.0042L8.58475 0L1.66695 6.63125L9.41549 14.0587L0 23.6565L6.72257 30.0002L23.3075 14.0042Z"
      fill={fill}
    />
    <path
      d="M17.3301 21.6447L24.0821 28.1172L30.9998 21.4861L24.2476 15.0137L17.3301 21.6447Z"
      fill={fill}
    />
    <path
      d="M17.3867 6.41821L24.3047 13.0495L31.0002 6.63125L24.0823 0L17.3867 6.41821Z"
      fill={fill}
    />
  </svg>
)

export default XTeamLogo
