import React from 'react'

import { Green_4 } from '@theme/colors.module.css'

const ReviewStars = ({
  height = 30,
  width = 120,
  fill = Green_4,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    {...props}
  >
    <path
      d="M14.986 8.47434L12.4538 2.98442C12.2751 2.59699 11.7244 2.59699 11.5457 2.98442L9.01347 8.47434C8.94064 8.63224 8.791 8.74096 8.61831 8.76144L2.61459 9.47327C2.19089 9.52351 2.02073 10.0472 2.33398 10.3369L6.77269 14.4417C6.90036 14.5597 6.95752 14.7357 6.92363 14.9062L5.74537 20.8361C5.66222 21.2545 6.1077 21.5782 6.48001 21.3698L11.7555 18.4168C11.9073 18.3319 12.0922 18.3319 12.244 18.4168L17.5195 21.3698C17.8918 21.5782 18.3373 21.2545 18.2541 20.8361L17.0759 14.9062C17.042 14.7357 17.0991 14.5597 17.2268 14.4417L21.6655 10.3369C21.9788 10.0472 21.8086 9.52351 21.3849 9.47327L15.3812 8.76144C15.2085 8.74096 15.0589 8.63224 14.986 8.47434Z"
      fill="#44BB89"
    />
    <path
      d="M38.986 8.47434L36.4538 2.98442C36.2751 2.59699 35.7244 2.59699 35.5457 2.98442L33.0135 8.47434C32.9406 8.63224 32.791 8.74096 32.6183 8.76144L26.6146 9.47327C26.1909 9.52351 26.0207 10.0472 26.334 10.3369L30.7727 14.4417C30.9004 14.5597 30.9575 14.7357 30.9236 14.9062L29.7454 20.8361C29.6622 21.2545 30.1077 21.5782 30.48 21.3698L35.7555 18.4168C35.9073 18.3319 36.0922 18.3319 36.244 18.4168L41.5195 21.3698C41.8918 21.5782 42.3373 21.2545 42.2541 20.8361L41.0759 14.9062C41.042 14.7357 41.0991 14.5597 41.2268 14.4417L45.6655 10.3369C45.9788 10.0472 45.8086 9.52351 45.3849 9.47327L39.3812 8.76144C39.2085 8.74096 39.0589 8.63224 38.986 8.47434Z"
      fill="#44BB89"
    />
    <path
      d="M62.986 8.47434L60.4538 2.98442C60.2751 2.59699 59.7244 2.59699 59.5457 2.98442L57.0135 8.47434C56.9406 8.63224 56.791 8.74096 56.6183 8.76144L50.6146 9.47327C50.1909 9.52351 50.0207 10.0472 50.334 10.3369L54.7727 14.4417C54.9004 14.5597 54.9575 14.7357 54.9236 14.9062L53.7454 20.8361C53.6622 21.2545 54.1077 21.5782 54.48 21.3698L59.7555 18.4168C59.9073 18.3319 60.0922 18.3319 60.244 18.4168L65.5195 21.3698C65.8918 21.5782 66.3373 21.2545 66.2541 20.8361L65.0759 14.9062C65.042 14.7357 65.0991 14.5597 65.2268 14.4417L69.6655 10.3369C69.9788 10.0472 69.8086 9.52351 69.3849 9.47327L63.3812 8.76144C63.2085 8.74096 63.0589 8.63224 62.986 8.47434Z"
      fill="#44BB89"
    />
    <path
      d="M86.986 8.47434L84.4538 2.98442C84.2751 2.59699 83.7244 2.59699 83.5457 2.98442L81.0135 8.47434C80.9406 8.63224 80.791 8.74096 80.6183 8.76144L74.6146 9.47327C74.1909 9.52351 74.0207 10.0472 74.334 10.3369L78.7727 14.4417C78.9004 14.5597 78.9575 14.7357 78.9236 14.9062L77.7454 20.8361C77.6622 21.2545 78.1077 21.5782 78.48 21.3698L83.7555 18.4168C83.9073 18.3319 84.0922 18.3319 84.244 18.4168L89.5195 21.3698C89.8918 21.5782 90.3373 21.2545 90.2541 20.8361L89.0759 14.9062C89.042 14.7357 89.0991 14.5597 89.2268 14.4417L93.6655 10.3369C93.9788 10.0472 93.8086 9.52351 93.3849 9.47327L87.3812 8.76144C87.2085 8.74096 87.0589 8.63224 86.986 8.47434Z"
      fill="#44BB89"
    />
    <path
      d="M110.986 8.47434L108.454 2.98442C108.275 2.59699 107.724 2.59699 107.546 2.98442L105.013 8.47434C104.941 8.63224 104.791 8.74096 104.618 8.76144L98.6146 9.47327C98.1909 9.52351 98.0207 10.0472 98.334 10.3369L102.773 14.4417C102.9 14.5597 102.958 14.7357 102.924 14.9062L101.745 20.8361C101.662 21.2545 102.108 21.5782 102.48 21.3698L107.756 18.4168C107.907 18.3319 108.092 18.3319 108.244 18.4168L113.519 21.3698C113.892 21.5782 114.337 21.2545 114.254 20.8361L113.076 14.9062C113.042 14.7357 113.099 14.5597 113.227 14.4417L117.666 10.3369C117.979 10.0472 117.809 9.52351 117.385 9.47327L111.381 8.76144C111.209 8.74096 111.059 8.63224 110.986 8.47434Z"
      fill="#44BB89"
    />
  </svg>
)

export default ReviewStars
