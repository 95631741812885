import React, { FunctionComponent } from 'react'

import { Button } from '@components/redesign/Button'
import { Svg } from '@components/redesign/Svgs'

import { useMediaQuery } from '@hooks/useMediaQuery'

import { NOTIFICATION_VARIATIONS } from './NotificationBarLayout.utils'

import { INotificationBarLayout } from './NotificationBarLayout.types'

import {
  style_wrapper,
  style_icon_background,
  style_right_container,
  style_message,
  style_button_container,
  style_action_button,
  style_dismiss_button
} from './NotificationBarLayout.module.css'
import { breakpointSM } from '@theme/breakpoints.module.css'

const NotificationBarLayout: FunctionComponent<INotificationBarLayout> = ({
  variation,
  message = '',
  actionButtonText = '',
  actionButtonDataValue,
  onActionButtonClick,
  dismissButtonText,
  onDismissButtonClick
}) => {
  const isMobile = !useMediaQuery(`(min-width: ${breakpointSM})`)
  const buttonSize = isMobile ? 'small' : 'normal'
  const variationProperties = NOTIFICATION_VARIATIONS[variation]

  if (!variationProperties) {
    return null
  }

  return (
    <div
      className={`${style_wrapper} ${variationProperties.barBackgroundColorClass}`}
    >
      <div
        className={`${style_icon_background} ${variationProperties.backgroundColorClass}`}
      >
        <Svg name={variationProperties.iconName} />
      </div>
      <div className={style_right_container}>
        {message && (
          <h2
            className={`${style_message} ${variationProperties.messageColorClass}`}
          >
            {message}
          </h2>
        )}
        <div className={style_button_container}>
          {onActionButtonClick && (
            <Button
              className={`${style_action_button} ${variationProperties.backgroundColorClass}`}
              onClick={onActionButtonClick}
              size={buttonSize}
              data-value={actionButtonDataValue}
            >
              {actionButtonText || `Provide 'actionButtonText' prop`}
            </Button>
          )}
          {onDismissButtonClick && (
            <Button
              className={style_dismiss_button}
              color={'secondary'}
              outlined
              onClick={onDismissButtonClick}
              size={buttonSize}
              data-testid="NotificationBarLayout-dismiss"
            >
              {dismissButtonText || `DON’T SHOW AGAIN`}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
export default NotificationBarLayout
