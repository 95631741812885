import React, {
  FunctionComponent,
  ChangeEvent,
  useLayoutEffect,
  useState
} from 'react'
import { useForm } from 'react-hook-form'

import { FormLayoutContainer } from '@containers/FormLayoutContainer'
import { LinkedInButtonContainer } from '@containers/LinkedInButtonContainer'
import { ButtonNew } from '@components/redesign/ButtonNew'
import { Divider } from '@components/redesign/FormLayout/Divider'
import { InputFieldNew } from '@components/redesign/InputFieldNew'
import { InputSection } from '@components/redesign/InputSection'
import { ValidationList } from '@components/redesign/ValidationList'

import { useAuthContext, ISignUpArgs } from '@hooks/useAuthContext'
import { useUTMParamsContext } from '@hooks/useUTMParamsContext'
import { useValidationItems } from '@hooks/useValidationItems'
import {
  size,
  emailValidationPattern,
  replacePlaceholders,
  getLocalStorage,
  getAuthErrorMessage
} from '@utils/helpers'

import { ICreateAccountFormProps } from './CreateAccountForm.types'

const CreateAccountForm: FunctionComponent<ICreateAccountFormProps> = ({
  handleAuthenticatedSession
}) => {
  const localStorage = getLocalStorage()

  const { utmParams } = useUTMParamsContext()
  const { error: authError, signUp, clearErrors } = useAuthContext()
  const {
    register,
    handleSubmit,
    errors: formErrors,
    getValues
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange'
  })
  const { validationItems, handlePasswordChange, validateRules } =
    useValidationItems()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const hasAuthErrors = size(authError) > 0
  const hasFormErrors = size(formErrors) > 0
  const canEdit = !isSubmitting
  const canSubmit = !hasFormErrors && canEdit

  const getErrorMessage = () => {
    if (hasAuthErrors) {
      return (
        getAuthErrorMessage(authError) ||
        'Something went wrong, please try again'
      )
    }
  }

  const handleOnSubmit = async (params: ISignUpArgs) => {
    setIsSubmitting(true)
    // Update profile with UTM data after we successfully signed up
    await signUp({ ...params, utmParams }, () => {
      handleAuthenticatedSession?.()
    })
    setIsSubmitting(false)
  }

  useLayoutEffect(() => {
    clearErrors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLinkedinProfileChange = (event: ChangeEvent<HTMLInputElement>) =>
    localStorage.setItem(
      'useLinkedinPhoto',
      event.target?.checked ? 'YES' : 'NO'
    )

  const emailErrorMessage = formErrors?.email?.message
  const fullNameErrorMessage = formErrors?.fullName?.message
  const passwordFieldValue = getValues('password')
  const passwordErrorMessage = formErrors?.password
    ? passwordFieldValue?.length === 0
      ? 'Password is required.'
      : formErrors?.password?.message ||
        'Your password doesn\'t respect our requirements.'
    : undefined

  const updatedEmailErrorMessage = replacePlaceholders(emailErrorMessage, {
    '{{Log in}}': <a href="/?login">Log In</a>,
    '{{Contact Support}}': (
      <a href="mailto:support@x-team.com">Contact Support</a>
    )
  })

  return (
    <FormLayoutContainer
      onSubmit={handleSubmit(handleOnSubmit)}
      error={getErrorMessage()}
    >
      <LinkedInButtonContainer label="Create account with LinkedIn" />
      <Divider isBlank />
      <InputFieldNew
        type="checkbox"
        placeholder="Allow X-Team to use your LinkedIn photo"
        onChange={handleLinkedinProfileChange}
      />
      <Divider label="Or, register with your email" />
      <InputSection
        label="Email address"
        invalid={
          updatedEmailErrorMessage ? <>{updatedEmailErrorMessage}</> : null
        }
        compact
      >
        <InputFieldNew
          name="email"
          placeholder="Email address"
          disabled={!canEdit}
          invalid={formErrors.email}
          validation={register({
            required: 'Email address is required.',
            pattern: emailValidationPattern
          })}
        />
      </InputSection>
      <InputSection label="Full name" invalid={fullNameErrorMessage} compact>
        <InputFieldNew
          name="fullName"
          placeholder="Full name"
          disabled={!canEdit}
          invalid={formErrors.fullName}
          validation={register({
            required: 'Full name is required.',
            validate: (value: string) => Boolean(value.trim())
          })}
        />
      </InputSection>
      <InputSection label="Password" invalid={passwordErrorMessage} compact>
        <InputFieldNew
          name="password"
          placeholder="Password"
          type="password"
          disabled={!canEdit}
          invalid={formErrors.password}
          validation={register({
            validate: validateRules
          })}
          onChange={e => handlePasswordChange(e, formErrors?.password)}
        />
      </InputSection>
      {passwordFieldValue && (
        <InputSection compact>
          <ValidationList items={validationItems} />
        </InputSection>
      )}
      <Divider isBlank />
      <ButtonNew
        data-value="create-account-modal-get-started-button"
        block
        disabled={!canSubmit}
        type="submit"
        uppercase={false}
        size="large"
      >
        {isSubmitting ? 'Creating account...' : 'Get started'}
      </ButtonNew>
      <Divider isBlank />
      <p>
        By signing up, you agree to X-Team&apos;s{' '}
        <a
          href="https://x-team.com/blog/privacy-policy/"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        .
      </p>
      <Divider />
      <p>
        <InputFieldNew
          name="jobAlertsNotification"
          data-value="job-alerts-checkbox"
          type="checkbox"
          placeholder="I want to receive job opportunities."
          disabled={!canEdit}
          validation={register()}
        />
      </p>
      <p>
        You can opt out of receiving these at any time in your account settings.
      </p>
    </FormLayoutContainer>
  )
}

export default CreateAccountForm
