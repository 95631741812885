import React, { FunctionComponent } from 'react'

import {
  availableColors,
  availableSizes,
  availableTypes,
  colorsClassNames,
  sizeClassNames
} from './ButtonNew.constants'

import { IButtonNewProps, ButtonNewTypes } from './ButtonNew.types'

import {
  style_component,
  style_outlined,
  style_pill,
  style_block,
  style_block_link,
  style_block_link_inner,
  style_href,
  style_whiteBackground,
  style_uppercase
} from './ButtonNew.module.css'

const ButtonNew: FunctionComponent<IButtonNewProps> = ({
  onClick,
  children,
  color = 'default',
  disabled = false,
  outlined = false,
  pill = false,
  size = 'normal',
  block = false,
  type = 'button',
  whiteBackground = false,
  uppercase = true,
  className = '',
  testid,
  href,
  target,
  rel,
  ...rest
}) => {
  if (!children) {
    return null
  }

  const validColor = availableColors.includes(color)
    ? color
    : availableColors[0]
  const colorClassName =
    outlined && colorsClassNames[`${validColor}_outlined`]
      ? `${validColor}_outlined`
      : validColor

  const buttonType = availableTypes.includes(type)
    ? type
    : (availableTypes[0] as ButtonNewTypes)

  const classNames = [
    style_component,
    availableSizes.includes(size)
      ? sizeClassNames[size]
      : sizeClassNames.normal,
    colorsClassNames[colorClassName],
    block ? style_block : '',
    block && color === 'link' ? style_block_link : '',
    pill ? style_pill : '',
    href ? style_href : '',
    outlined ? style_outlined : '',
    whiteBackground ? style_whiteBackground : '',
    uppercase ? style_uppercase : '',
    className
  ].join(' ')

  const renderChildren = () =>
    block && color === 'link' ? (
      <span className={style_block_link_inner}>{children}</span>
    ) : (
      children
    )

  if (href) {
    return (
      <a
        href={href}
        className={classNames}
        target={target}
        rel={rel}
        onClick={onClick}
      >
        {renderChildren()}
      </a>
    )
  }

  return (
    <button
      className={classNames}
      data-testid={testid || 'ButtonNew'}
      onClick={onClick}
      disabled={disabled}
      type={buttonType}
      {...rest}
    >
      {renderChildren()}
    </button>
  )
}

export default ButtonNew
