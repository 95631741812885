import React, { FunctionComponent } from 'react'
import { navigate } from 'gatsby'
import { findIndex, findLastIndex } from 'lodash'

import { NotificationBarLayout } from '@components/redesign/NotificationBarLayout'

import { size, getNotificationMessage } from '@utils/helpers'
import { NOTIFICATION_TYPES } from '@config/constants'

import { INotificationBarProps } from './NotificationBar.types'

const NotificationBar: FunctionComponent<INotificationBarProps> = ({
  visibleNotifications = [],
  dismissNotification,
  toggleResumeUploadModal
}) => {
  if (!dismissNotification && !toggleResumeUploadModal) {
    return null
  }

  const goToProfile = profileSubRoute =>
    profileSubRoute ? navigate(profileSubRoute) : navigate('/profile/')
  const goToProfileJobPreferences = () =>
    goToProfile('/profile/job-preferences/')

  const order = ['success', 'error', 'warning', 'notice']

  // Sorts the visibleNotifications with respect to order above.
  visibleNotifications.sort(
    (a, b) =>
      order.indexOf(NOTIFICATION_TYPES[a].type) -
      order.indexOf(NOTIFICATION_TYPES[b].type)
  )

  /**
   * Sort the warning notifications in the order of
   * `LOCATION_ADD`, `JOB_PREFERENCES` and so on
   * @param {Array} notificationsArr
   */
  const sortWarningTypes = notificationsArr => {
    const firstWarningIndex = findIndex(
      notificationsArr,
      item => NOTIFICATION_TYPES[item].type === 'warning'
    )
    const lastWarningIndex = findLastIndex(
      notificationsArr,
      item => NOTIFICATION_TYPES[item].type === 'warning'
    )

    const warningNotifications = notificationsArr.slice(
      firstWarningIndex,
      lastWarningIndex + 1
    )

    // Order of the warning notifications
    const warningNotificationsOrder = [NOTIFICATION_TYPES.JOB_PREFERENCES.name]

    // Sort the warning notifications with respect to above order
    warningNotifications.sort(
      (notificationA, notificationB) =>
        warningNotificationsOrder.indexOf(notificationA) -
        warningNotificationsOrder.indexOf(notificationB)
    )

    // Replace the existing warning notifications with sorted ones.
    notificationsArr.splice(
      firstWarningIndex,
      warningNotifications.length,
      ...warningNotifications
    )
  }

  sortWarningTypes(visibleNotifications)

  const notifications = visibleNotifications
    .map(notification => {
      const dismiss = () =>
        dismissNotification && dismissNotification(notification)
      switch (notification) {
        case NOTIFICATION_TYPES.PROFILE_UPDATED.name:
          return (
            <NotificationBarLayout
              variation={NOTIFICATION_TYPES.PROFILE_UPDATED.type}
              message={getNotificationMessage({
                notification
              })}
            />
          )

        case NOTIFICATION_TYPES.SETTINGS_UPDATED.name:
          return (
            <NotificationBarLayout
              variation={NOTIFICATION_TYPES.SETTINGS_UPDATED.type}
              message={getNotificationMessage({
                notification
              })}
            />
          )

        case NOTIFICATION_TYPES.JOB_PREFERENCES.name:
          return (
            <NotificationBarLayout
              variation={NOTIFICATION_TYPES.JOB_PREFERENCES.type}
              message={getNotificationMessage({
                notification
              })}
              actionButtonText={'Update my profile'}
              actionButtonDataValue="job-prefs-notification"
              onActionButtonClick={goToProfileJobPreferences}
              onDismissButtonClick={dismiss}
            />
          )

        case NOTIFICATION_TYPES.UPLOAD_RESUME.name:
          return (
            <NotificationBarLayout
              variation={NOTIFICATION_TYPES.UPLOAD_RESUME.type}
              message={getNotificationMessage({
                notification
              })}
              actionButtonText={'UPLOAD MY CV'}
              actionButtonDataValue="cv-upload-notification"
              onActionButtonClick={toggleResumeUploadModal}
              onDismissButtonClick={dismiss}
            />
          )
        default:
          return null
      }
    })
    .filter(Boolean)

  return size(notifications) > 0 ? notifications[0] : null
}

export default NotificationBar
