import React, { FunctionComponent } from 'react'

import { Button } from '@components/redesign/Button'
import { SVGS, Svg } from '@components/redesign/Svgs'

import { ISuccessRegistrationProps } from './SuccessRegistration.types'

import {
  style_component,
  style_xTeamLogo,
  style_title,
  style_subtitle,
  style_button
} from './SuccessRegistration.module.css'

const SuccessRegistration: FunctionComponent<ISuccessRegistrationProps> = ({
  onClick
}) => (
  <div className={style_component} data-testid={'SuccessRegistration'}>
    <Svg name={SVGS.XTeamLogo} className={style_xTeamLogo} />
    <Svg name={SVGS.TickCircle} />
    <h2 className={style_title}>YOU&rsquo;RE REGISTERED WITH X-TEAM</h2>
    <p className={style_subtitle}>
      This is the beginning of a new and exciting journey. Click below to get
      started.
    </p>
    <Button
      data-value="continue-registration-button"
      className={style_button}
      onClick={onClick}
      data-testid={'SuccessRegistration-button'}
    >
      Continue
    </Button>
  </div>
)

export default SuccessRegistration
