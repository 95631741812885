import React from 'react'

import { NavigationMenuButton } from '@components/app/Header/Desktop/NavigationMenuButton'

import { isSSR } from '@utils/helpers'

import { navigationMenu, navigationMenuItem } from './NavigationMenu.module.css'

const NavigationMenu = () => {
  const pathname = isSSR ? '' : window?.location?.pathname
  const isJobsBoardActive =
    pathname === '/' || pathname?.match(/^\/jobs(?!\/my-applications)/)
  const isFaqActive = pathname?.match(/^\/faq/)

  return (
    <ul className={navigationMenu}>
      <li className={navigationMenuItem}>
        <NavigationMenuButton
          label="Jobs"
          to="/jobs/"
          active={isJobsBoardActive}
        />
      </li>
      <li className={navigationMenuItem}>
        <NavigationMenuButton
          label="Blog"
          to="https://x-team.com/blog/"
          target="_blank"
          isExternalLink={true}
        />
      </li>
      <li className={navigationMenuItem}>
        <NavigationMenuButton
          label="Unleash+"
          to="https://x-team.com/blog/unleash/"
          target="_blank"
          isExternalLink={true}
        />
      </li>
      <li className={navigationMenuItem}>
        <NavigationMenuButton
          label="FAQ"
          to="/faq/"
          target="_blank"
          active={isFaqActive}
        />
      </li>
    </ul>
  )
}

export default NavigationMenu
