// extracted by mini-css-extract-plugin
export var Neutral_1 = "#EFEFEF";
export var Red_4 = "#DC4343";
export var colors = "\"@theme/colors.module.css\"";
export var style_avatarIcon = "ProfileAvatar-module--style_avatarIcon--93617";
export var style_avatarItem = "ProfileAvatar-module--style_avatarItem--28a4e";
export var style_component = "ProfileAvatar-module--style_component--9eb23 typography-module--typeface_base--8d193";
export var style_hamburger = "ProfileAvatar-module--style_hamburger--1d276";
export var style_notification = "ProfileAvatar-module--style_notification--f6785";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "\"@theme/typography.module.css\"";
export var white = "#FFFFFF";