import React, { FunctionComponent } from 'react'

import { Svg, SVGS } from '@components/redesign/Svgs'

import {
  style_component,
  style_icon,
  style_block
} from './ErrorMessage.module.css'

type Props = {
  message?: string
  block?: boolean
}

const ErrorMessage: FunctionComponent<Props> = ({ message, block }: Props) => {
  if (!message) {
    return null
  }

  return (
    <div
      data-testid="ErrorMessage"
      className={[style_component, block ? style_block : ''].join(' ')}
    >
      <Svg name={SVGS.ExclamationCircle} className={style_icon} />
      {message}
    </div>
  )
}

export default ErrorMessage
