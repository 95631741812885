// extracted by mini-css-extract-plugin
export var Body_Medium_XS = "typography-module--Body_Medium_XS--cadd2";
export var colors = "\"@theme/colors.module.css\"";
export var fs18_fw700 = "typography-module--fs18_fw700--08853";
export var gradient_caramelizedOrange_bee = "linear-gradient(269.23deg, #ed8e4c -1.67%, #f5be53 89.7%), linear-gradient(222.76deg, #040404 0%, #2d2d2d 100%)";
export var style_card = "DidYouKnow-module--style_card--93696 typography-module--typeface_base--8d193";
export var style_cardSubTitle = "DidYouKnow-module--style_cardSubTitle--6eed4 typography-module--Body_Medium_XS--cadd2";
export var style_cardTitle = "DidYouKnow-module--style_cardTitle--b1577 typography-module--fs18_fw700--08853";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "\"@theme/typography.module.css\"";
export var white = "#FFFFFF";