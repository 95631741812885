import { AxiosError } from 'axios'

import { api, handleAPIError } from '@utils/apiHelpers'

import { IFeatureFlag } from '@type/featureFlag'

export const fetchFeatureFlags = () => {
  try {
    return api.get<IFeatureFlag[]>('/feature-flags')
  } catch (error) {
    return handleAPIError(error as AxiosError)
  }
}
