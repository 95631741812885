import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  FunctionComponent,
  PropsWithChildren
} from 'react'
import { useLocation } from '@reach/router'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { navigate } from 'gatsby'

import { fetchPublishedJobs, fetchJobDetailsWithSlug } from '@services/job'
import { getSelectedJobSlug, selectJobFromSlug } from '@utils/jobHelpers'

import JobsContext from './JobsContext'

import { IJob } from '@type/job'
import { ISkill } from '@type/skill'

/*
 * Provider created to share JobsList value and filters
 * between JobSearch and JobCardList components.
 */
const JobsProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [filteredJobs, setFilteredJobs] = useState<Array<IJob>>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [isSearchApplied, setSearchApplied] = useState<boolean>(false)
  const [selectedJob, setSelectedJob] = useState<IJob | undefined>(undefined)

  const { data: jobListRes, isLoading } = useQuery({
    queryKey: ['jobsList'],
    queryFn: () =>
      fetchPublishedJobs({
        populate: 'skills',
        open: true,
        _sort: 'updated_at:DESC,priority:DESC'
      }) as Promise<AxiosResponse<IJob[]>>
  })

  const jobs = useMemo(() => jobListRes?.data || [], [jobListRes])

  const location = useLocation()
  const selectedJobSlug = getSelectedJobSlug(location?.pathname)

  const isJobsPage = location.pathname.includes('/jobs/')
  const isMyApplicationPage = location.pathname.includes(
    '/jobs/my-applications'
  )

  const selectedJobFromSlug = selectedJobSlug
    ? selectJobFromSlug({
      jobs: jobs,
      selectedJobSlug
    })
    : undefined

  const shouldFetchJobDetails =
    jobListRes &&
    Boolean(selectedJobSlug) &&
    isJobsPage &&
    !isMyApplicationPage &&
    !selectedJobFromSlug

  const { data: jobDataRes, isLoading: isJobDetailsLoading } = useQuery({
    queryKey: ['jobDetailsWithSlug', selectedJobSlug],
    queryFn: () =>
      fetchJobDetailsWithSlug({
        slug: selectedJobSlug,
        open: false,
        populate: 'skills'
      }) as Promise<AxiosResponse<IJob>>,
    enabled: Boolean(shouldFetchJobDetails)
  })

  useEffect(() => {
    if (jobListRes?.data) {
      setFilteredJobs(jobListRes?.data)
    }
  }, [jobListRes])

  useEffect(() => {
    setSelectedJob(selectedJobFromSlug || jobDataRes?.data)
  }, [selectedJobFromSlug, jobDataRes])

  useEffect(() => {
    if (!isJobDetailsLoading && shouldFetchJobDetails && !jobDataRes?.data) {
      navigate('/404/')
    }
  }, [shouldFetchJobDetails, jobDataRes?.data, isJobDetailsLoading])

  const filterJobs = useCallback(
    (searchText: string, isApplied: boolean) => {
      const result = jobs.filter(({ name, description, skills }) => {
        const formattedSearchText = searchText?.toLowerCase()?.trim()
        return (
          name?.toLowerCase()?.includes(formattedSearchText) ||
          description?.toLowerCase()?.includes(formattedSearchText) ||
          (skills as ISkill[]).findIndex(({ title }) =>
            title.includes(formattedSearchText)
          ) > -1
        )
      })

      setFilteredJobs(result)
      setSearchApplied(isApplied)
      setSearchValue(searchText)
    },
    [jobs]
  )

  const contextValue = {
    isLoading,
    jobs,
    filteredJobs,
    filterJobs,
    searchValue,
    setSearchValue,
    isSearchApplied,
    setSearchApplied,
    selectedJob
  }

  return (
    <JobsContext.Provider value={contextValue}>{children}</JobsContext.Provider>
  )
}

export default JobsProvider
