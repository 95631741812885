import React, { FunctionComponent } from 'react'
import { createPortal } from 'react-dom'

import { isSSR } from '@utils/helpers'

import { IModalProps } from './Modal.types'

import {
  style_component,
  style_overlay,
  style_margin,
  style_frame
} from './Modal.module.css'
import { alpha_Neutral_8_60 } from '@theme/colors.module.css'

const Modal: FunctionComponent<IModalProps> = ({
  background = alpha_Neutral_8_60,
  children,
  onClose,
  hasMargin = true
}) => {
  if (isSSR) {
    return null
  }

  if (!children) {
    return null
  }

  const noClick = event => {
    event.stopPropagation()
  }

  const handleClose = () => {
    onClose && onClose()
  }

  const handleKeyPress = event => {
    event.stopPropagation()

    // Esc
    if (event.keyCode === 27) {
      handleClose()
    }
  }

  const overlayClassName = [style_overlay, hasMargin ? style_margin : ''].join(
    ' '
  )

  return createPortal(
    <div
      style={{ background }}
      className={style_component}
      data-testid="Modal"
      onKeyDown={handleKeyPress}
      onClick={handleClose}
      tabIndex={0}
    >
      <div className={overlayClassName}>
        <section
          className={style_frame}
          onClick={noClick}
          data-testid="Modal-frame"
        >
          {children}
        </section>
      </div>
    </div>,
    document.body
  )
}

export default Modal
