import React, { FunctionComponent } from 'react'

import { JobTags } from '@containers/JobTags'
import { CardTags } from '@components/redesign/CardTags'
import { JobTag } from '@components/redesign/JobTag'
import { Svg, SVGS } from '@components/redesign/Svgs'

import { size } from '@utils/helpers'

import { IJobCardProps } from './JobCard.types'

import {
  style_card,
  style_card_selected,
  style_name,
  style_nameLink,
  style_skills,
  style_bottom,
  style_jobLink,
  style_viewJobBtn,
  style_header_container,
  style_timezoneAndArrangement,
  style_selected_jobLink
} from './JobCard.module.css'
import { turquish, boneChilling } from '@theme/colors.module.css'

const JobCard: FunctionComponent<IJobCardProps> = ({
  job,
  onCardClick,
  isSelected,
  isSuccessfulApplication
}) => {
  if (!job) {
    return null
  }

  const { id, name, slug, skills, baseTimezone, arrangement } = job

  const successTagView = isSuccessfulApplication && (
    <JobTag
      value="Application Successful"
      color={boneChilling}
      backgroundColor={turquish}
    />
  )

  if ((!id && isNaN(id as number)) || !slug || !name) {
    console.error(
      `The job doesn't have required data and is skipped from rendering.`
    )
    return null
  }

  const cardClassName = [
    style_card,
    isSelected ? style_card_selected : ''
  ].join(' ')

  return (
    <div
      className={cardClassName}
      id={isSelected ? `card-${job.id}` : undefined}
      data-testid="JobCard"
      onClick={onCardClick}
    >
      <div className={style_header_container}>
        {successTagView || <JobTags job={job} />}
        <div className={style_timezoneAndArrangement}>
          {[baseTimezone, arrangement].filter(Boolean).join(' - ')}
        </div>
      </div>
      <div className={style_nameLink}>
        <h3 className={style_name}>{name}</h3>
      </div>
      <div className={style_bottom}>
        {size(skills) > 0 && (
          <div className={style_skills} data-testid="JobCard-skills">
            <CardTags tags={skills} />
          </div>
        )}
        <div
          className={`${style_jobLink} ${
            isSelected ? style_selected_jobLink : ''
          }`}
        >
          <Svg name={SVGS.ArrowRight} width="6px" height="8px" />
        </div>
        <div className={style_viewJobBtn}>View Job</div>
      </div>
    </div>
  )
}

export default JobCard
