import { useState, ChangeEvent } from 'react'

import { PASSWORD_FIELD_VALIDATE_RULES } from '@config/constants'

import { IItemType } from '@components/redesign/ValidationList/ValidationList.types'

const PASSWORD_FIELD_INITIAL_VALIDATE_STATUS = Object.keys(
  PASSWORD_FIELD_VALIDATE_RULES
).reduce(
  (acc, cur) => [
    ...acc,
    {
      label: PASSWORD_FIELD_VALIDATE_RULES[cur].label,
      status: 'default'
    }
  ],
  [] as IItemType[]
)

const useValidationItems = () => {
  const [validationItems, setValidationItems] = useState(
    PASSWORD_FIELD_INITIAL_VALIDATE_STATUS
  )

  const getPasswordValidationRuleStatus = (
    key: string,
    password: string,
    passwordError?: string
  ) => {
    const rule = PASSWORD_FIELD_VALIDATE_RULES[key]
    if (!passwordError && password === '') {
      return 'default'
    }
    return rule.regex.test(password) ? 'success' : 'error'
  }

  const handlePasswordChange = (
    e: ChangeEvent<HTMLInputElement>,
    passwordError?: string
  ) => {
    setValidationItems(
      Object.keys(PASSWORD_FIELD_VALIDATE_RULES).reduce(
        (acc, cur) => [
          ...acc,
          {
            label: PASSWORD_FIELD_VALIDATE_RULES[cur].label,
            status: getPasswordValidationRuleStatus(
              cur,
              e.target.value,
              passwordError
            )
          }
        ],
        [] as IItemType[]
      )
    )
  }

  const validateRules = Object.keys(PASSWORD_FIELD_VALIDATE_RULES).reduce(
    (acc, cur) => ({
      ...acc,
      [cur]: (value: string) =>
        PASSWORD_FIELD_VALIDATE_RULES[cur].regex.test(value)
    }),
    {}
  )

  return {
    validationItems,
    setValidationItems,
    getPasswordValidationRuleStatus,
    handlePasswordChange,
    validateRules
  }
}

export default useValidationItems
