import {
  RouteConfigListType,
  RouteConfigType
} from './AppLayoutContainer.types'

export const getRouteConfig = (
  routesUsingAppLayout: RouteConfigListType,
  path: string
): RouteConfigType | undefined =>
  Object.values(routesUsingAppLayout).find(config =>
    (config.route === '/'
      ? config.route === path
      : path.includes(config.route || ''))
      ? config
      : undefined
  )
