import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { api, handleAPIError } from '@utils/apiHelpers'
import { ML_JOB_SUGGESTIONS_MODEL } from '@config/constants'

import { FetchJobsArgs, SimilarJobsParams } from './job.types'
import { IJob } from '@type/job'
import { IJobSimilarity } from '@type/jobSimilarity'

export async function fetchSimilarJobs (
  jobId: number,
  params: SimilarJobsParams = { populate: 'skills', _limit: 5 }
) {
  try {
    const response = await (api as AxiosInstance).get<IJobSimilarity[]>(
      `/jobs/${jobId}/similar`,
      { params }
    )
    const { data, status, headers } = response

    return {
      data,
      headers,
      status
    }
  } catch (error) {
    return handleAPIError(error as AxiosError)
  }
}

export const fetchPublishedJobs = async (
  params: FetchJobsArgs = { populate: 'skills' }
) => {
  try {
    return await api.get<IJob[]>(
      '/jobs?populate[]=image&populate[]=isAlwaysHiring&populate[]=jobLocation',
      {
        params: { ...params, open: true }
      }
    )
  } catch (error) {
    return handleAPIError(error as AxiosError)
  }
}

export const fetchSuggestedJobs = async (profileId: number) => {
  const params = {
    profile_id: profileId,
    remove_applied: 1,
    populate: ['job', 'skills'],
    _limit: 5
  }
  let response
  try {
    response = await (api as AxiosInstance).get('/jobs/suggestions', {
      params: {
        ...params,
        model: ML_JOB_SUGGESTIONS_MODEL
      }
    })
  } catch (error) {
    if (!ML_JOB_SUGGESTIONS_MODEL) {
      response = handleAPIError(error as AxiosError)
    }
    try {
      response = await (api as AxiosInstance).get('/jobs/suggestions', {
        params
      })
    } catch (error) {
      response = handleAPIError(error as AxiosError)
    }
  }
  const { data, status, headers, error } = response

  return error
    ? { data: [] }
    : {
      data,
      headers,
      status
    }
}

/**
 * Get the details of a job using `slug`.
 * Currently the api does not support findOne operation with slug, so to keep
 * things simple the job details has been fetch from job list api passing
 * parameter `_limit:1`
 *
 * @param {FetchJobsArgs} params
 * @returns
 */
export const fetchJobDetailsWithSlug = async (
  params: FetchJobsArgs = { populate: 'skills' }
) => {
  params._limit = 1
  try {
    const response = await api.get<IJob[]>(
      `/jobs?populate[]=image&populate[]=isAlwaysHiring&populate[]=jobLocation`,
      { params }
    )
    const { data } = response
    const jobDetailsRes: AxiosResponse<IJob> = {
      ...response,
      data: data[0]
    }

    return jobDetailsRes
  } catch (error) {
    return handleAPIError(error as AxiosError)
  }
}

export const fetchPrioritizedJobs = async ({
  limit = 5,
  idNin = []
}: {
  limit?: number
  idNin?: number[]
}) => {
  const response = await fetchPublishedJobs({
    populate: 'skills',
    open: true,
    id_nin: idNin,
    _sort: 'priority:DESC,updated_at:DESC',
    _limit: limit
  })
  return response as AxiosResponse<IJob[]>
}
