// extracted by mini-css-extract-plugin
export var Body_Regular_XS = "typography-module--Body_Regular_XS--1e203";
export var amaranth = "#DE2B50";
export var boneChilling = "#E1F4F2";
export var breakpointMD = "1024px";
export var breakpointSM = "768px";
export var breakpoints = "'@theme/breakpoints.module.css'";
export var colors = "'@theme/colors.module.css'";
export var desertLily = "#FFF5DB";
export var fs17_fw400 = "typography-module--fs17_fw400--4582a";
export var linen = "#FFECEC";
export var mikadoYellow = "#EDAB03";
export var style_action_button = "NotificationBarLayout-module--style_action_button--8e64e typography-module--Body_Regular_XS--1e203";
export var style_button_container = "NotificationBarLayout-module--style_button_container--f8c36";
export var style_dismiss_button = "NotificationBarLayout-module--style_dismiss_button--d03b7 typography-module--Body_Regular_XS--1e203";
export var style_green_background = "NotificationBarLayout-module--style_green_background--f6057";
export var style_icon_background = "NotificationBarLayout-module--style_icon_background--ab6c1";
export var style_message = "NotificationBarLayout-module--style_message--bdef3 typography-module--fs17_fw400--4582a";
export var style_message_green = "NotificationBarLayout-module--style_message_green--e2db2";
export var style_message_red = "NotificationBarLayout-module--style_message_red--9398e";
export var style_message_yellow = "NotificationBarLayout-module--style_message_yellow--66558";
export var style_red_background = "NotificationBarLayout-module--style_red_background--816f5";
export var style_right_container = "NotificationBarLayout-module--style_right_container--e3829";
export var style_wrapper = "NotificationBarLayout-module--style_wrapper--f5bc5 typography-module--typeface_base--8d193";
export var style_wrapper_green_background = "NotificationBarLayout-module--style_wrapper_green_background--38fea";
export var style_wrapper_red_background = "NotificationBarLayout-module--style_wrapper_red_background--be4e5";
export var style_wrapper_yellow_background = "NotificationBarLayout-module--style_wrapper_yellow_background--0a123";
export var style_yellow_background = "NotificationBarLayout-module--style_yellow_background--77a69";
export var turquish = "#01A390";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "'@theme/typography.module.css'";
export var white = "#FFFFFF";