import React, { FunctionComponent, useLayoutEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { FormLayoutContainer } from '@containers/FormLayoutContainer'
import { LinkedInButtonContainer } from '@containers/LinkedInButtonContainer'
import { ButtonNew } from '@components/redesign/ButtonNew'
import { Divider } from '@components/redesign/FormLayout/Divider'
import { InputFieldNew } from '@components/redesign/InputFieldNew'
import { InputSection } from '@components/redesign/InputSection'

import { useAuthContext } from '@hooks/useAuthContext'
import { useUTMParamsContext } from '@hooks/useUTMParamsContext'
import {
  size,
  emailValidationPattern,
  getAuthErrorMessage
} from '@utils/helpers'

import { ILoginFormProps } from './LoginForm.types'

const LoginForm: FunctionComponent<ILoginFormProps> = ({
  handleAuthenticatedSession,
  handleForgotPasswordClick
}) => {
  const { utmParams } = useUTMParamsContext()
  const { error: authError, signIn, clearErrors } = useAuthContext()
  const { register, handleSubmit, errors: formErrors } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange'
  })
  const [isSubmitting, setIsSubmitting] = useState(false)

  const hasAuthErrors = size(authError) > 0
  const hasFormErrors = size(formErrors) > 0
  const canEdit = !isSubmitting
  const canSubmit = !hasFormErrors && canEdit

  const handleOnSubmit = async ({ identifier, password }) => {
    setIsSubmitting(true)
    await signIn({ identifier, password, utmParams }, () => {
      handleAuthenticatedSession?.()
    })
    setIsSubmitting(false)
  }

  // Clear previous authentication errors when mounting the component
  useLayoutEffect(() => {
    clearErrors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getErrorMessage = () => {
    if (hasAuthErrors) {
      return (
        getAuthErrorMessage(authError) ||
        'Something went wrong, please try again'
      )
    }
  }

  const identifierErrorMessage = formErrors?.identifier?.message
  const passwordErrorMessage = formErrors?.password?.message

  return (
    <FormLayoutContainer
      onSubmit={handleSubmit(handleOnSubmit)}
      error={getErrorMessage()}
    >
      <LinkedInButtonContainer label="Login with LinkedIn" />
      <Divider label="Or, login with your email" />
      <InputSection
        label="Email address"
        invalid={identifierErrorMessage}
        compact
      >
        <InputFieldNew
          name="identifier"
          placeholder="Email address"
          disabled={!canEdit}
          invalid={formErrors.identifier}
          validation={register({
            required: 'Email address is required.',
            pattern: emailValidationPattern
          })}
        />
      </InputSection>
      <InputSection label="Password" invalid={passwordErrorMessage} compact>
        <InputFieldNew
          name="password"
          placeholder="Password"
          type="password"
          disabled={!canEdit}
          invalid={formErrors.password}
          validation={register({
            required: 'Password is required.'
          })}
        />
      </InputSection>
      <Divider isBlank />
      <ButtonNew
        data-value="login-modal-login-button"
        block
        disabled={!canSubmit}
        type="submit"
        uppercase={false}
        size="large"
      >
        {isSubmitting ? 'Logging in...' : 'Login'}
      </ButtonNew>
      <Divider isBlank />
      <p>
        <a href="/forgot-password/" onClick={handleForgotPasswordClick}>
          Forgot password?
        </a>
      </p>
    </FormLayoutContainer>
  )
}

export default LoginForm
