import React, { Suspense } from 'react'

import { SubmitCV } from '@containers/SubmitCV'
import { NotificationBar } from '@components/redesign/NotificationBar'

import { useModal } from '@hooks/useModal'
import { useNotificationBar } from '@hooks/useNotificationBar'
import { size } from '@utils/helpers'

const Modal = React.lazy(() => import('@components/redesign/Modal/Modal'))

const NotificationContainer = () => {
  const { visibleNotifications, dismissNotification } = useNotificationBar()
  const notifications = visibleNotifications

  const {
    isVisible: isResumeUploadModalVisible,
    toggleModal: toggleResumeUploadModal
  } = useModal()

  const renderResumeUploadModal = () => {
    if (isResumeUploadModalVisible) {
      return (
        <Suspense fallback={<div />}>
          <Modal onClose={toggleResumeUploadModal}>
            <SubmitCV
              handleConfirmation={toggleResumeUploadModal}
              confirmationText="Close"
              hideLockedFeatures
              handleDiscard={toggleResumeUploadModal}
            />
          </Modal>
        </Suspense>
      )
    }
  }

  return (
    <>
      {renderResumeUploadModal()}
      {size(notifications) > 0 && (
        <NotificationBar
          visibleNotifications={notifications}
          dismissNotification={dismissNotification}
          toggleResumeUploadModal={toggleResumeUploadModal}
        />
      )}
    </>
  )
}

export default NotificationContainer
