// extracted by mini-css-extract-plugin
export var Body_Medium_XS = "typography-module--Body_Medium_XS--cadd2";
export var Interface_Medium_M = "typography-module--Interface_Medium_M--2989f";
export var Neutral_1 = "#EFEFEF";
export var colors = "'@theme/colors.module.css'";
export var popperCloseAnimation = "HeaderAvatar-module--popperCloseAnimation--76785";
export var popperOpenAnimation = "HeaderAvatar-module--popperOpenAnimation--79c9b";
export var scaleDown = "HeaderAvatar-module--scaleDown--667bf";
export var scaleUp = "HeaderAvatar-module--scaleUp--94c31";
export var style_authButton = "HeaderAvatar-module--style_authButton--c1539 typography-module--Interface_Medium_M--2989f";
export var style_authButtons = "HeaderAvatar-module--style_authButtons--768fa";
export var style_avatar = "HeaderAvatar-module--style_avatar--0bfb2 typography-module--Body_Medium_XS--cadd2 typography-module--typeface_base--8d193";
export var style_avatarContainer = "HeaderAvatar-module--style_avatarContainer--0e408";
export var style_hambugerMenu = "HeaderAvatar-module--style_hambugerMenu--0f054";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "'@theme/typography.module.css'";