import React, { FunctionComponent } from 'react'

import { CardTags } from '@components/redesign/CardTags'

import { IFaqCardProps } from './FaqCard.types'

import {
  style_component,
  style_selected,
  style_title,
  style_tags,
  style_answers
} from './FaqCard.module.css'

const FaqCard: FunctionComponent<IFaqCardProps> = ({
  title,
  tags,
  answers,
  isSelected,
  onClick
}) => {
  if (!title) {
    return null
  }

  const faqCardClassName = [
    style_component,
    isSelected ? style_selected : ''
  ].join(' ')

  return (
    <section
      data-testid="FaqCard"
      className={faqCardClassName}
      id={isSelected ? `card-${title}` : undefined}
      onClick={onClick}
    >
      <h1 className={style_title}>{title}</h1>
      <div className={style_tags}>
        <CardTags
          tags={tags}
          shouldSort={false}
          isExpandable={false}
          redesign
        />
        {answers && <div className={style_answers}>{answers} Answers</div>}
      </div>
    </section>
  )
}

export default FaqCard
