import React from 'react'

import { style_component, style_spinner } from './Spinner.module.css'

const Spinner = () => (
  <div className={style_component} data-testid="Spinner">
    <div className={style_spinner}></div>
  </div>
)

export default Spinner
