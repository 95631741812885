import { white, Green_5 } from '@theme/colors.module.css'

export const AVAILABILITY = {
  NOT_SELECTED: 'AVAILABILITY_NOT_SELECTED',
  PERIOD: 'period',
  DATE: 'date',
  NOW: 'now',
  NOT_AVAILABLE: 'not_available'
}

const APP_NAMESPACE = 'jobsSite'
export const SAVE_PROFILE_MESSAGE = 'Your profile has been saved.'
export const NOTIFICATION_DURATION = 2000
export const NOTIFICATION_MESSAGE_STYLE = {
  background: Green_5,
  color: white
}

export const STORAGE_KEYS = {
  AUTH_TOKEN: `${APP_NAMESPACE}__authToken`,
  WELCOME_HERO: `${APP_NAMESPACE}__welcomeHero`,
  DISMISSED_NOTIFICATION_BARS: `${APP_NAMESPACE}__dismissedNotificationBars`,
  LINKEDIN_SIGNIN_REDIRECT_URL: `${APP_NAMESPACE}__linkedinSignInRedirecturl`
}

export const NOTIFICATION_TYPES = {
  PROFILE_UPDATED: {
    name: 'PROFILE_UPDATED',
    type: 'success'
  },
  SETTINGS_UPDATED: {
    name: 'SETTINGS_UPDATED',
    type: 'success'
  },
  JOB_PREFERENCES: {
    name: 'JOB_PREFERENCES',
    type: 'warning'
  },
  UPLOAD_RESUME: {
    name: 'UPLOAD_RESUME',
    type: 'warning'
  }
}

export const LOCATION_STRING_SEPARATOR = '::'

// Password must satisfies the following rules:
//   - minimum 6 chars,
//   - at least one lowercase letter,
//   - at least one digit,
//   - at least one uppercase letter,
//   - at least one special character
//     - https://owasp.org/www-community/password-special-characters
export const PASSWORD_REGEXP = /^(?=.{6,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[\W_]).*$/

// - Must be at least 6 characters
export const PASSWORD_REGEXP_MIN_6_CHARS = /^(?=.{6,}$).*$/
// - Must include at least 1 uppercase letter
export const PASSWORD_REGEXP_MIN_1_UPPERCASE_CHAR = /^(?=.*?[A-Z]).*$/
// - Must include at least 1 lowercase letter
export const PASSWORD_REGEXP_MIN_1_LOWERCASE_CHAR = /^(?=.*?[a-z]).*$/
// - Must include at least 1 number
export const PASSWORD_REGEXP_MIN_1_NUMBER = /^(?=.*?[0-9]).*$/
// - Must include at least 1 special character
export const PASSWORD_REGEXP_MIN_1_SPECIAL_CHAR = /^(?=.*?[\W_]).*$/

export const PASSWORD_FIELD_VALIDATE_RULES = {
  min6Chars: {
    regex: PASSWORD_REGEXP_MIN_6_CHARS,
    label: 'Must be at least 6 characters'
  },
  min1UppercaseChar: {
    regex: PASSWORD_REGEXP_MIN_1_UPPERCASE_CHAR,
    label: 'Must include at least 1 uppercase letter'
  },
  min1LowercaseChar: {
    regex: PASSWORD_REGEXP_MIN_1_LOWERCASE_CHAR,
    label: 'Must include at least 1 lowercase letter'
  },
  min1Number: {
    regex: PASSWORD_REGEXP_MIN_1_NUMBER,
    label: 'Must include at least 1 number'
  },
  min1SpecialChar: {
    regex: PASSWORD_REGEXP_MIN_1_SPECIAL_CHAR,
    label: 'Must include at least 1 special character'
  }
}

export const FORM_FIELDS_META = {
  password: {
    placeholder: 'numbers, letters, and special characters',
    validationError:
      'at least 6 characters long and contain numbers, lowercase and uppercase letters, and special characters.'
  }
}

export const AUTO_LOGOUT_SECONDS = 30

export const ML_JOB_SUGGESTIONS_MODEL =
  process.env.GATSBY_ML_JOB_SUGGESTIONS_MODEL || ''

export const AUTH_PROVIDERS = {
  LOCAL: 'local',
  LINKEDIN: 'linkedin'
}

export const ACCESS_TOKEN_KEY = 'access_token'

export const FILE_TOO_LARGE_CODE = 'file-too-large'
export const FILE_TYPE_INVALID = 'file-invalid-type'
export const FILE_TOO_LARGE_MESSAGE =
  'Please make sure your file is under 4MB and try again.'
export const FILE_TOO_LARGE_MESSAGE_PROFILE_IMAGE = 'File must be below 4MB.'
export const FILE_TOO_LARGE_MESSAGE_CV =
  'That file is too large. Please ensure your CV is less than 4Mb'
export const TRY_AGAIN_MESSAGE = 'Please close this pop-up and try again.'
export const PARSE_RESUME_ERROR_MESSAGE = `We couldn't parse your resume, please try again in a few minutes`

export const CONVERSION_ID = 4462684

export const EXPECTED_RATE_RANGES = [
  { label: '$0 - $35', value: '0,35' },
  { label: '$35 - $50', value: '35,50' },
  { label: '$50 - $70', value: '50,70' },
  { label: '$70 - $100', value: '70,100' },
  { label: '$100+', value: '100,' }
]

export const JOB_APPLICATION_STAGES = {
  PENDING: 'Pending',
  SUCCESSFUL: 'Successful',
  REJECTED: 'Rejected',
  EXPIRED: 'Expired',
  WITHDRAWN: 'Withdrawn'
}

export const REDIRECT_ROUTES = {
  INITIAL_JOB_APPLICATIONS: '/jobs/',
  LINKEDIN: '/linkedin/',
  ACTIVATE: '/activate/'
}
