import React, { FunctionComponent } from 'react'

import { ICardListProps } from './CardList.types'

import { style_component, style_padded } from './CardList.module.css'

const CardList: FunctionComponent<ICardListProps> = ({
  children,
  paddingBottom
}) => {
  const componentClassName = [
    style_component,
    paddingBottom ? style_padded : undefined
  ].join(' ')

  return <div className={componentClassName}>{children}</div>
}

export default CardList
