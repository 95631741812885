import React from 'react'

import { Neutral_8 } from '@theme/colors.module.css'

const RightCircleArrow = ({
  strokeColor = 'white',
  fill = Neutral_8,
  height = 56,
  width = 56,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 56 56" fill="none" {...rest}>
    <rect width={width} height={height} rx="28" fill={fill} />
    <path
      d="M22.167 28H33.8337"
      stroke={strokeColor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.833 23L33.833 28L28.833 33"
      stroke={strokeColor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default RightCircleArrow
