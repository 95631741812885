import React from 'react'

const Decoration = props => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.7778 1H2.22222C1.54721 1 1 1.54721 1 2.22222V10.7778C1 11.4528 1.54721 12 2.22222 12H10.7778C11.4528 12 12 11.4528 12 10.7778V2.22222C12 1.54721 11.4528 1 10.7778 1Z"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.36393 5.27791C4.87019 5.27791 5.2806 4.86751 5.2806 4.36125C5.2806 3.85499 4.87019 3.44458 4.36393 3.44458C3.85767 3.44458 3.44727 3.85499 3.44727 4.36125C3.44727 4.86751 3.85767 5.27791 4.36393 5.27791Z"
      fill="#666666"
    />
    <path
      d="M12.0004 8.33351L8.94488 5.27795L2.22266 12.0002"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Decoration
