import React from 'react'

import { HeaderAvatar } from '@components/app/Header/Desktop/HeaderAvatar'
import { NavigationMenu } from '@components/app/Header/Desktop/NavigationMenu'
import { XTeamLogo } from '@components/generic/XTeamLogo'

import { desktopHeader } from './DesktopHeader.module.css'

const DesktopHeader = ({ hideMenu, showLoginForm, showCreateAccountForm }) => (
  <header className={desktopHeader}>
    <XTeamLogo />
    <NavigationMenu />
    {hideMenu ? (
      <div />
    ) : (
      <HeaderAvatar
        showLoginForm={showLoginForm}
        showCreateAccountForm={showCreateAccountForm}
      />
    )}
  </header>
)

export default DesktopHeader
