// extracted by mini-css-extract-plugin
export var Body_Regular_S = "typography-module--Body_Regular_S--f1633";
export var Neutral_1 = "#EFEFEF";
export var Neutral_6 = "#505050";
export var Neutral_8 = "#0C0C0C";
export var breakpointMD = "1024px";
export var breakpoints = "\"@theme/breakpoints.module.css\"";
export var coldMorning = "#E5E5E5";
export var colors = "\"@theme/colors.module.css\"";
export var fs11_fw700 = "typography-module--fs11_fw700--4f669";
export var fs14_fw700 = "typography-module--fs14_fw700--8b307";
export var fs18_fw700 = "typography-module--fs18_fw700--08853";
export var squant = "#666666";
export var style_acceptButton = "CookieBanner-module--style_acceptButton--9fb7d typography-module--fs11_fw700--4f669";
export var style_component = "CookieBanner-module--style_component--65084 typography-module--typeface_base--8d193";
export var style_mainText = "CookieBanner-module--style_mainText--dafc0 typography-module--fs18_fw700--08853";
export var style_rejectButton = "CookieBanner-module--style_rejectButton--be832 typography-module--fs11_fw700--4f669";
export var style_subText = "CookieBanner-module--style_subText--d0a3b typography-module--Body_Regular_S--f1633";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "\"@theme/typography.module.css\"";