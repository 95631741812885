import React, { useState, useRef, FunctionComponent } from 'react'
import { useLocation } from '@reach/router'

import InputText from '@components/form/InputText'
import { Svg, SVGS } from '@components/redesign/Svgs'

import { useJobsContext } from '@hooks/useJobsContext'
import { useMediaQuery } from '@hooks/useMediaQuery'

import { IJobSearchProps, ComponentRefType } from './JobSearch.types'

import {
  style_backdrop,
  style_backdropVisible,
  style_searchInput,
  style_inputContainer,
  style_inputButton,
  style_inputContainerWithIcon
} from './JobSearch.module.css'
import { breakpointSM } from '@theme/breakpoints.module.css'

const InputIcon = ({ isSearchApplied, handleSearchValue, clearSearchValue }) =>
  isSearchApplied ? (
    <div className={style_inputButton} onClick={clearSearchValue}>
      <Svg name={SVGS.Close} />
    </div>
  ) : (
    <div className={style_inputButton} onClick={handleSearchValue}>
      <Svg name={SVGS.Search} />
    </div>
  )

const JobSearch: FunctionComponent<IJobSearchProps> = ({ hideOnMobile }) => {
  const { pathname } = useLocation()

  const [isInputFocused, setInputFocused] = useState(false)

  const jobs = useJobsContext()
  const {
    filterJobs,
    searchValue,
    setSearchValue,
    isSearchApplied,
    setSearchApplied
  } = jobs || {}

  const searchInputRef = useRef() as ComponentRefType

  const isMobile = !useMediaQuery(`(min-width: ${breakpointSM})`)
  const inputPlaceholder = isMobile
    ? 'SEARCH'
    : 'SEARCH FOR KEYWORDS, OR SKILLS'

  if (!jobs) {
    return null
  }

  const handleSearchValue = () => {
    if (searchValue !== '') {
      setSearchApplied(true)
      filterJobs && filterJobs(searchValue, true)
      searchInputRef?.current?.blur()
    } else {
      setSearchApplied(false)
      filterJobs && filterJobs(searchValue, false)
      searchInputRef?.current?.blur()
    }
  }

  const clearSearchValue = () => {
    setSearchValue('')
    setSearchApplied(false)
    filterJobs && filterJobs('', false)
  }

  const handleTextChange = ({ target: { value } }) => {
    setSearchValue(value)
    setSearchApplied(false)
  }

  const setBackdropVisible = () => {
    setInputFocused(true)
  }

  const setBackdropHidden = () => {
    setInputFocused(false)
  }

  const handleKeyPress = event => {
    event.stopPropagation()

    // Enter
    if (event.keyCode === 13) {
      handleSearchValue()
    }
  }

  const shouldShowOnMobile = pathname === '/' || pathname.includes('/jobs')

  if (isMobile && (!shouldShowOnMobile || hideOnMobile)) {
    return null
  }

  const backdropClasses = isInputFocused ? style_backdropVisible : ''
  const containerClasses = [
    style_inputContainer,
    isMobile && (searchValue || isSearchApplied)
      ? style_inputContainerWithIcon
      : ''
  ].join(' ')

  const isIconVisible = !isMobile || searchValue || isSearchApplied

  return (
    <div>
      <div className={[style_backdrop, backdropClasses].join(' ')} />
      <div className={containerClasses}>
        <InputText
          ref={searchInputRef}
          data-value="search"
          className={style_searchInput}
          value={searchValue}
          placeholder={inputPlaceholder}
          onChange={handleTextChange}
          onFocus={setBackdropVisible}
          onBlur={setBackdropHidden}
          onKeyDown={handleKeyPress}
        />
        {isIconVisible ? (
          <InputIcon
            isSearchApplied={isSearchApplied}
            handleSearchValue={handleSearchValue}
            clearSearchValue={clearSearchValue}
          />
        ) : null}
      </div>
    </div>
  )
}

export default JobSearch
