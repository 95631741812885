import React from 'react'

import { Button } from '@components/redesign/Button'

import {
  style_card,
  style_cardTitle
} from './GlassdoorMostLovedCompany.module.css'

const GlassdoorMostLovedCompany = () => (
  <div className={style_card}>
    <span className={style_cardTitle}>
      GLASSDOOR&apos;S MOST LOVED <br />
      ENGINEERING COMPANY.
    </span>
    <Button
      href="https://www.glassdoor.com.au/Reviews/X-Team-Reviews-E1274500.htm"
      color="transparent"
      size="extra_small"
      target="_blank"
      rel="noreferrer"
    >
      VIEW GLASSDOOR&apos;S REVIEWS
    </Button>
  </div>
)

export default GlassdoorMostLovedCompany
