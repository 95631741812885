import React from 'react'

import { squant } from '@theme/colors.module.css'

const MovingThreeDots = ({
  fillColor = squant,
  strokeColor = squant,
  ...props
}) => (
  <svg
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.75 5.5C1.75 5.08579 2.08579 4.75 2.5 4.75C2.91421 4.75 3.25 5.08579 3.25 5.5C3.25 5.91421 2.91421 6.25 2.5 6.25C2.08579 6.25 1.75 5.91421 1.75 5.5Z"
      fill={fillColor}
      stroke={strokeColor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.25 4C6.25 3.58579 6.58579 3.25 7 3.25C7.41421 3.25 7.75 3.58579 7.75 4C7.75 4.41421 7.41421 4.75 7 4.75C6.58579 4.75 6.25 4.41421 6.25 4Z"
      fill={fillColor}
      stroke={strokeColor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.75 2.5C10.75 2.08579 11.0858 1.75 11.5 1.75C11.9142 1.75 12.25 2.08579 12.25 2.5C12.25 2.91421 11.9142 3.25 11.5 3.25C11.0858 3.25 10.75 2.91421 10.75 2.5Z"
      fill={fillColor}
      stroke={strokeColor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default MovingThreeDots
