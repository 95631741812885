// extracted by mini-css-extract-plugin
export var Interface_SemiBold_L = "typography-module--Interface_SemiBold_L--52185";
export var Interface_SemiBold_S = "typography-module--Interface_SemiBold_S--af7a9";
export var Neutral_4 = "#8F8F8F";
export var Neutral_8 = "#0C0C0C";
export var alpha_Neutral_8_20 = "rgba(12, 12, 12, 0.2)";
export var alpha_Neutral_8_50 = "rgba(12, 12, 12, 0.5)";
export var alpha_Neutral_8_80 = "rgba(12, 12, 12, 0.8)";
export var alpha_hornetSting_10 = "rgba(255, 0, 53, 0.1)";
export var alpha_white_10 = "rgba(255, 255, 255, 0.1)";
export var alpha_white_20 = "rgba(255, 255, 255, 0.2)";
export var alpha_white_40 = "rgba(255, 255, 255, 0.4)";
export var alpha_white_50 = "rgba(255, 255, 255, 0.5)";
export var boneChilling = "#E1F4F2";
export var colors = "\"@theme/colors.module.css\"";
export var fs11_fw600 = "typography-module--fs11_fw600--fce4e";
export var fs13_fw600 = "typography-module--fs13_fw600--ee3e2";
export var hornetSting = "#FF0035";
export var jadeMountain = "#2FC3A9";
export var mediumCandyAppleRed = "#E50030";
export var niagara = "#29A892";
export var quicksilver = "#A6A6A6";
export var ravenBlack = "#3D3D3D";
export var soothingWhite = "#E2E2E3";
export var squant = "#666666";
export var style_block = "ButtonNew-module--style_block--6012b";
export var style_block_link = "ButtonNew-module--style_block_link--70cc1";
export var style_block_link_inner = "ButtonNew-module--style_block_link_inner--3237b";
export var style_component = "ButtonNew-module--style_component--4e4d6 typography-module--typeface_base--8d193";
export var style_default = "ButtonNew-module--style_default--1fcb1";
export var style_default_outlined = "ButtonNew-module--style_default_outlined--e473b";
export var style_href = "ButtonNew-module--style_href--2bb99";
export var style_link = "ButtonNew-module--style_link--78eba";
export var style_outlined = "ButtonNew-module--style_outlined--34164";
export var style_pill = "ButtonNew-module--style_pill--1e9e1";
export var style_primary = "ButtonNew-module--style_primary--8d0b4";
export var style_primary_outlined = "ButtonNew-module--style_primary_outlined--ec43a";
export var style_secondary = "ButtonNew-module--style_secondary--fc21c";
export var style_secondary_outlined = "ButtonNew-module--style_secondary_outlined--535ac";
export var style_size_extra_small = "ButtonNew-module--style_size_extra_small--ef29b typography-module--fs11_fw600--fce4e";
export var style_size_large = "ButtonNew-module--style_size_large--bcea9 typography-module--Interface_SemiBold_L--52185";
export var style_size_normal = "ButtonNew-module--style_size_normal--4125c typography-module--Interface_SemiBold_S--af7a9";
export var style_size_small = "ButtonNew-module--style_size_small--80da3 typography-module--fs11_fw600--fce4e";
export var style_transparent = "ButtonNew-module--style_transparent--f09de";
export var style_transparent_outlined = "ButtonNew-module--style_transparent_outlined--2c79f";
export var style_uppercase = "ButtonNew-module--style_uppercase--369cb";
export var style_warning = "ButtonNew-module--style_warning--f99bb";
export var style_warning_outlined = "ButtonNew-module--style_warning_outlined--7c909";
export var style_whiteBackground = "ButtonNew-module--style_whiteBackground--1160a";
export var turquish = "#01A390";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "\"@theme/typography.module.css\"";
export var white = "#FFFFFF";