import React from 'react'

const MusicGear = props => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 11 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.875 1H2.125C1.50368 1 1 1.53726 1 2.2V11.8C1 12.4627 1.50368 13 2.125 13H8.875C9.49632 13 10 12.4627 10 11.8V2.2C10 1.53726 9.49632 1 8.875 1Z"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.5 10.5C6.60457 10.5 7.5 9.60457 7.5 8.5C7.5 7.39543 6.60457 6.5 5.5 6.5C4.39543 6.5 3.5 7.39543 3.5 8.5C3.5 9.60457 4.39543 10.5 5.5 10.5Z"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.5 3.5H5.50625"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default MusicGear
