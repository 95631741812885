// extracted by mini-css-extract-plugin
export var alpha_Neutral_8_60 = "rgba(12, 12, 12, 0.6)";
export var animation_fadeIn_005_linear = "animations-module--animation_fadeIn_005_linear--b3af0";
export var animations = "\"@theme/animations.module.css\"";
export var breakpointSM = "768px";
export var breakpoints = "\"@theme/breakpoints.module.css\"";
export var colors = "\"@theme/colors.module.css\"";
export var style_component = "Modal-module--style_component--6c32f animations-module--animation_fadeIn_005_linear--b3af0";
export var style_frame = "Modal-module--style_frame--df141";
export var style_margin = "Modal-module--style_margin--ac98c";
export var style_overlay = "Modal-module--style_overlay--6c55d";