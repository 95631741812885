import React from 'react'

import { devilsGrass } from '@theme/colors.module.css'

const GlassdoorCircle = props => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" {...props}>
    <mask id="path-1" fill="white">
      <path d="M40 0C50.5028 1.25245e-07 60.5842 4.13082 68.0674 11.5005C75.5505 18.8701 79.8349 28.8873 79.9953 39.3889C80.1558 49.8905 76.1795 60.0338 68.925 67.6287C61.6705 75.2235 51.72 79.6604 41.2221 79.9813C30.7242 80.3022 20.5213 76.4813 12.8165 69.3437C5.11167 62.2062 0.523249 52.3246 0.0420113 41.8328C-0.439226 31.341 3.22533 21.0809 10.2444 13.2679C17.2634 5.455 27.0737 0.716152 37.5569 0.0746766L38.1666 10.0375C30.2993 10.5189 22.937 14.0753 17.6695 19.9386C12.402 25.8019 9.65188 33.5018 10.013 41.3754C10.3742 49.2491 13.8176 56.6649 19.5998 62.0214C25.3819 67.3779 33.0389 70.2453 40.9171 70.0045C48.7954 69.7637 56.2629 66.4339 61.7071 60.7343C67.1513 55.0346 70.1354 47.4224 70.015 39.5414C69.8946 31.6603 66.6793 24.1428 61.0635 18.6122C55.4477 13.0815 47.882 9.9815 40 9.9815V0Z" />
    </mask>
    <path
      stroke={devilsGrass}
      strokeWidth="20"
      mask="url(#path-1)"
      d="M40 0C50.5028 1.25245e-07 60.5842 4.13082 68.0674 11.5005C75.5505 18.8701 79.8349 28.8873 79.9953 39.3889C80.1558 49.8905 76.1795 60.0338 68.925 67.6287C61.6705 75.2235 51.72 79.6604 41.2221 79.9813C30.7242 80.3022 20.5213 76.4813 12.8165 69.3437C5.11167 62.2062 0.523249 52.3246 0.0420113 41.8328C-0.439226 31.341 3.22533 21.0809 10.2444 13.2679C17.2634 5.455 27.0737 0.716152 37.5569 0.0746766L38.1666 10.0375C30.2993 10.5189 22.937 14.0753 17.6695 19.9386C12.402 25.8019 9.65188 33.5018 10.013 41.3754C10.3742 49.2491 13.8176 56.6649 19.5998 62.0214C25.3819 67.3779 33.0389 70.2453 40.9171 70.0045C48.7954 69.7637 56.2629 66.4339 61.7071 60.7343C67.1513 55.0346 70.1354 47.4224 70.015 39.5414C69.8946 31.6603 66.6793 24.1428 61.0635 18.6122C55.4477 13.0815 47.882 9.9815 40 9.9815V0Z"
    />
  </svg>
)

export default GlassdoorCircle
