import React, { FunctionComponent } from 'react'

import { ITwoColumnLayoutProps } from './TwoColumnLayout.types'

import {
  styles_component,
  styles_sidebar,
  styles_content
} from './TwoColumnLayout.module.css'

const TwoColumnLayout: FunctionComponent<ITwoColumnLayoutProps> = ({
  content,
  sidebar: Sidebar
}) => (
  <div className={styles_component}>
    <div className={styles_sidebar}>{Sidebar && <Sidebar />}</div>
    <div className={styles_content}>{content}</div>
  </div>
)

export default TwoColumnLayout
