import React from 'react'

import { squant } from '@theme/colors.module.css'

const Unlock = props => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.3889 5.95166H2.61111C1.99746 5.95166 1.5 6.44402 1.5 7.05137V10.9004C1.5 11.5077 1.99746 12.0001 2.61111 12.0001H10.3889C11.0025 12.0001 11.5 11.5077 11.5 10.9004V7.05137C11.5 6.44402 11.0025 5.95166 10.3889 5.95166Z"
      stroke={squant}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.72266 5.95146V3.75204C3.72197 3.07025 3.97726 2.41252 4.43898 1.90655C4.90069 1.40057 5.5359 1.08244 6.22127 1.01393C6.90665 0.945406 7.5933 1.13138 8.14792 1.53575C8.70255 1.94012 9.08558 2.53403 9.22266 3.20219"
      stroke={squant}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Unlock
