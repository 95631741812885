import React, { FunctionComponent } from 'react'

import type { IConnectAccountsProps } from './ConnectAccounts.types'

import {
  style_component,
  style_title,
  style_description,
  style_emailContainer,
  style_link
} from './ConnectAccounts.module.css'

const ConnectAccounts: FunctionComponent<IConnectAccountsProps> = ({
  resendEmailHandler,
  isEmailSent
}) => {
  if (!resendEmailHandler) {
    return null
  }

  return (
    <div data-testid="ConnectAccounts" className={style_component}>
      <div>
        <p className={style_title}>Connect your Account.</p>
        <span className={style_description}>
          We see you have logged in to the X-Team Jobs board via LinkedIn.
          You’re seeing this page because we’ve found an account already exists
          with the same email address.
        </span>
        <p className={style_description}>
          We have sent you an email that will allow you to confirm both accounts
          belong to you and to authorize LinkedIn login.
        </p>
      </div>
      <div className={style_emailContainer}>
        <div>Didn’t receive an email?</div>
        {isEmailSent ? (
          <span>
            Email sent!{' '}
            <a href="mailto:support@x-team.com" className={style_link}>
              Contact support
            </a>{' '}
            if you haven’t received anything yet.
          </span>
        ) : (
          <>
            <span className={style_link} onClick={resendEmailHandler}>
              Click here to resend
            </span>
            , or{' '}
            <a href="mailto:support@x-team.com" className={style_link}>
              contact support
            </a>
          </>
        )}
      </div>
    </div>
  )
}
export default ConnectAccounts
