// extracted by mini-css-extract-plugin
export var Body_Medium_XS = "typography-module--Body_Medium_XS--cadd2";
export var Neutral_8 = "#0C0C0C";
export var bleachedSilk = "#F2F2F2";
export var breakpointSM = "768px";
export var breakpoints = "\"@theme/breakpoints.module.css\"";
export var colors = "\"@theme/colors.module.css\"";
export var fs13_fw600 = "typography-module--fs13_fw600--ee3e2";
export var fs18_fw700 = "typography-module--fs18_fw700--08853";
export var mediumCandyAppleRed = "#E50030";
export var soothingWhite = "#E2E2E3";
export var style_buttons = "UpdateCV-module--style_buttons--03c3d";
export var style_component = "UpdateCV-module--style_component--97e38 typography-module--typeface_base--8d193";
export var style_fileInfo = "UpdateCV-module--style_fileInfo--2cf0f";
export var style_infoLine = "UpdateCV-module--style_infoLine--0173a typography-module--fs13_fw600--ee3e2";
export var style_secondary = "UpdateCV-module--style_secondary--59f7f";
export var style_subtitle = "UpdateCV-module--style_subtitle--9824d typography-module--Body_Medium_XS--cadd2 typography-module--fs13_fw600--ee3e2";
export var style_title = "UpdateCV-module--style_title--7c7f4 typography-module--fs18_fw700--08853";
export var style_warning = "UpdateCV-module--style_warning--ed505 typography-module--fs13_fw600--ee3e2";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "\"@theme/typography.module.css\"";