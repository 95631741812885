// extracted by mini-css-extract-plugin
export var Interface_SemiBold_S = "typography-module--Interface_SemiBold_S--af7a9";
export var Neutral_1 = "#EFEFEF";
export var Neutral_2 = "#CFCFCF";
export var Neutral_5 = "#707070";
export var colors = "'@theme/colors.module.css'";
export var soothingWhite = "#E2E2E3";
export var squant = "#666666";
export var style_default = "CardTags-module--style_default--422c7";
export var style_extra_tag_count = "CardTags-module--style_extra_tag_count--e651c";
export var style_extra_tag_count_clickable = "CardTags-module--style_extra_tag_count_clickable--9b479";
export var style_extra_tag_count_only_child = "CardTags-module--style_extra_tag_count_only_child--444c7";
export var style_redesign = "CardTags-module--style_redesign--f4cf0";
export var style_tag = "CardTags-module--style_tag--0e87d";
export var style_tag_hidden = "CardTags-module--style_tag_hidden--ee851";
export var style_tag_visible = "CardTags-module--style_tag_visible--0bdde";
export var style_tags = "CardTags-module--style_tags--b8573 typography-module--typeface_base--8d193 typography-module--Interface_SemiBold_S--af7a9";
export var style_tags_expanded = "CardTags-module--style_tags_expanded--c6aaf";
export var style_tags_hidden = "CardTags-module--style_tags_hidden--b5809";
export var style_tags_row = "CardTags-module--style_tags_row--b4351";
export var style_tags_visible = "CardTags-module--style_tags_visible--620aa";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "'@theme/typography.module.css'";
export var white = "#FFFFFF";