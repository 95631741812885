// extracted by mini-css-extract-plugin
export var Interface_SemiBold_S = "typography-module--Interface_SemiBold_S--af7a9";
export var Interface_SemiBold_XL = "typography-module--Interface_SemiBold_XL--876a5";
export var Neutral_1 = "#EFEFEF";
export var Neutral_4 = "#8F8F8F";
export var Neutral_8 = "#0C0C0C";
export var breakpointSM = "768px";
export var breakpoints = "\"@theme/breakpoints.module.css\"";
export var colors = "'@theme/colors.module.css'";
export var style_answers = "FaqCard-module--style_answers--8e795 typography-module--Interface_SemiBold_S--af7a9";
export var style_component = "FaqCard-module--style_component--687fa typography-module--typeface_base--8d193";
export var style_selected = "FaqCard-module--style_selected--d0e87";
export var style_tags = "FaqCard-module--style_tags--90918";
export var style_title = "FaqCard-module--style_title--d7e21 typography-module--Interface_SemiBold_XL--876a5";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "'@theme/typography.module.css'";