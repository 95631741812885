import React from 'react'

import { Neutral_8 } from '@theme/colors.module.css'

const OpenUrl = ({ stroke = Neutral_8, ...props }) => (
  <svg
    width="17"
    height="15"
    viewBox="0 0 17 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.57143 3H4.71429C4.3198 3 4 3.3198 4 3.71429V12.2857C4 12.6802 4.3198 13 4.71429 13H13.2857C13.6802 13 14 12.6802 14 12.2857V9.42857"
      stroke={stroke}
      strokeWidth="2"
    />
    <path
      d="M15 2L16 2V1H15V2ZM16 7L16 2L14 2L14 7H16ZM15 1H10V3H15V1Z"
      fill={stroke}
    />
    <path
      d="M10.7071 7.70711L15.7071 2.70711L14.2929 1.29289L9.29289 6.29289L10.7071 7.70711Z"
      fill={stroke}
    />
  </svg>
)

export default OpenUrl
