import React from 'react'

const Conference = props => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 13 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.77144 9.00002V8.11114C8.77144 7.63965 8.57991 7.18747 8.239 6.85407C7.89808 6.52067 7.4357 6.33337 6.95358 6.33337H3.31786C2.83573 6.33337 2.37335 6.52067 2.03244 6.85407C1.69152 7.18747 1.5 7.63965 1.5 8.11114V9.00002"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.13427 4.55553C6.13824 4.55553 6.95212 3.7596 6.95212 2.77777C6.95212 1.79593 6.13824 1 5.13427 1C4.13029 1 3.31641 1.79593 3.31641 2.77777C3.31641 3.7596 4.13029 4.55553 5.13427 4.55553Z"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5001 8.99998V8.1111C11.4998 7.71721 11.3658 7.33456 11.119 7.02325C10.8722 6.71194 10.5267 6.48959 10.1367 6.39111"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.31641 1.05786C8.70743 1.15577 9.05402 1.37817 9.30152 1.68999C9.54902 2.00182 9.68336 2.38533 9.68336 2.78007C9.68336 3.17481 9.54902 3.55832 9.30152 3.87015C9.05402 4.18197 8.70743 4.40437 8.31641 4.50228"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Conference
