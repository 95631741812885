import React from 'react'

import { alpha_Neutral_8_40 } from '@theme/colors.module.css'

const Checkmark = ({ strokeColor = alpha_Neutral_8_40, ...props }) => (
  <svg width="13" height="10" viewBox="0 0 13 10" fill="none" {...props}>
    <path
      d="M1.66797 5.49975L4.9678 8.79958L12.0389 1.72852"
      stroke={strokeColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Checkmark
