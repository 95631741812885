// extracted by mini-css-extract-plugin
export var Display_Black_40 = "typography-module--Display_Black_40--ecc82";
export var Interface_Medium_L = "typography-module--Interface_Medium_L--d5d89";
export var Neutral_3 = "#AFAFAF";
export var Neutral_5 = "#707070";
export var black = "#000000";
export var breakpointSM = "768px";
export var breakpoints = "\"@theme/breakpoints.module.css\"";
export var colors = "\"@theme/colors.module.css\"";
export var style_component = "ConnectAccounts-module--style_component--0cdfc typography-module--typeface_base--8d193";
export var style_description = "ConnectAccounts-module--style_description--30ac4 typography-module--Interface_Medium_L--d5d89";
export var style_emailContainer = "ConnectAccounts-module--style_emailContainer--186d8 typography-module--Interface_Medium_L--d5d89";
export var style_link = "ConnectAccounts-module--style_link--c7d7a";
export var style_title = "ConnectAccounts-module--style_title--2b1fc typography-module--Display_Black_40--ecc82";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "\"@theme/typography.module.css\"";
export var white = "#FFFFFF";