// extracted by mini-css-extract-plugin
export var Body_Medium_M = "typography-module--Body_Medium_M--7b04f";
export var Body_Medium_S = "typography-module--Body_Medium_S--0eee9";
export var Body_Medium_XS = "typography-module--Body_Medium_XS--cadd2";
export var Neutral_8 = "#0C0C0C";
export var breakpointSM = "768px";
export var breakpoints = "\"@theme/breakpoints.module.css\"";
export var colors = "\"@theme/colors.module.css\"";
export var fs30_fw800 = "typography-module--fs30_fw800--042dc";
export var fs40_fw800 = "typography-module--fs40_fw800--61c55";
export var style_button = "LockedFeatures-module--style_button--82110";
export var style_component = "LockedFeatures-module--style_component--79f38 typography-module--typeface_base--8d193";
export var style_extra = "LockedFeatures-module--style_extra--7be18 typography-module--Body_Medium_XS--cadd2";
export var style_message = "LockedFeatures-module--style_message--ded76 typography-module--Body_Medium_S--0eee9 typography-module--Body_Medium_M--7b04f";
export var style_title = "LockedFeatures-module--style_title--05ab7 typography-module--fs30_fw800--042dc typography-module--fs40_fw800--61c55";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "\"@theme/typography.module.css\"";
export var white = "#FFFFFF";