import { SVGS } from '@components/redesign/Svgs'

import { INotificationVariations } from './NotificationBarLayout.types'

import {
  style_wrapper_yellow_background,
  style_wrapper_red_background,
  style_wrapper_green_background,
  style_yellow_background,
  style_red_background,
  style_green_background,
  style_message_yellow,
  style_message_red,
  style_message_green
} from './NotificationBarLayout.module.css'

export const NOTIFICATION_VARIATIONS: INotificationVariations = {
  info: {
    name: 'info',
    iconName: SVGS.Bell,
    barBackgroundColorClass: style_wrapper_yellow_background,
    backgroundColorClass: style_yellow_background,
    messageColorClass: style_message_yellow
  },
  warning: {
    name: 'warning',
    iconName: SVGS.Warning,
    barBackgroundColorClass: style_wrapper_yellow_background,
    backgroundColorClass: style_yellow_background,
    messageColorClass: style_message_yellow
  },
  error: {
    name: 'error',
    iconName: SVGS.Exclamation,
    barBackgroundColorClass: style_wrapper_red_background,
    backgroundColorClass: style_red_background,
    messageColorClass: style_message_red
  },
  success: {
    name: 'success',
    iconName: SVGS.Success,
    barBackgroundColorClass: style_wrapper_green_background,
    backgroundColorClass: style_green_background,
    messageColorClass: style_message_green
  }
}
