import React from 'react'

import { squant } from '@theme/colors.module.css'

const ArrowLeft = ({ stroke = squant, ...props }) => (
  <svg
    width="7"
    height="10"
    viewBox="0 0 7 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M6 1L2 5L6 9" stroke={stroke} strokeWidth="1.5" />
  </svg>
)

export default ArrowLeft
