/**
 * Custom hook that queries for data with
 * Gatsby's useStaticQuery React hook.
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from 'gatsby'

import { ISiteMetadata } from './useSiteMetadata.types'

const useSiteMetadata: ISiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            title
            description
            author
            image
          }
        }
      }
    `
  )
  return site.siteMetadata
}

export default useSiteMetadata
