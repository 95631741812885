import { createContext } from 'react'

import { IUseUTMParamsContext } from './useUTMParamsContext.types'

export const initialState = {
  utmParams: {},
  clearUtmParams: () => {}
}

const UTMParamsContext = createContext<IUseUTMParamsContext>(initialState)

export default UTMParamsContext
