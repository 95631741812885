// extracted by mini-css-extract-plugin
export var breakpointLG = "1280px";
export var breakpoints = "\"@theme/breakpoints.module.css\"";
export var colors = "\"@theme/colors.module.css\"";
export var fs18_fw700 = "typography-module--fs18_fw700--08853";
export var gradient_highBlue_guillimanBlue = "linear-gradient(269.33deg, #45A4E9 23.46%, #699BFE 67.41%), linear-gradient(317.24deg, #FFFFFF 0%, #F2F2F2 100%)";
export var style_card = "WorkWithTopBrands-module--style_card--7246f typography-module--typeface_base--8d193";
export var style_cardTitle = "WorkWithTopBrands-module--style_cardTitle--79ff4 typography-module--fs18_fw700--08853";
export var style_icon = "WorkWithTopBrands-module--style_icon--7312b";
export var style_iconContainer = "WorkWithTopBrands-module--style_iconContainer--23a70";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "\"@theme/typography.module.css\"";
export var white = "#FFFFFF";