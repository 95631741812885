import React from 'react'

import { squant } from '@theme/colors.module.css'

const File = props => (
  <svg
    width="58"
    height="58"
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M43.4997 50.75H14.4997C13.165 50.75 12.083 49.668 12.083 48.3333L12.083 9.66667C12.083 8.33198 13.165 7.25 14.4997 7.25L32.7771 7.25C33.4503 7.25 34.093 7.5308 34.5504 8.02477L45.2729 19.6051C45.6866 20.0518 45.9163 20.6382 45.9163 21.247L45.9164 48.3333C45.9164 49.668 44.8344 50.75 43.4997 50.75Z"
      stroke={squant}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.917 21.75L33.8337 21.75C32.499 21.75 31.417 20.668 31.417 19.3333L31.417 7.25"
      stroke={squant}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default File
