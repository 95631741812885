import React, { FunctionComponent } from 'react'

import { FormLayout } from '@components/redesign/FormLayout'

import { IFormLayoutContainerProps } from './FormLayoutContainer.types'

const FormLayoutContainer: FunctionComponent<IFormLayoutContainerProps> = props => {
  const { ...formLayoutProps } = props

  return <FormLayout {...formLayoutProps} />
}

export default FormLayoutContainer
