import React from 'react'

import icon4 from './assets/AT&T.svg'
import icon2 from './assets/DELL.svg'
import icon1 from './assets/FOX.svg'
import icon3 from './assets/INTEL.svg'
import icon5 from './assets/RIOT.svg'

import {
  style_card,
  style_cardTitle,
  style_iconContainer,
  style_icon
} from './WorkWithTopBrands.module.css'

const WorkWithTopBrands = () => (
  <div className={style_card}>
    <span className={style_cardTitle}>WORK WITH TOP BRANDS</span>
    <div className={style_iconContainer}>
      <img alt="FOX icon" className={style_icon} src={icon1} />
      <img alt="Dell icon" className={style_icon} src={icon2} />
      <img alt="Intel icon" className={style_icon} src={icon3} />
      <img alt="AT&T Icon" className={style_icon} src={icon4} />
      <img alt="FOX Icon" className={style_icon} src={icon5} />
    </div>
  </div>
)

export default WorkWithTopBrands
