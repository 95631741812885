import { useEffect } from 'react'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'

import { useAuthContext } from '@hooks/useAuthContext'
import { getIsLinkedInUser } from '@utils/authHelpers'
import { isNetworkError } from '@utils/helpers'
import { getPageRedirectConfig } from '@utils/redirectHelpers'

import { IUser } from '@type/user'

const useRedirections = () => {
  const {
    user,
    loading: isLoadingUser,
    error: authError,
    hasUserSignedOut
  } = useAuthContext()
  const isServerDown = isNetworkError(authError)
  const { pathname = '', search = '', hash = '' } = useLocation()

  useEffect(() => {
    if (isLoadingUser || isServerDown) {
      return
    }

    const loadPageRedirection = async () => {
      const { navigateTo } = await getPageRedirectConfig(
        (user as unknown) as IUser,
        hasUserSignedOut,
        pathname,
        search,
        getIsLinkedInUser(user)
      )

      if (navigateTo) {
        navigate(navigateTo)
      }
    }

    loadPageRedirection()
  }, [
    isLoadingUser,
    user,
    hasUserSignedOut,
    pathname,
    search,
    hash,
    isServerDown
  ])
}

export default useRedirections
