import React from 'react'

import { squant } from '@theme/colors.module.css'

const Menu = props => (
  <svg
    width="16"
    height="8"
    viewBox="0 0 16 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="16" height="1.5" fill={squant} />
    <rect y="6.5" width="16" height="1.5" fill={squant} />
  </svg>
)

export default Menu
