import React, { FunctionComponent } from 'react'

import { Button } from '@components/redesign/Button'
import { LinkedinUploadButton } from '@components/redesign/LinkedinUploadButton'
import { ModalBox } from '@components/redesign/Modal/ModalBox'
import { Svg, SVGS } from '@components/redesign/Svgs'

import { IUploadCVProps } from './UploadCV.types'

import {
  style_component,
  style_dropArea,
  style_title,
  style_subtitle,
  style_tips,
  style_recommended,
  style_tipsButton,
  style_infoLine,
  style_heading,
  style_dragging
} from './UploadCV.module.css'

const UploadCV: FunctionComponent<IUploadCVProps> = ({
  handleBrowseFile,
  getRootProps = {},
  getInputProps,
  uploadPercent = 0,
  somethingWrong,
  handleLinkedInClick,
  showLinkedInButton,
  showHeading,
  isDragActive
}) => {
  const handleTipsButtonClick = () => {
    window &&
      window.open(
        'https://x-team.com/blog/make-your-x-team-profile-stand-out/',
        '_blank'
      )
  }

  const title = somethingWrong ? 'Something went wrong.' : 'Upload your CV here'

  const subtitle = somethingWrong
    ? 'Wrong file format, please try again with another format.'
    : 'Drag and drop, or click on the button.'

  const renderFileInfoBlock = () => {
    if (uploadPercent > 0) {
      return <p className={style_infoLine}>Uploading... {uploadPercent}%</p>
    }

    return (
      <>
        {showLinkedInButton && (
          <LinkedinUploadButton onClick={handleLinkedInClick} />
        )}
        <Button onClick={handleBrowseFile} testid="UploadCV-browse">
          Upload CV
        </Button>
      </>
    )
  }

  const dropAreaClassNames = [
    style_dropArea,
    isDragActive ? style_dragging : ''
  ].join(' ')

  return (
    <section className={style_component} data-testid="UploadCV">
      <ModalBox>
        {showHeading && (
          <h1 className={style_heading}>Make your profile stand out</h1>
        )}
        <div {...getRootProps} className={dropAreaClassNames}>
          {getInputProps && <input {...getInputProps} />}
          {isDragActive ? (
            <Svg name={SVGS.ThinPlus} />
          ) : (
            <>
              <h2 className={style_title}>{title}</h2>
              <p className={style_subtitle}>{subtitle}</p>
              {renderFileInfoBlock()}
            </>
          )}
        </div>
      </ModalBox>
      <ModalBox>
        <div className={style_tips}>
          <p className={style_recommended}>
            <strong>Recommended formats:</strong> .pdf, .doc, .docx, .odt
          </p>
          <Button
            data-value="cv-upload-tips"
            color="secondary"
            outlined
            className={style_tipsButton}
            onClick={handleTipsButtonClick}
          >
            See our tips on writing a good CV
          </Button>
        </div>
      </ModalBox>
    </section>
  )
}

export default UploadCV
