import React, { FunctionComponent } from 'react'
import {
  createMemorySource,
  createHistory,
  LocationProvider
} from '@reach/router'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import ApplicationHistoryLayout from './ApplicationHistoryLayout'

const source = createMemorySource('/jobs/my-application/')
const history = createHistory(source)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity
    }
  }
})

const storiesConfig = {
  title: 'Layout components/ApplicationHistoryLayout',
  component: ApplicationHistoryLayout,
  argTypes: {},
  parameters: {
    layout: 'fullscreen',
    controls: {
      exclude: 'children'
    }
  },
  decorators: [
    (Story: FunctionComponent) => (
      <QueryClientProvider client={queryClient}>
        <LocationProvider history={history}>
          <Story />
        </LocationProvider>
      </QueryClientProvider>
    )
  ]
}

export { ApplicationHistoryLayout, storiesConfig }
