import React from 'react'

const Bell = ({ fillStrokeColor = 'white', ...rest }) => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M11.6667 4.74244C11.6667 3.48467 11.175 2.27841 10.2998 1.38903C9.42466 0.499648 8.23768 0 7 0C5.76232 0 4.57534 0.499648 3.70017 1.38903C2.825 2.27841 2.33333 3.48467 2.33333 4.74244C2.33333 7.71946 1.65781 9.55232 1.0337 10.6104C0.783347 11.0348 1.13668 11.8561 1.62946 11.8561H12.3705C12.8633 11.8561 13.2167 11.0348 12.9663 10.6104C12.3422 9.55232 11.6667 7.71946 11.6667 4.74244Z"
      fill={fillStrokeColor}
    />
    <path
      d="M7.90098 13.2986C7.78406 13.5119 7.61625 13.689 7.41435 13.8121C7.21245 13.9352 6.98354 14 6.75054 14C6.51754 14 6.28863 13.9352 6.08672 13.8121C5.88482 13.689 5.71701 13.5119 5.6001 13.2986"
      stroke={fillStrokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Bell
