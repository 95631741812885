import React, { FunctionComponent, useEffect } from 'react'
import { useLocation } from '@reach/router'
import queryString from 'query-string'

import { JobCardListContainer } from '@containers/JobCardListContainer'
import { TwoColumnLayout } from '@components/redesign/TwoColumnLayout'

import { useAuthContext } from '@hooks/useAuthContext'
import { useAuthScreensContext } from '@hooks/useAuthScreensContext'

import { IJobsPageLayoutProps } from './JobsPageLayout.types'

const JobsPageLayout: FunctionComponent<IJobsPageLayoutProps> = ({
  content
}) => {
  const { user, loading: isLoadingUser, isUserUpdated } = useAuthContext()
  const { isAuthScreensVisible, showLoginForm, showCreateAccountForm } =
    useAuthScreensContext()

  const { search = '' } = useLocation()
  const parsedSearch = queryString.parse(search)
  const isSignupInSearch = 'signup' in parsedSearch
  const isLoginInSearch = 'login' in parsedSearch
  const isAuthenticated = Boolean(user && !isLoadingUser && isUserUpdated)

  // Automatically show the AuthScreens when redirected from ?signup or ?login
  useEffect(() => {
    if (!isAuthScreensVisible && !isAuthenticated) {
      if (isSignupInSearch) {
        showCreateAccountForm({ canBeClosed: false })
      }
      if (isLoginInSearch) {
        showLoginForm({ canBeClosed: false })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthScreensVisible, isAuthenticated, isLoginInSearch, isSignupInSearch])

  return <TwoColumnLayout sidebar={JobCardListContainer} content={content} />
}

export default JobsPageLayout
