// extracted by mini-css-extract-plugin
export var alpha_white_10 = "rgba(255, 255, 255, 0.1)";
export var alpha_white_20 = "rgba(255, 255, 255, 0.2)";
export var alpha_white_40 = "rgba(255, 255, 255, 0.4)";
export var alpha_white_50 = "rgba(255, 255, 255, 0.5)";
export var colors = "\"@theme/colors.module.css\"";
export var fs11_fw600 = "typography-module--fs11_fw600--fce4e";
export var fs18_fw700 = "typography-module--fs18_fw700--08853";
export var gradient_devilsGrass2_arubaGreen = "linear-gradient(269.53deg, #45AF56 15.74%, #50B691 84.75%)";
export var style_card = "GlassdoorMostLovedCompany-module--style_card--282c5 typography-module--typeface_base--8d193";
export var style_cardTitle = "GlassdoorMostLovedCompany-module--style_cardTitle--aa468 typography-module--fs18_fw700--08853";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "\"@theme/typography.module.css\"";
export var white = "#FFFFFF";