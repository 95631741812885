import React from 'react'

import { Green_2 } from '@theme/colors.module.css'

const TickCircle = ({ stroke = Green_2, ...props }) => (
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M100 175C141.421 175 175 141.421 175 100C175 58.5786 141.421 25 100 25C58.5786 25 25 58.5786 25 100C25 141.421 58.5786 175 100 175Z"
      stroke={stroke}
      strokeWidth="12"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M125 83.3335L91.6667 116.667L75 100"
      stroke={stroke}
      strokeWidth="12"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default TickCircle
