import React, { Suspense, useEffect, useState } from 'react'

import { CookieBanner } from '@components/redesign/CookieBanner'
import { cookieBannerName } from '@components/redesign/CookieBanner/CookieBanner.constants'

import { useModal } from '@hooks/useModal'
import { getCookie } from '@utils/cookieHelpers'

import {
  ICookieBannerModal,
  RenderCookieBannerModal
} from './useCookieBanner.types'

const Modal = React.lazy(() => import('@components/redesign/Modal/Modal'))

const useCookieBanner = (): ICookieBannerModal => {
  const cookieEnabled = getCookie(cookieBannerName)

  const [resetCookieBanner, setResetCookieBanner] = useState(false)
  const { isVisible, toggleModal } = useModal()

  useEffect(() => {
    if (cookieEnabled === undefined) {
      toggleModal()
    }
  }, [])

  const handlerReloadCookieBanner = (choice: boolean) => {
    setResetCookieBanner(choice)
    if (choice) {
      window.location.reload()
    }
    toggleModal()
  }

  const renderCookieBanner: RenderCookieBannerModal = () =>
    isVisible && (
      <Suspense fallback={<div />}>
        <Modal>
          <CookieBanner
            resetCookieBanner={resetCookieBanner}
            setResetCookieBanner={handlerReloadCookieBanner}
          />
        </Modal>
      </Suspense>
    )

  return {
    renderCookieBanner
  }
}

export default useCookieBanner
