import React, { forwardRef } from 'react'
import { Link, navigate } from 'gatsby'

import { useAuthContext } from '@hooks/useAuthContext'
import { getProfileUpdateProgress } from '@utils/profileHelpers'

import {
  style_dialogMenu,
  style_dialogMenuItem,
  style_link,
  style_profileButton,
  style_break
} from './AvatarMenu.module.css'

const AvatarMenu = ({ onClick }, ref) => {
  const { signOut, profile } = useAuthContext()

  const handleSignOut = e => {
    e.preventDefault()
    navigate('/')
    signOut(true)
  }

  const profileLinkLabel =
    getProfileUpdateProgress(profile) < 100
      ? 'Complete Profile'
      : 'View Profile'

  return (
    <ul ref={ref} className={style_dialogMenu}>
      <li className={style_dialogMenuItem}>
        <Link className={style_link} to="/profile/" onClick={onClick}>
          <button className={style_profileButton}>{profileLinkLabel}</button>
        </Link>
      </li>
      <li className={style_dialogMenuItem}>
        <Link
          className={style_link}
          to="/jobs/my-applications/"
          onClick={onClick}
        >
          Application History
        </Link>
      </li>
      <hr className={style_break} />
      <li className={style_dialogMenuItem}>
        <Link className={style_link} to="/settings/" onClick={onClick}>
          Settings
        </Link>
      </li>
      <hr className={style_break} />
      <li className={style_dialogMenuItem}>
        <Link className={style_link} to="/faq/" onClick={onClick}>
          FAQ
        </Link>
      </li>
      <hr className={style_break} />
      <li className={style_dialogMenuItem}>
        <a className={style_link} href="/" onClick={handleSignOut}>
          Sign Out
        </a>
      </li>
    </ul>
  )
}

export default forwardRef(AvatarMenu)
