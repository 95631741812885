import React, { ForwardRefRenderFunction, forwardRef } from 'react'

import { Avatar } from '@components/redesign/Avatar'
import { Svg, SVGS } from '@components/redesign/Svgs'

import type { IProfileAvatarProps } from './ProfileAvatar.types'

import {
  style_component,
  style_hamburger,
  style_avatarIcon,
  style_avatarItem,
  style_notification
} from './ProfileAvatar.module.css'
import { black, white } from '@theme/colors.module.css'

const ProfileAvatar: ForwardRefRenderFunction<HTMLDivElement, IProfileAvatarProps> = ({
  onClick,
  fullname,
  imageSrc,
  hasProfileNotification
}, ref) => (
  <div
    ref={ref}
    data-testid="ProfileAvatar"
    className={style_component}
    onClick={onClick}
  >
    {hasProfileNotification && (
      <div className={style_notification} data-testid="ProfileAvatar-notification" />
    )}
    <div className={style_hamburger}>
      <Svg name={SVGS.MenuHamburger} />
    </div>
    {imageSrc ? (
      <div className={style_avatarItem} data-testid="ProfileAvatar-avatar">
        <Avatar
          alt={fullname}
          color={white}
          textColor={black}
          maxInitials={2}
          src={imageSrc}
          textSizeRatio={2.5}
          size="34"
        />
      </div>
    ) : (
      <div className={style_avatarIcon} data-testid="ProfileAvatar-icon">
        <Svg name={SVGS.Profile} />
      </div>
    )}
  </div>
)

export default forwardRef(ProfileAvatar)
