import { isDateNewerThanSpecifiedTime } from '@utils/helpers'
import { JOB_APPLICATION_STAGES } from '@config/constants'

import { SelectJobFromSlugArgs } from './jobHelpers.types'
import { IJobApplication } from '@type/jobApplication'

export const getApplicantMenuLinks = () => [
  {
    label: 'Browse Jobs',
    to: `/jobs/`
  },
  {
    label: 'My Applications',
    to: `/jobs/my-applications/`
  },
  {
    label: 'Blog',
    href: 'https://x-team.com/blog/'
  },
  {
    label: 'Unleash+',
    href: 'https://x-team.com/blog/unleash/'
  }
]

export const isPendingJobApplication = ({ closedAt, stage }: IJobApplication) =>
  !closedAt && stage === JOB_APPLICATION_STAGES.PENDING

export const isActivePreviousApplication = ({ active }: IJobApplication) =>
  active === false

export const getActivePreviousJobApplications = (
  jobApplications: IJobApplication[] = [],
  dateField: string
) => {
  // In the Gregorian calendar, an average month has exactly 30.436875 days.
  // It was originally based on the time it takes for the moon to rotate the Earth.
  // See: https://en.wikipedia.org/wiki/Month#Julian_and_Gregorian_calendars
  const threeMonthsInMs = 3 * 30.436875 * 24 * 60 * 60 * 1000

  const previousApplicationStages = [
    JOB_APPLICATION_STAGES.SUCCESSFUL,
    JOB_APPLICATION_STAGES.REJECTED,
    JOB_APPLICATION_STAGES.EXPIRED,
    JOB_APPLICATION_STAGES.WITHDRAWN
  ]

  return jobApplications
    .filter(
      ja =>
        Boolean(ja.closedAt) &&
        isDateNewerThanSpecifiedTime(ja[dateField], threeMonthsInMs) &&
        ja.stage &&
        previousApplicationStages.includes(ja.stage)
    )
    .sort(
      (a, b) =>
        new Date(b.appliedAt).getTime() - new Date(a.appliedAt).getTime()
    )
}

export const getActivePreviousJobApplicationsByAppliedAtDate = (
  jobApplications: IJobApplication[] = []
) => getActivePreviousJobApplications(jobApplications, 'appliedAt')

export const getSelectedJobSlug = (pathname: string) =>
  pathname
    ?.split('/')[2]
    ?.trim()
    ?.toLowerCase()

export const selectJobFromSlug = ({
  jobs = [],
  selectedJobSlug = ''
}: SelectJobFromSlugArgs) =>
  jobs.find(({ slug }) =>
    slug
      ?.trim()
      ?.toLowerCase()
      ?.includes(selectedJobSlug)
  )
