import React, { forwardRef } from 'react'
import { Link, navigate } from 'gatsby'

import { useAuthContext } from '@hooks/useAuthContext'
import { getProfileUpdateProgress } from '@utils/profileHelpers'

import {
  style_dialogMenu,
  style_dialogMenuItem,
  style_link,
  style_profileButton,
  style_logOutButton
} from './MobileHeaderMenu.module.css'

const MobileHeaderMenu = (_, ref) => {
  const { signOut, profile } = useAuthContext()

  const handleSignOut = e => {
    e.preventDefault()
    navigate('/')
    signOut(true)
  }

  const profileLinkLabel =
    getProfileUpdateProgress(profile) < 100
      ? 'Complete Profile'
      : 'View Profile'

  return (
    <ul ref={ref} className={style_dialogMenu}>
      <li className={style_dialogMenuItem}>
        <Link className={style_link} to="/profile/">
          <button className={style_profileButton}>{profileLinkLabel}</button>
        </Link>
      </li>
      <li className={style_dialogMenuItem}>
        <Link className={style_link} to="/jobs/">
          Jobs
        </Link>
      </li>
      <li className={style_dialogMenuItem}>
        <a
          className={style_link}
          href="https://x-team.com/blog/"
          target="_blank"
          rel="noreferrer"
        >
          Blog
        </a>
      </li>
      <li className={style_dialogMenuItem}>
        <a
          className={style_link}
          href="https://x-team.com/blog/unleash/"
          target="_blank"
          rel="noreferrer"
        >
          Unleash+
        </a>
      </li>
      <li className={style_dialogMenuItem}>
        <Link className={style_link} to="/jobs/my-applications/">
          Application History
        </Link>
      </li>
      <li className={style_dialogMenuItem}>
        <Link className={style_link} to="/settings/">
          Settings
        </Link>
      </li>
      <li className={style_dialogMenuItem}>
        <Link className={style_link} to="/faq/">
          FAQ
        </Link>
      </li>
      <li className={style_dialogMenuItem}>
        <button className={style_logOutButton} onClick={handleSignOut}>
          Sign Out
        </button>
      </li>
    </ul>
  )
}

export default forwardRef(MobileHeaderMenu)
