import React, { FunctionComponent, useEffect } from 'react'

import { NotificationContainer } from '@containers/NotificationContainer'
import { HeaderContainer } from '@components/app/Header'
import { AppLayout } from '@components/redesign/AppLayout'
import { Spinner } from '@components/redesign/Spinner'

import { useAuthContext } from '@hooks/useAuthContext'
import { useAuthScreensContext, TRIGGERS } from '@hooks/useAuthScreensContext'
import { JobsProvider } from '@hooks/useJobsContext'
import { isSSR } from '@utils/helpers'

import { IAppLayoutContainerProps } from './AppLayoutContainer.types'

const AppLayoutContainer: FunctionComponent<IAppLayoutContainerProps> = ({
  children,
  isLoading,
  isPrivateRoute,
  shouldRenderOnSSR,
  PageLayout
}) => {
  const {
    user,
    isLoadingUser,
    isLoadingProfile,
    isUserUpdated,
    hasUserSignedOut
  } = useAuthContext()
  const { isAuthScreensVisible, showLoginForm } = useAuthScreensContext()

  const isBlockedOnServerSide = Boolean(isSSR && shouldRenderOnSSR === false)
  const isLoadingRequiredData = Boolean(
    isLoading || isLoadingUser || isLoadingProfile
  )
  const isForbiddenAccess = !user && !isLoadingUser && isPrivateRoute

  // Automatically show/hide the AuthScreens on private routes
  useEffect(() => {
    const shouldShowAuthScreens = Boolean(
      !isAuthScreensVisible &&
        isPrivateRoute &&
        !user &&
        !isLoadingUser &&
        isUserUpdated &&
        !hasUserSignedOut
    )
    if (shouldShowAuthScreens) {
      showLoginForm({
        AuthScreensTrigger: TRIGGERS.PRIVATE_ROUTE,
        canBeClosed: false
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAuthScreensVisible,
    user,
    isLoadingUser,
    isPrivateRoute,
    isUserUpdated,
    hasUserSignedOut
  ])

  if (isBlockedOnServerSide) {
    return null
  }

  if (isLoadingRequiredData || isForbiddenAccess) {
    return <Spinner />
  }

  return (
    <JobsProvider>
      <AppLayout
        notification={<NotificationContainer />}
        header={<HeaderContainer />}
        hideOverflow={Boolean(PageLayout)}
      >
        {PageLayout ? <PageLayout>{children}</PageLayout> : children}
      </AppLayout>
    </JobsProvider>
  )
}

export default AppLayoutContainer
