import React, { FunctionComponent } from 'react'

import { IButtonProps, ButtonTypes } from './Button.types'

import {
  style_component,
  style_outlined,
  style_size_normal,
  style_size_large,
  style_size_small,
  style_size_extra_small,
  style_pill,
  style_block,
  style_block_link,
  style_block_link_inner,
  style_href,
  style_default,
  style_default_outlined,
  style_primary,
  style_primary_outlined,
  style_secondary,
  style_secondary_outlined,
  style_warning,
  style_warning_outlined,
  style_transparent,
  style_transparent_outlined,
  style_link,
  style_whiteBackground,
  style_uppercase
} from './Button.module.css'

export const availableColors = [
  'default',
  'primary',
  'secondary',
  'warning',
  'transparent',
  'link'
]
export const availableSizes = ['normal', 'large', 'small', 'extra_small']
export const availableTypes = ['button', 'submit', 'reset']

const Button: FunctionComponent<IButtonProps> = ({
  onClick,
  children,
  color = 'default',
  disabled = false,
  outlined = false,
  pill = false,
  size = 'normal',
  block = false,
  type = 'button',
  whiteBackground = false,
  uppercase = true,
  className = '',
  testid,
  href,
  target,
  rel,
  ...rest
}) => {
  if (!children) {
    return null
  }

  const colors = {
    default: style_default,
    default_outlined: style_default_outlined,
    primary: style_primary,
    primary_outlined: style_primary_outlined,
    secondary: style_secondary,
    secondary_outlined: style_secondary_outlined,
    warning: style_warning,
    warning_outlined: style_warning_outlined,
    transparent: style_transparent,
    transparent_outlined: style_transparent_outlined,
    link: style_link
  }
  const validColor = availableColors.includes(color)
    ? color
    : availableColors[0]
  const colorClassName =
    outlined && colors[`${validColor}_outlined`]
      ? `${validColor}_outlined`
      : validColor

  const sizeClassName = {
    normal: style_size_normal,
    large: style_size_large,
    small: style_size_small,
    extra_small: style_size_extra_small
  }

  const buttonType = availableTypes.includes(type)
    ? type
    : (availableTypes[0] as ButtonTypes)

  const classNames = [
    style_component,
    availableSizes.includes(size) ? sizeClassName[size] : sizeClassName.normal,
    colors[colorClassName],
    block ? style_block : '',
    block && color === 'link' ? style_block_link : '',
    pill ? style_pill : '',
    href ? style_href : '',
    outlined ? style_outlined : '',
    whiteBackground ? style_whiteBackground : '',
    uppercase ? style_uppercase : '',
    className
  ].join(' ')

  const renderChildren = () =>
    block && color === 'link' ? (
      <span className={style_block_link_inner}>{children}</span>
    ) : (
      children
    )

  if (href) {
    return (
      <a
        href={href}
        className={classNames}
        target={target}
        rel={rel}
        onClick={onClick}
      >
        {renderChildren()}
      </a>
    )
  }

  return (
    <button
      className={classNames}
      data-testid={testid || 'Button'}
      onClick={onClick}
      disabled={disabled}
      type={buttonType}
      {...rest}
    >
      {renderChildren()}
    </button>
  )
}

export default Button
