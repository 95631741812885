import { api } from '@utils/apiHelpers'

import { IFile } from '@type/file'

export const uploadResume = async ({ profileId, file, onProgress }) => {
  const formData = new FormData()

  const dataObj = {
    files: file,
    refId: profileId,
    ref: 'profile',
    field: 'resume',
    path: 'resume'
  }

  Object.keys(dataObj).forEach(key => formData.append(key, dataObj[key]))

  const config = {
    headers: { 'content-type': 'multipart/form-data' },
    onUploadProgress: event => {
      const percent = Math.floor((event.loaded / event.total) * 100)
      // Don't set progress to 100% before API request is finish
      if (percent < 100) {
        onProgress && onProgress(percent)
      }
    }
  }

  try {
    const response = await api.post<FormData, IFile[]>(
      '/upload',
      formData,
      config
    )
    // Set upload to 100% only after the API request is finish
    onProgress && onProgress(100)
    return response
  } catch (error) {
    console.error('Error uploading resume', error)
  }
}

export const getResume = async resume => {
  try {
    const response = await api.get(`/upload/files/${resume.id}`)
    return response
  } catch (error) {
    console.error('Error fetching resume', error)
  }
}

export const uploadProfileImage = async ({ profileId, file, onProgress }) => {
  const formData = new FormData()

  const dataObj = {
    files: file,
    refId: profileId,
    ref: 'profile',
    field: 'image',
    path: 'profile'
  }

  Object.keys(dataObj).forEach(key => formData.append(key, dataObj[key]))

  const config = {
    headers: { 'content-type': 'multipart/form-data' },
    onUploadProgress: event => {
      const percent = Math.floor((event.loaded / event.total) * 100)
      onProgress && onProgress(percent)
    }
  }

  try {
    return await api.post<FormData, IFile[]>('/upload', formData, config)
  } catch (error) {
    console.error('Error uploading resume', error)
  }
}
