import React, { FunctionComponent } from 'react'
import ReactAvatar from 'react-avatar'

import { IAvatarProps } from './Avatar.type'

import { style_component } from './Avatar.module.css'
import { alpha_Neutral_8_80, bleachedSilk } from '@theme/colors.module.css'

const Avatar: FunctionComponent<IAvatarProps> = ({
  alt,
  src,
  size,
  color = bleachedSilk,
  textColor = alpha_Neutral_8_80,
  className,
  textSizeRatio,
  maxInitials = 3
}) => (
  <div data-testid="Avatar" className={style_component}>
    <ReactAvatar
      className={className}
      name={alt}
      maxInitials={maxInitials}
      alt={alt}
      src={src}
      size={size}
      textSizeRatio={textSizeRatio}
      color={color}
      fgColor={textColor}
      round
    />
  </div>
)

export default Avatar
