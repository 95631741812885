import { size } from '@utils/helpers'

import { IProfile } from '@type/profile'

export const getProfileUpdateProgressDetails = (profile: IProfile | null) => {
  const progressDetails = [
    {
      key: 'fullName',
      label: 'Add your full name',
      checked: Boolean(profile?.fullName)
    },
    {
      key: 'image',
      label: 'Upload a profile photo',
      checked: Boolean(profile?.image)
    },
    {
      key: 'country',
      label: 'Update your location',
      checked: Boolean(profile?.country)
    },
    {
      key: 'socials',
      label: 'Add social links',
      checked: [
        profile?.githubAccount,
        profile?.linkedinAccount,
        profile?.stackoverflowAccount
      ].some(value => size(value) > 0)
    },
    {
      key: 'about',
      label: 'Write an introduction',
      checked: Boolean(profile?.about)
    },
    {
      key: 'resume',
      label: 'Upload your Resume/CV',
      checked: Boolean(profile?.resume)
    },
    {
      key: 'preferredJobTypes',
      label: 'Add your preferred job types',
      checked: size(profile?.preferredJobTypes) > 0
    },
    {
      key: 'preferredSkills',
      label: 'Add your preferred skills',
      checked: size(profile?.preferredSkills) > 0
    },
    {
      key: 'rateRange',
      label: 'Add your expected rate',
      checked: Boolean(profile?.rateRange)
    }
  ]
  return progressDetails
}

export const getProfileUpdateProgress = (profile: IProfile | null) => {
  if (!profile) {
    return 0
  }

  const score = {
    fullName: 10,
    country: 10,
    about: 10,
    resume: 20,
    preferredJobTypes: 10,
    preferredSkills: 10,
    rateRange: 10,
    image: 10,
    socials: 10
  }

  const scoreKeys = Object.keys(score)
  const partialScore = scoreKeys.reduce((acc, cur) => {
    const currentProfile = profile[cur]
    const isFulfilled = isNaN(currentProfile)
      ? Boolean(size(currentProfile))
      : currentProfile > 0
    return isFulfilled ? acc + score[cur] : acc
  }, 0)

  const finalScore = [
    profile.githubAccount,
    profile.linkedinAccount,
    profile.stackoverflowAccount
  ].some(value => size(value) > 0)
    ? partialScore + score.socials
    : partialScore

  return finalScore
}
