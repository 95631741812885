import React, { FunctionComponent } from 'react'

import { Button } from '@components/redesign/Button'

import { INotFindingTheRightJobProps } from './NotFindingTheRightJob.types'

import {
  style_card,
  style_cardTitle,
  style_cardSubTitle
} from './NotFindingTheRightJob.module.css'

const NotFindingTheRightJob: FunctionComponent<INotFindingTheRightJobProps> = ({
  buttonContainer: ButtonContainer
}) => (
  <div className={style_card}>
    <span className={style_cardTitle}>NOT FINDING THE RIGHT JOB?</span>
    <span className={style_cardSubTitle}>
      Make sure to upload your latest Resume/CV and we&apos;ll send you roles
      that match your skills.
    </span>
    {ButtonContainer ? (
      <ButtonContainer>
        <Button href="/profile/" color="transparent" size="extra_small">
          UPLOAD CV NOW
        </Button>
      </ButtonContainer>
    ) : (
      <Button href="/profile/" color="transparent" size="extra_small">
        UPLOAD CV NOW
      </Button>
    )}
  </div>
)

export default NotFindingTheRightJob
