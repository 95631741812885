import { useAuthContext } from '@hooks/useAuthContext'
import { isSSR } from '@utils/helpers'

import { IDataLayerObject } from './useDataLayer.types'
import { IUser } from '@type/user'

const useDataLayer = () => {
  const { user } = useAuthContext()

  if (isSSR) {
    return null
  }

  const pushToDataLayer = (obj: IDataLayerObject) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(obj)
  }

  const setUserData = (userId: number) => {
    pushToDataLayer({
      userData: {
        userId
      }
    })
  }

  if (user) {
    const { id: userId } = user as IUser
    setUserData(userId)
  }
}

export default useDataLayer
