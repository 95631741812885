// extracted by mini-css-extract-plugin
export var Interface_SemiBold_S = "typography-module--Interface_SemiBold_S--af7a9";
export var alpha_hornetSting_09 = "rgba(255, 0, 53, 0.9)";
export var colors = "\"@theme/colors.module.css\"";
export var glimpseOfPink = "#fff3f5";
export var hornetSting = "#FF0035";
export var style_block = "ErrorMessage-module--style_block--9bd2f";
export var style_component = "ErrorMessage-module--style_component--87366 typography-module--typeface_base--8d193 typography-module--Interface_SemiBold_S--af7a9";
export var style_icon = "ErrorMessage-module--style_icon--134c1";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "\"@theme/typography.module.css\"";