import {
  style_size_normal,
  style_size_large,
  style_size_small,
  style_size_extra_small,
  style_default,
  style_default_outlined,
  style_primary,
  style_primary_outlined,
  style_secondary,
  style_secondary_outlined,
  style_warning,
  style_warning_outlined,
  style_transparent,
  style_transparent_outlined,
  style_link
} from './ButtonNew.module.css'

export const availableColors = [
  'default',
  'primary',
  'secondary',
  'warning',
  'transparent',
  'link'
]

export const availableSizes = ['normal', 'large', 'small', 'extra_small']

export const availableTypes = ['button', 'submit', 'reset']

export const colorsClassNames = {
  default: style_default,
  default_outlined: style_default_outlined,
  primary: style_primary,
  primary_outlined: style_primary_outlined,
  secondary: style_secondary,
  secondary_outlined: style_secondary_outlined,
  warning: style_warning,
  warning_outlined: style_warning_outlined,
  transparent: style_transparent,
  transparent_outlined: style_transparent_outlined,
  link: style_link
}

export const sizeClassNames = {
  normal: style_size_normal,
  large: style_size_large,
  small: style_size_small,
  extra_small: style_size_extra_small
}
