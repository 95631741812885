import React from 'react'

import {
  style_card,
  style_cardTitle,
  style_cardSubTitle
} from './DidYouKnow.module.css'

const DidYouKnow = () => (
  <div className={style_card}>
    <span className={style_cardTitle}>DID YOU KNOW?</span>
    <span className={style_cardSubTitle}>
      As an X-Teamer, you can work with your favorite brands from anywhere you
      want, and whenever you want.
    </span>
  </div>
)

export default DidYouKnow
