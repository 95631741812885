import React from 'react'

const Success = ({ strokeColor = 'white', ...rest }) => (
  <svg
    width="16"
    height="13"
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M15 1L5.375 11L1 6.45455" stroke={strokeColor} strokeWidth="2" />
  </svg>
)

export default Success
