import React, { useState, useCallback, Suspense } from 'react'
import { useDropzone } from 'react-dropzone'
import TimeAgo from 'react-timeago'

import { LockedFeatures } from '@components/redesign/LockedFeatures'
import { UpdateCV } from '@components/redesign/UpdateCV'
import { UploadCV } from '@components/redesign/UploadCV'
import { UploadLinkedinCV } from '@components/redesign/UploadLinkedinCV'

import { useAuthContext } from '@hooks/useAuthContext'
import { useFeatureFlags } from '@hooks/useFeatureFlags'
import { useMediaQuery } from '@hooks/useMediaQuery'
import { useModal } from '@hooks/useModal'
import {
  deleteProfileResume,
  parseProfileResumeWithRetries
} from '@services/profile'
import { timeFormatter } from '@utils/helpers'
import { uploadResume } from '@utils/uploadHelpers'
import { FILE_TOO_LARGE_CODE } from '@config/constants'

import { breakpointSM } from '@theme/breakpoints.module.css'

const Modal = React.lazy(() => import('@components/redesign/Modal/Modal'))

const SubmitCV = ({
  handleConfirmation,
  confirmationText,
  hideLockedFeatures
}) => {
  const isDesktop = useMediaQuery(`(min-width: ${breakpointSM})`)
  const { features } = useFeatureFlags()
  const isLinkedCvFeatureEnabled = Boolean(features?.OPS_LINKEDIN_CV)

  const { profile, updateState: updateAuthState } = useAuthContext()
  const { id: profileId, resume } = profile || {}
  const [showLockedFeatures, setShowLockedFeatures] = useState(
    !resume && !hideLockedFeatures
  )
  const [hasExceededSizeLimit, setHasExceededSizeLimit] = useState(false)
  const [uploadPercent, setUploadPercent] = useState(0)
  const [hasUploadError, setHasUploadError] = useState(false)
  const [resumeFile, setResumeFile] = useState(
    Boolean(resume) && {
      uid: resume.id,
      name: resume.name,
      url: resume.url,
      updated_at: resume.updated_at
    }
  )

  const {
    isVisible: isLinkedinCVModalVisible,
    toggleModal: toggleLinkedinCVModal
  } = useModal()

  const {
    isVisible: isUploadCVModalVisible,
    toggleModal: toggleUploadCVModal
  } = useModal()

  const onDrop = useCallback(
    acceptedFiles => {
      acceptedFiles.forEach(async file => {
        try {
          setUploadPercent(0)
          setHasUploadError(false)
          setHasExceededSizeLimit(false)

          if (resume) {
            await deleteProfileResume(profileId)
          }

          const { data } = await uploadResume({
            profileId,
            file,
            onProgress: setUploadPercent
          })
          const [newResume] = data

          if (newResume) {
            updateAuthState({
              profile: {
                ...profile,
                resume: newResume
              }
            })
            await parseProfileResumeWithRetries(profileId).catch(() => {
              setHasUploadError(true)
              setUploadPercent(0)
            })

            if (isLinkedinCVModalVisible) {
              toggleLinkedinCVModal()
            }

            if (isUploadCVModalVisible) {
              toggleUploadCVModal()
            }

            setResumeFile({
              uid: newResume.id,
              name: newResume.name,
              url: newResume.url,
              updated_at: newResume.updated_at
            })
          } else {
            updateAuthState({
              profile: {
                ...profile,
                resume: undefined
              }
            })
          }
          setUploadPercent(0)
        } catch (err) {
          setUploadPercent(0)
          setHasUploadError(true)
          updateAuthState({
            profile: {
              ...profile,
              resume: undefined
            }
          })
        }
      })
    },
    [
      resume,
      profileId,
      updateAuthState,
      profile,
      isLinkedinCVModalVisible,
      isUploadCVModalVisible,
      toggleLinkedinCVModal,
      toggleUploadCVModal
    ]
  )

  const onDropRejected = useCallback(fileRejections => {
    if (fileRejections.length && fileRejections[0].errors) {
      const errorCode = fileRejections[0].errors[0].code

      if (errorCode === FILE_TOO_LARGE_CODE) {
        setHasExceededSizeLimit(true)
        setHasUploadError(true)
      }
    }
  }, [])

  const {
    getRootProps,
    getInputProps,
    open: handleBrowseFile,
    isDragReject,
    isDragActive
  } = useDropzone({
    onDrop,
    onDropRejected,
    noClick: true,
    accept:
      'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.oasis.opendocument.text,application/rdf+xml,text/plain',
    maxFiles: 1,
    maxSize: 4000000 // 4Mb
  })

  const somethingWrong = isDragReject || hasExceededSizeLimit

  const renderUploadCV = (showHeading = false) => (
    <UploadCV
      getRootProps={getRootProps()}
      getInputProps={getInputProps()}
      handleBrowseFile={handleBrowseFile}
      somethingWrong={somethingWrong}
      uploadPercent={uploadPercent}
      handleLinkedInClick={toggleLinkedinCVModal}
      showLinkedInButton={isLinkedCvFeatureEnabled && isDesktop}
      showHeading={showHeading}
      isDragActive={isDragActive}
    />
  )

  const renderUploadCVModal = () => (
    <>
      {renderLinkedInCVModal()}
      {isLinkedCvFeatureEnabled && isUploadCVModalVisible && (
        <Suspense fallback={<div />}>
          <Modal onClose={toggleUploadCVModal}>{renderUploadCV(true)}</Modal>
        </Suspense>
      )}
    </>
  )

  const handleCloseLinkedinCVModal = useCallback(() => {
    if (isLinkedinCVModalVisible) {
      toggleLinkedinCVModal()
    }
    if (isUploadCVModalVisible) {
      toggleUploadCVModal()
    }
  }, [
    toggleLinkedinCVModal,
    toggleUploadCVModal,
    isLinkedinCVModalVisible,
    isUploadCVModalVisible
  ])

  const renderLinkedInCVModal = () =>
    isLinkedCvFeatureEnabled &&
    isLinkedinCVModalVisible && (
      <Suspense fallback={<div />}>
        <Modal onClose={handleCloseLinkedinCVModal}>
          <UploadLinkedinCV
            getRootProps={getRootProps()}
            getInputProps={getInputProps()}
            handleBrowseFile={handleBrowseFile}
            handleGoBackClick={handleCloseLinkedinCVModal}
            somethingWrong={somethingWrong}
            uploadPercent={uploadPercent}
            isGoBackArrowVisible
          />
        </Modal>
      </Suspense>
    )

  const handleUpdateCVBrowseFile = () =>
    isDesktop && isLinkedCvFeatureEnabled
      ? toggleUploadCVModal()
      : handleBrowseFile()

  if (showLockedFeatures) {
    return (
      <LockedFeatures handleContinue={() => setShowLockedFeatures(false)} />
    )
  }

  if (resumeFile?.uid) {
    return (
      <>
        {renderUploadCVModal()}
        <UpdateCV
          updatedAt={
            <TimeAgo
              date={new Date(resumeFile.updated_at)}
              formatter={timeFormatter}
            />
          }
          fileName={resumeFile.name}
          getRootProps={getRootProps()}
          getInputProps={getInputProps()}
          handleBrowseFile={handleUpdateCVBrowseFile}
          handleConfirmation={handleConfirmation}
          confirmationText={confirmationText}
          somethingWrong={somethingWrong}
          hasUploadError={hasUploadError}
          uploadPercent={uploadPercent}
        />
      </>
    )
  }

  return (
    <>
      {renderUploadCVModal()}
      {renderLinkedInCVModal()}
      {!isUploadCVModalVisible && renderUploadCV()}
    </>
  )
}

export default SubmitCV
