// extracted by mini-css-extract-plugin
export var Body_Medium_XS = "typography-module--Body_Medium_XS--cadd2";
export var alpha_white_10 = "rgba(255, 255, 255, 0.1)";
export var alpha_white_20 = "rgba(255, 255, 255, 0.2)";
export var alpha_white_40 = "rgba(255, 255, 255, 0.4)";
export var alpha_white_50 = "rgba(255, 255, 255, 0.5)";
export var colors = "\"@theme/colors.module.css\"";
export var fs11_fw600 = "typography-module--fs11_fw600--fce4e";
export var fs18_fw700 = "typography-module--fs18_fw700--08853";
export var gradient_armorWash_carbonFiber = "linear-gradient(222.76deg, #040404 0%, #2D2D2D 100%)";
export var gradient_venetianNights_purpleIllusionist = "linear-gradient(269.23deg, #7A4EF7 -2.63%, #A180FF 89.7%)";
export var style_card = "NotFindingTheRightJob-module--style_card--2a114 typography-module--typeface_base--8d193";
export var style_cardSubTitle = "NotFindingTheRightJob-module--style_cardSubTitle--418d9 typography-module--Body_Medium_XS--cadd2";
export var style_cardTitle = "NotFindingTheRightJob-module--style_cardTitle--98ec6 typography-module--fs18_fw700--08853";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "\"@theme/typography.module.css\"";
export var white = "#FFFFFF";