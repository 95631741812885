// extracted by mini-css-extract-plugin
export var Interface_SemiBold_M = "typography-module--Interface_SemiBold_M--61495";
export var Interface_SemiBold_S = "typography-module--Interface_SemiBold_S--af7a9";
export var Neutral_8 = "#0C0C0C";
export var blueDiamond = "#0A66C2";
export var boneChilling = "#E1F4F2";
export var colors = "\"@theme/colors.module.css\"";
export var fs13_fw600 = "typography-module--fs13_fw600--ee3e2";
export var fs18_fw700 = "typography-module--fs18_fw700--08853";
export var squant = "#666666";
export var style_arrowLeft = "UploadLinkedinCV-module--style_arrowLeft--cc7af";
export var style_component = "UploadLinkedinCV-module--style_component--46a9a typography-module--typeface_base--8d193";
export var style_fileInfo = "UploadLinkedinCV-module--style_fileInfo--b7781";
export var style_fileInfoDefault = "UploadLinkedinCV-module--style_fileInfoDefault--c6061";
export var style_heading = "UploadLinkedinCV-module--style_heading--ee443 typography-module--fs18_fw700--08853";
export var style_icons = "UploadLinkedinCV-module--style_icons--793a1";
export var style_infoLine = "UploadLinkedinCV-module--style_infoLine--06cb8 typography-module--fs13_fw600--ee3e2";
export var style_instructions = "UploadLinkedinCV-module--style_instructions--2f28f typography-module--Interface_SemiBold_M--61495";
export var style_instructionsImage = "UploadLinkedinCV-module--style_instructionsImage--23beb";
export var style_subtitle = "UploadLinkedinCV-module--style_subtitle--b5e20 typography-module--fs13_fw600--ee3e2";
export var style_title = "UploadLinkedinCV-module--style_title--7e4c8 typography-module--fs18_fw700--08853";
export var turquish = "#01A390";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "\"@theme/typography.module.css\"";
export var white = "#FFFFFF";