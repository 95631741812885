import React from 'react'

import { Neutral_8 } from '@theme/colors.module.css'

const ArrowRight = ({
  height = '14px',
  width = '14px',
  fill = Neutral_8,
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 6 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M1 0.999878L4 3.99988L1 6.99988" stroke={fill} strokeWidth="1.5" />
  </svg>
)

export default ArrowRight
