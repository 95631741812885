import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse, AxiosError } from 'axios'
import { Link } from 'gatsby'

import { useUTMParamsContext } from '@hooks/useUTMParamsContext'
import {
  checkAuth,
  getAuthToken,
  initAuthHeader,
  signIn,
  signOut,
  signUp,
  IAuthResponseData,
  resendConnectAccountEmail
} from '@services/auth'
import { fetchMe, updateMe } from '@services/profile'
import { IApiError, IHandleApiErrorRes, handleAPIError } from '@utils/apiHelpers'
import { isNetworkError } from '@utils/helpers'
import { CONVERSION_ID } from '@config/constants'

import AuthContext, { initialState } from './AuthContext'

import {
  IUseAuthContextReturn,
  ISignIn,
  IUpdateMe,
  ISignOut,
  IAuthenticate,
  ISignUp,
  IClearErrors,
  IUpdateState
} from './useAuthContext.types'
import { IProfile } from '@type/profile'
import { IUser } from '@type/user'

let reFetchTokenCountdownInterval

const AlreadyConnectedErrorMessage = {
  name: (
    <span>
      You are already authenticated. &nbsp;
      <Link to="/jobs">Click here</Link> to be redirected to the jobs board.
    </span>
  )
}

function useAuth () {
  const [state, setState] = useState<IUseAuthContextReturn>(initialState)
  const [isAuthInitiated, setIsAuthInitiated] = useState<boolean>(false)
  const { clearUtmParams } = useUTMParamsContext()
  const update = useCallback(
    values => setState(state => ({ ...state, ...values })),
    [setState]
  )

  const {
    data: authRes,
    error: authError,
    isFetching: isAuthFetching
  } = useQuery<AxiosResponse<IUser>, AxiosError>({
    queryKey: ['auth'],
    queryFn: () => checkAuth() as Promise<AxiosResponse<IUser>>,
    enabled: Boolean(isAuthInitiated)
  })

  const { data: profileRes, isFetching: isMeFetching } = useQuery<
    AxiosResponse<IProfile>,
    AxiosError
  >({
    queryKey: ['me'],
    queryFn: () => fetchMe() as Promise<AxiosResponse<IProfile>>,
    enabled: Boolean(state.user)
  })

  const reFetchTokenCountdown = useCallback(() => {
    update({
      reFetchTokenCountdownValue:
        Number(state.reFetchTokenCountdownValue) > 0
          ? Number(state.reFetchTokenCountdownValue) - 1
          : 10
    })
  }, [update, state.reFetchTokenCountdownValue])

  const reFetchToken = useCallback(() => {
    reFetchTokenCountdownInterval = setInterval(reFetchTokenCountdown, 1000)
  }, [reFetchTokenCountdown])

  const initAuth = useCallback(() => {
    clearInterval(reFetchTokenCountdownInterval)
    const savedToken = getAuthToken()
    update({
      isUserUpdated: false
    })

    if (!savedToken) {
      update({
        user: null,
        isUserUpdated: true
      })
      return
    }

    initAuthHeader(savedToken)
    setIsAuthInitiated(true)

    if (!authRes) {
      return
    }

    const { data, status } = authRes

    if (status !== 200) {
      signOut()
      return
    }

    const { blocked, confirmed, id, role, email, token, provider } = data

    update({
      user: {
        blocked,
        confirmed,
        id,
        role,
        email,
        token,
        provider
      },
      isUserUpdated: true
    })

    if (!authError) {
      return
    }

    const { response } = authError as AxiosError

    update({
      user: null,
      isUserUpdated: true
    })

    if (!response) {
      isNetworkError(authError) && reFetchToken()
      return
    }

    if (response.status === 401) {
      signOut()
    }

    update({
      error: authError
    })
  }, [update, authRes, authError, reFetchToken])

  useEffect(() => {
    initAuth()
  }, [initAuth])

  useEffect(() => {
    update({
      isProfileUpdated: false
    })

    if (!profileRes || !state.user) {
      return
    }

    const { data, status } = profileRes
    const hasNoLinkedInAccount = status === 404

    update({
      requiresProfileLink:
        hasNoLinkedInAccount && state.user?.provider === 'linkedin'
    })

    if (status === 200) {
      update({
        profile: data,
        isProfileUpdated: true
      })
    }
  }, [update, profileRes, state.user])

  useEffect(() => {
    update({
      loading: isMeFetching || isAuthFetching,
      isLoadingUser: isAuthFetching,
      isLoadingProfile: isMeFetching
    })
  }, [update, isMeFetching, isAuthFetching])

  const clearErrors: IClearErrors = useCallback(() => {
    update({
      error: null
    })
  }, [update])

  const handleSignOut: ISignOut = useCallback(
    (wasUser = false) => {
      update({
        user: null,
        profile: null,
        hasUserSignedOut: wasUser
      })
      signOut()
    },
    [update]
  )

  const handleAuthenticate: IAuthenticate = useCallback(async () => {
    await initAuth()
  }, [initAuth])

  const handleSignUp: ISignUp = useCallback(
    async (
      { password, email, fullName, jobAlertsNotification, utmParams },
      postSignupAction
    ) => {
      if (getAuthToken()) {
        await initAuth()

        update({
          error: AlreadyConnectedErrorMessage,
          hasUserSignedOut: false
        })
        return
      }

      update({
        error: null,
        hasUserSignedOut: false
      })

      const res:
        | IApiError
        | IHandleApiErrorRes
        | AxiosResponse<IAuthResponseData> = await signUp({
          password,
          email,
          fullName,
          jobAlertsNotification
        })

      if ('data' in res && res.status === 200) {
        const { blocked, confirmed, id, role, email } = res.data.user

        if (utmParams) {
          await updateMe(utmParams)
        }

        if (window?.lintrk) {
          window.lintrk('track', { conversion_id: CONVERSION_ID })
        }

        update({
          user: {
            blocked,
            confirmed,
            id,
            role,
            email
          }
        })

        typeof postSignupAction === 'function' && postSignupAction()
        clearUtmParams()
      } else if ('error' in res) {
        update({ error: res.error.name })
      }
    },
    [clearUtmParams, update, initAuth]
  )

  const handleUpdateMe: IUpdateMe = useCallback(
    async data => {
      update({
        updating: true,
        updated: false
      })

      if (!data) {
        data = {}
      }

      data.updatedAtByCandidate = new Date()

      const res = await updateMe(data)

      const successRes = res as AxiosResponse<IProfile>
      if (successRes.status === 200) {
        update({
          updating: false,
          updated: true,
          profile: successRes.data
        })
      } else {
        update({
          updating: false,
          updated: false
        })
      }
    },
    [update]
  )

  const handleSignIn: ISignIn = useCallback(
    async (
      { identifier, password, wasActivated, utmParams },
      postSignupAction
    ) => {
      update({
        error: null,
        hasUserSignedOut: false,
        wasActivated
      })

      const res = await signIn({
        identifier,
        password,
        utmParams
      })

      const successRes = res as AxiosResponse<IAuthResponseData>

      if (successRes.status === 200) {
        const { blocked, confirmed, id, role, email, token } =
          successRes.data.user

        update({
          user: {
            blocked,
            confirmed,
            id,
            role,
            email,
            token
          }
        })

        typeof postSignupAction === 'function' && postSignupAction()
        clearUtmParams()
      } else {
        // We have to check for 'res.message' in case we throw
        // custom error objects from the related api handling files.
        const error =
          (res as IHandleApiErrorRes).error || (res as AxiosError).message

        update({
          error:
            typeof error === 'string'
              ? {
                name: error || (
                  <span>
                      Network error. Please try again later, or contact{' '}
                    <a href="mailto:support@x-team.com">support</a>.
                  </span>
                )
              }
              : error
        })
      }
    },
    [update, clearUtmParams]
  )

  const handleUpdateState: IUpdateState = useCallback(
    params => {
      update(params)
    },
    [update]
  )

  const resendEmailHandler = useCallback(async () => {
    try {
      const res = await resendConnectAccountEmail() as AxiosResponse
      if (res.data.success) {
        update({
          ...state,
          requiresProfileLink: true,
          connectAccountsEmailSent: true
        })
      }
    } catch (error) {
      await handleAPIError(error as AxiosError)
    }
  }, [])

  const memoizedProviderValue = useMemo(
    () => ({
      ...state,
      clearErrors,
      signUp: handleSignUp,
      updateMe: handleUpdateMe,
      updateState: handleUpdateState,
      signIn: handleSignIn,
      signOut: handleSignOut,
      authenticate: handleAuthenticate,
      resendEmailHandler
    }),
    [
      state,
      clearErrors,
      handleSignUp,
      handleSignIn,
      handleSignOut,
      handleAuthenticate,
      handleUpdateState,
      handleUpdateMe,
      resendEmailHandler
    ]
  )

  return memoizedProviderValue
}

const AuthProvider = ({ children }) => (
  <AuthContext.Provider value={useAuth()}>{children}</AuthContext.Provider>
)

const AuthProviderMocked = ({ children, mock }) => (
  <AuthContext.Provider value={mock}>{children}</AuthContext.Provider>
)

export { AuthProvider, AuthProviderMocked }
