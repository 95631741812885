import React from 'react'

import { squant } from '@theme/colors.module.css'

const Like = props => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.79434 11.9999H2.11774C1.82129 11.9999 1.53699 11.884 1.32738 11.6777C1.11776 11.4715 1 11.1917 1 10.8999V7.04996C1 6.75823 1.11776 6.47844 1.32738 6.27215C1.53699 6.06586 1.82129 5.94997 2.11774 5.94997H3.79434M7.70641 4.84998V2.64999C7.70641 2.21239 7.52977 1.7927 7.21535 1.48327C6.90092 1.17384 6.47447 1 6.02981 1L3.79434 5.94997V11.9999H10.0984C10.3679 12.0029 10.6295 11.91 10.8349 11.7381C11.0403 11.5663 11.1757 11.3272 11.2161 11.0649L11.9873 6.11497C12.0117 5.95731 12.0009 5.79634 11.9557 5.64321C11.9105 5.49007 11.832 5.34843 11.7257 5.22811C11.6194 5.10778 11.4878 5.01164 11.3399 4.94634C11.1921 4.88105 11.0316 4.84817 10.8696 4.84998H7.70641Z"
      stroke={squant}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Like
