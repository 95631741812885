import React, { FunctionComponent, useState } from 'react'

import { SVGS, Svg } from '@components/redesign/Svgs'

import authPoster from '@assets/auth-poster.png'

import { IAuthModalProps } from './AuthModal.types'

import {
  style_component,
  style_brand,
  style_brandMobile,
  style_close,
  style_left,
  style_heading,
  style_subheading,
  style_images,
  style_right,
  style_header,
  style_tabs,
  style_tab,
  style_active,
  style_rightInner
} from './AuthModal.module.css'
import { Neutral_1, Neutral_7, Neutral_8 } from '@theme/colors.module.css'

const AuthModal: FunctionComponent<IAuthModalProps> = ({
  onBrandClick,
  onClose,
  contents,
  activeTab
}) => {
  const [currentActiveTab, setActiveTab] = useState(activeTab)

  const isActiveContent = content => content.title === currentActiveTab
  const activeContent = contents?.find(isActiveContent)
  if (!activeContent) {
    return null
  }

  const handleTabClick = (tab: string) => () => {
    setActiveTab(tab)
  }

  const handleOnBrandClick = () => {
    onClose && onBrandClick?.()
  }

  return (
    <div id="AuthModal" data-testid="AuthModal" className={style_component}>
      <div className={style_left}>
        <Svg
          className={onClose ? style_brand : ''}
          name={SVGS.XTeamLogo}
          fill={Neutral_1}
          onClick={handleOnBrandClick}
        />
        <h1 className={style_heading}>THE NEXT STEP FOR YOUR CAREER.</h1>
        <p className={style_subheading}>
          Join the most energizing community for developers and get long-term
          remote jobs with the world&apos;s biggest brands.
        </p>
        <div className={style_images}>
          <img src={authPoster} alt="THE NEXT STEP FOR YOUR CAREER" />
        </div>
      </div>
      <div className={style_right}>
        <div className={style_header}>
          <Svg
            name={SVGS.XTeamLogo}
            fill={Neutral_8}
            className={style_brandMobile}
            onClick={handleOnBrandClick}
          />
          {onClose && (
            <div className={style_close} onClick={onClose}>
              <Svg name={SVGS.Close} strokeColor={Neutral_7} />
            </div>
          )}
        </div>
        <div className={style_rightInner}>
          <div className={style_tabs}>
            {contents?.map(content => (
              <div
                key={content.title}
                onClick={handleTabClick(content.title)}
                className={[
                  style_tab,
                  isActiveContent(content) ? style_active : ''
                ].join(' ')}
              >
                {content.title}
              </div>
            ))}
          </div>
          {activeContent?.render}
        </div>
      </div>
    </div>
  )
}

export default AuthModal
