import { useContext } from 'react'

import JobApplicationsContext from './JobApplicationsContext'

import { IUseJobApplicationsContextReturn } from './useJobApplicationsContext.types'

const useJobApplicationsContext = () =>
  useContext<IUseJobApplicationsContextReturn | null>(
    JobApplicationsContext
  ) as IUseJobApplicationsContextReturn

export default useJobApplicationsContext
