import React, { Suspense, useState } from 'react'
import { navigate } from 'gatsby'

import { AuthButton } from '@containers/AuthButton'
import { MobileHeaderMenu } from '@components/app/Header/Mobile/MobileHeaderMenu'
import { MobileUnauthenticatedHeaderMenu } from '@components/app/Header/Mobile/MobileUnauthenticatedHeaderMenu'
import { Button } from '@components/redesign/Button'
import { MainMenu } from '@components/redesign/MainMenu'
import { ProfileAvatar } from '@components/redesign/ProfileAvatar'
import { Svg, SVGS } from '@components/redesign/Svgs'

import { useAuthContext } from '@hooks/useAuthContext'
import { useAuthScreensContext } from '@hooks/useAuthScreensContext'
import { useFeatureFlags } from '@hooks/useFeatureFlags'
import { useModal } from '@hooks/useModal'
import { getProfileUpdateProgress } from '@utils/profileHelpers'

import {
  style_menuButton,
  style_logInButton,
  style_closeButton,
  popperOpenAnimation,
  popperCloseAnimation
} from './MobileHeaderMenuButton.module.css'
import { white } from '@theme/colors.module.css'

const Popper = React.lazy(() => import('@components/generic/Popper/Popper'))
const Modal = React.lazy(() => import('@components/redesign/Modal/Modal'))

const MobileHeaderMenuButton = ({ showAuthScreens }) => {
  const { user, profile, signOut } = useAuthContext()
  const { showLoginForm } = useAuthScreensContext()
  const [isMenuOpen, setMenuOpen] = useState(false)
  const { features } = useFeatureFlags()
  const {
    isVisible: isMainMenuVisible,
    toggleModal: toggleMainMenu
  } = useModal()

  const isAuthorized = Boolean(user)

  const isMenuUpdateFeatureEnabled = Boolean(features?.RELEASE_MENU_UPDATE)

  if (isMenuUpdateFeatureEnabled) {
    if (!profile) {
      return (
        <div className={style_logInButton}>
          <AuthButton showAuthScreens={showLoginForm}>
            <Button
              data-value="login-button-header"
              to="/login/"
              color="default"
              uppercase={false}
              pill
            >
              Log in
            </Button>
          </AuthButton>
        </div>
      )
    }

    const hasProfileNotification = profile && getProfileUpdateProgress(profile) < 100

    const handleSignOut = e => {
      e?.preventDefault()
      navigate('/')
      signOut(true)
    }

    const handleOpenMainMenu = () => !isMainMenuVisible && toggleMainMenu()
    const handleCloseMainMenu = () => isMainMenuVisible && toggleMainMenu()

    const renderMainMenuModal = () =>
      isMainMenuVisible && (
        <Suspense fallback={<div />}>
          <Modal>
            <button
              className={style_closeButton}
              onClick={handleCloseMainMenu}
            >
              <Svg
                name={SVGS.Close}
                strokeColor={white}
              />
            </button>
            <MainMenu
              onClick={handleCloseMainMenu}
              navigate={navigate}
              signOut={handleSignOut}
              profileEmail={profile?.email}
              hasProfileNotification={hasProfileNotification}
              showAuthScreens={showAuthScreens}
            />
          </Modal>
        </Suspense>
      )

    const imageSrc = profile?.image?.url || profile?.socialProfileImageUrl

    return (
      <>
        {renderMainMenuModal()}
        <ProfileAvatar
          fullname={profile?.fullName}
          imageSrc={imageSrc}
          hasProfileNotification={hasProfileNotification}
          onClick={handleOpenMainMenu}
        />
      </>
    )
  }

  return (
    <Suspense fallback={<div />}>
      <Popper
        blockScroll={true}
        visibleBackdrop={true}
        onOpen={() => setMenuOpen(true)}
        onClose={() => setMenuOpen(false)}
        classes={{
          openAnimation: popperOpenAnimation,
          closeAnimation: popperCloseAnimation
        }}
        content={
          isAuthorized ? (
            <MobileHeaderMenu />
          ) : (
            <MobileUnauthenticatedHeaderMenu
              showAuthScreens={showAuthScreens}
            />
          )
        }
      >
        <div className={style_menuButton}>
          {isMenuOpen ? (
            <button>
              <Svg name={SVGS.Close} />
            </button>
          ) : (
            <button>
              <Svg name={SVGS.Menu} />
            </button>
          )}
        </div>
      </Popper>
    </Suspense>
  )
}

export default MobileHeaderMenuButton
