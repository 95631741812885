import React, { FunctionComponent } from 'react'
import { navigate } from 'gatsby'

import { LinkedInButton } from '@components/redesign/LinkedInButton'

import { baseUrl } from '@utils/apiHelpers'
import { STORAGE_KEYS } from '@config/constants'

import { ILinkedInButtonContainerProps } from './LinkedInButtonContainer.types'

const { LINKEDIN_SIGNIN_REDIRECT_URL } = STORAGE_KEYS

const LinkedInButtonContainer: FunctionComponent<ILinkedInButtonContainerProps> = ({
  label = ''
}) => {
  const storeRedirectUrl = redirectUrl => {
    Boolean(redirectUrl) &&
      localStorage.setItem(LINKEDIN_SIGNIN_REDIRECT_URL, String(redirectUrl))
  }

  const handleLinkedInLogin = () => {
    const redirectUrl = window.location.pathname
    storeRedirectUrl(redirectUrl)
    navigate(`${baseUrl}/connect/linkedin`)
  }

  // "Login with LinkedIn"
  return <LinkedInButton label={label} onClick={handleLinkedInLogin} />
}

export default LinkedInButtonContainer
