import React from 'react'

const Book = props => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 11.5C1 11.1022 1.18108 10.7206 1.50341 10.4393C1.82574 10.158 2.26291 10 2.71875 10H12"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.71875 1H12V13H2.71875C2.26291 13 1.82574 12.842 1.50341 12.5607C1.18108 12.2794 1 11.8978 1 11.5V2.5C1 2.10218 1.18108 1.72064 1.50341 1.43934C1.82574 1.15804 2.26291 1 2.71875 1V1Z"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Book
