import React from 'react'

const Restaurant = props => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.7778 2.19995H2.22222C1.54721 2.19995 1 2.73721 1 3.39995V11.7999C1 12.4627 1.54721 12.9999 2.22222 12.9999H10.7778C11.4528 12.9999 12 12.4627 12 11.7999V3.39995C12 2.73721 11.4528 2.19995 10.7778 2.19995Z"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.94531 1V3.39999"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.05469 1V3.39999"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 5.80005H12"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Restaurant
