import React from 'react'

import { turquish } from '@theme/colors.module.css'

const SavedCheckmark = ({
  fillColor = turquish,
  strokeColor = turquish,
  strokeWidth = 2,
  ...props
}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 14.75C11.7279 14.75 14.75 11.7279 14.75 8C14.75 4.27208 11.7279 1.25 8 1.25C4.27208 1.25 1.25 4.27208 1.25 8C1.25 11.7279 4.27208 14.75 8 14.75Z"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9571 7.20711C11.3476 6.81658 11.3476 6.18342 10.9571 5.79289C10.5666 5.40237 9.93342 5.40237 9.54289 5.79289L10.9571 7.20711ZM7.25 9.5L6.54289 10.2071C6.93342 10.5976 7.56658 10.5976 7.95711 10.2071L7.25 9.5ZM6.45711 7.29289C6.06658 6.90237 5.43342 6.90237 5.04289 7.29289C4.65237 7.68342 4.65237 8.31658 5.04289 8.70711L6.45711 7.29289ZM9.54289 5.79289L6.54289 8.79289L7.95711 10.2071L10.9571 7.20711L9.54289 5.79289ZM7.95711 8.79289L6.45711 7.29289L5.04289 8.70711L6.54289 10.2071L7.95711 8.79289Z"
      fill={fillColor}
      strokeWidth={0.5}
    />
  </svg>
)

export default SavedCheckmark
