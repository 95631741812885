import React from 'react'

import AlertOctagon from './AlertOctagon'
import ArrowDown from './ArrowDown'
import ArrowInclinedRight from './ArrowInclinedRight'
import ArrowLeft from './ArrowLeft'
import ArrowLeftThicker from './ArrowLeftThicker'
import ArrowPointingLeft from './ArrowPointingLeft'
import ArrowPointingRight from './ArrowPointingRight'
import ArrowRight from './ArrowRight'
import Battery from './Battery'
import Bell from './Bell'
import Book from './Book'
import BrambleLogo from './BrambleLogo'
import Camera from './Camera'
import Checklist from './Checklist'
import Checkmark from './Checkmark'
import Close from './Close'
import Conference from './Conference'
import Decoration from './Decoration'
import EditIcon from './EditIcon'
import Exclamation from './Exclamation'
import ExclamationCircle from './ExclamationCircle'
import Eye from './Eye'
import FailedExclamation from './FailedExclamation'
import FairLogo from './FairLogo'
import File from './File'
import FoxLogo from './FoxLogo'
import Game from './Game'
import GlassdoorCircle from './GlassdoorCircle'
import LeftCircleArrow from './LeftCircleArrow'
import Like from './Like'
import LinkedIn from './LinkedIn'
import Love from './Love'
import MadeLogo from './MadeLogo'
import Menu from './Menu'
import MenuHamburger from './MenuHamburger'
import Movie from './Movie'
import MovingThreeDots from './MovingThreeDots'
import MusicGear from './MusicGear'
import OpenUrl from './OpenUrl'
import Plus from './Plus'
import Profile from './Profile'
import Restaurant from './Restaurant'
import ReviewStars from './ReviewStars'
import RightCircleArrow from './RightCircleArrow'
import RiotGamesLogo from './RiotGamesLogo'
import SavedCheckmark from './SavedCheckmark'
import Search from './Search'
import SmallCheckmark from './SmallCheckmark'
import SmallFile from './SmallFile'
import Star from './Star'
import Stars from './Stars'
import Success from './Success'
import ThinPlus from './ThinPlus'
import ThreeDots from './ThreeDots'
import TickCircle from './TickCircle'
import Unlock from './Unlock'
import Warning from './Warning'
import XTeamLogo from './XTeamLogo'
import XTeamLogoWithText from './XTeamLogoWithText'

export const SVGS = {
  ArrowPointingLeft: ArrowPointingLeft.name,
  ArrowPointingRight: ArrowPointingRight.name,
  ArrowRight: ArrowRight.name,
  ArrowLeft: ArrowLeft.name,
  ArrowLeftThicker: ArrowLeftThicker.name,
  Camera: Camera.name,
  Checklist: Checklist.name,
  Close: Close.name,
  GlassdoorCircle: GlassdoorCircle.name,
  Like: Like.name,
  LinkedIn: LinkedIn.name,
  Love: Love.name,
  Menu: Menu.name,
  Star: Star.name,
  Stars: Stars.name,
  Unlock: Unlock.name,
  XTeamLogo: XTeamLogo.name,
  Game: Game.name,
  Book: Book.name,
  Movie: Movie.name,
  Battery: Battery.name,
  Decoration: Decoration.name,
  Profile: Profile.name,
  Restaurant: Restaurant.name,
  Conference: Conference.name,
  MusicGear: MusicGear.name,
  Bell: Bell.name,
  Warning: Warning.name,
  Exclamation: Exclamation.name,
  ExclamationCircle: ExclamationCircle.name,
  Eye: Eye.name,
  Success: Success.name,
  Search: Search.name,
  Checkmark: Checkmark.name,
  AlertOctagon: AlertOctagon.name,
  EditIcon: EditIcon.name,
  ThreeDots: ThreeDots.name,
  SavedCheckmark: SavedCheckmark.name,
  SmallCheckmark: SmallCheckmark.name,
  MovingThreeDots: MovingThreeDots.name,
  FailedExclamation: FailedExclamation.name,
  Plus: Plus.name,
  File: File.name,
  OpenUrl: OpenUrl.name,
  ArrowDown: ArrowDown.name,
  SmallFile: SmallFile.name,
  ThinPlus: ThinPlus.name,
  TickCircle: TickCircle.name,
  XTeamLogoWithText: XTeamLogoWithText.name,
  MenuHamburger: MenuHamburger.name,
  ReviewStars: ReviewStars.name,
  RightCircleArrow: RightCircleArrow.name,
  LeftCircleArrow: LeftCircleArrow.name,
  MadeLogo: MadeLogo.name,
  BrambleLogo: BrambleLogo.name,
  FoxLogo: FoxLogo.name,
  RiotGamesLogo: RiotGamesLogo.name,
  FairLogo: FairLogo.name
}

const SVG_COMPONENTS_MAPPER = {
  [SVGS.ArrowPointingLeft]: ArrowPointingLeft,
  [SVGS.ArrowPointingRight]: ArrowPointingRight,
  [SVGS.ArrowRight]: ArrowRight,
  [SVGS.ArrowLeft]: ArrowLeft,
  [SVGS.ArrowLeftThicker]: ArrowLeftThicker,
  [SVGS.Camera]: Camera,
  [SVGS.Checklist]: Checklist,
  [SVGS.Close]: Close,
  [SVGS.GlassdoorCircle]: GlassdoorCircle,
  [SVGS.Like]: Like,
  [SVGS.LinkedIn]: LinkedIn,
  [SVGS.Love]: Love,
  [SVGS.Menu]: Menu,
  [SVGS.Star]: Star,
  [SVGS.Stars]: Stars,
  [SVGS.Unlock]: Unlock,
  [SVGS.XTeamLogo]: XTeamLogo,
  [SVGS.Game]: Game,
  [SVGS.Book]: Book,
  [SVGS.Movie]: Movie,
  [SVGS.Battery]: Battery,
  [SVGS.Decoration]: Decoration,
  [SVGS.Profile]: Profile,
  [SVGS.Restaurant]: Restaurant,
  [SVGS.Conference]: Conference,
  [SVGS.MusicGear]: MusicGear,
  [SVGS.Bell]: Bell,
  [SVGS.Warning]: Warning,
  [SVGS.Exclamation]: Exclamation,
  [SVGS.ExclamationCircle]: ExclamationCircle,
  [SVGS.Eye]: Eye,
  [SVGS.Success]: Success,
  [SVGS.Search]: Search,
  [SVGS.Checkmark]: Checkmark,
  [SVGS.AlertOctagon]: AlertOctagon,
  [SVGS.EditIcon]: EditIcon,
  [SVGS.ThreeDots]: ThreeDots,
  [SVGS.SavedCheckmark]: SavedCheckmark,
  [SVGS.SmallCheckmark]: SmallCheckmark,
  [SVGS.MovingThreeDots]: MovingThreeDots,
  [SVGS.FailedExclamation]: FailedExclamation,
  [SVGS.Plus]: Plus,
  [SVGS.File]: File,
  [SVGS.OpenUrl]: OpenUrl,
  [SVGS.ArrowDown]: ArrowDown,
  [SVGS.SmallFile]: SmallFile,
  [SVGS.ThinPlus]: ThinPlus,
  [SVGS.ArrowInclinedRight]: ArrowInclinedRight,
  [SVGS.TickCircle]: TickCircle,
  [SVGS.XTeamLogoWithText]: XTeamLogoWithText,
  [SVGS.MenuHamburger]: MenuHamburger,
  [SVGS.ReviewStars]: ReviewStars,
  [SVGS.RightCircleArrow]: RightCircleArrow,
  [SVGS.LeftCircleArrow]: LeftCircleArrow,
  [SVGS.MadeLogo]: MadeLogo,
  [SVGS.RiotGamesLogo]: RiotGamesLogo,
  [SVGS.BrambleLogo]: BrambleLogo,
  [SVGS.FoxLogo]: FoxLogo,
  [SVGS.FairLogo]: FairLogo
}

const Svg = ({ name, ...rest }) => {
  const Component = SVG_COMPONENTS_MAPPER[name] || null
  return Component && <Component {...rest} data-testid={name} />
}

export default Svg
