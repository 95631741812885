// extracted by mini-css-extract-plugin
export var Display_40 = "typography-module--Display_40--118d0";
export var Heading_Bold_24 = "typography-module--Heading_Bold_24--f0295";
export var Interface_Medium_XL = "typography-module--Interface_Medium_XL--33e7b";
export var Neutral_1 = "#EFEFEF";
export var Neutral_5 = "#707070";
export var Neutral_6 = "#505050";
export var Neutral_7 = "#303030";
export var Neutral_8 = "#0C0C0C";
export var alpha_Neutral_8_20 = "rgba(12, 12, 12, 0.2)";
export var animation_blur_012_easeIn = "animations-module--animation_blur_012_easeIn--8c867";
export var animations = "\"@theme/animations.module.css\"";
export var breakpointSM = "768px";
export var breakpointXS = "480px";
export var breakpoints = "\"@theme/breakpoints.module.css\"";
export var colors = "\"@theme/colors.module.css\"";
export var style_actionButton = "WhatToDo-module--style_actionButton--00c15";
export var style_blurOption = "WhatToDo-module--style_blurOption--1fe66 animations-module--animation_blur_012_easeIn--8c867";
export var style_closeIcon = "WhatToDo-module--style_closeIcon--666e6";
export var style_component = "WhatToDo-module--style_component--67d8b typography-module--typeface_base--8d193";
export var style_optionImage = "WhatToDo-module--style_optionImage--b5837";
export var style_optionTitle = "WhatToDo-module--style_optionTitle--3c4c0 typography-module--Heading_Bold_24--f0295";
export var style_options = "WhatToDo-module--style_options--7599b";
export var style_subTitle = "WhatToDo-module--style_subTitle--88f48 typography-module--Interface_Medium_XL--33e7b";
export var style_title = "WhatToDo-module--style_title--3fb47 typography-module--Display_40--118d0";
export var style_whatToDoOption = "WhatToDo-module--style_whatToDoOption--5b88d";
export var style_xTeamLogo = "WhatToDo-module--style_xTeamLogo--b0dae";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "\"@theme/typography.module.css\"";
export var white = "#FFFFFF";