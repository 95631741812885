import React from 'react'

import { squant } from '@theme/colors.module.css'

const ArrowLeftThicker = ({ stroke = squant, ...props }) => (
  <svg width="9" height="14" viewBox="0 0 9 14" fill="none" {...props}>
    <path d="M8 1L2 7L8 13" stroke={stroke} strokeWidth="2" />
  </svg>
)

export default ArrowLeftThicker
