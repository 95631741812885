import { useState } from 'react'

import { getLocalStorage } from '@utils/helpers'

import { IUseLocalStorage, ISetValue } from './useLocalStorage.types'
import { IDismissedNotifications } from '@type/dismissedNotifications'

const localStorage = getLocalStorage()

// TODO: This is NOT a global hook and pertained to notifications. Move closer to the component using it.
const useLocalStorage: IUseLocalStorage = (key, initialValue) => {
  // Execute logic only once
  const [storedValue, setStoredValue] = useState<IDismissedNotifications>(
    () => {
      try {
        const item = localStorage.getItem(key)
        return item ? JSON.parse(item) : initialValue
      } catch (error) {
        console.error(error)
        return initialValue
      }
    }
  )

  // Return a wrapped version of useState's setter function so that
  // it persists the new value to localStorage.
  const setValue: ISetValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      console.error(error)
    }
  }

  return {
    storedValue,
    setValue
  }
}

export default useLocalStorage
