import React, { FunctionComponent } from 'react'

import {
  AppLayoutContainer,
  getRouteConfig
} from '@containers/AppLayoutContainer'
import { FaqPageLayout } from '@containers/FaqPageLayout'
import { JobsRoot } from '@containers/JobsRoot'
import NetworkError from '@components/app/NetworkError'
import { ConnectAccounts } from '@components/redesign/ConnectAccounts'
import { Modal } from '@components/redesign/Modal'

import { useAuthContext } from '@hooks/useAuthContext'
import { AuthScreensProvider } from '@hooks/useAuthScreensContext'
import { useCookieBanner } from '@hooks/useCookieBanner'
import { useDataLayer } from '@hooks/useDataLayer'
import { useFeatureFlags } from '@hooks/useFeatureFlags'
import { useRedirections } from '@hooks/useRedirections'
import { isNetworkError } from '@utils/helpers'

import { IWrapPageElementProps } from './WrapPageElement.types'

const WrapPageElement: FunctionComponent<IWrapPageElementProps> = ({
  path = '',
  element
}) => {
  useRedirections()
  useDataLayer()
  const { features } = useFeatureFlags()
  const {
    requiresProfileLink,
    loading: isLoadingUser,
    error: authError,
    reFetchTokenCountdownValue,
    resendEmailHandler,
    connectAccountsEmailSent
  } = useAuthContext()
  const { renderCookieBanner } = useCookieBanner()

  const isServerDown = isNetworkError(authError)
  const isFeaturesLoading = Boolean(!features)

  if (isServerDown) {
    return <NetworkError reconnectTimer={reFetchTokenCountdownValue} />
  }

  const routesUsingAppLayout = {
    root: {
      route: '/',
      PageLayout: JobsRoot,
      isLoading: isFeaturesLoading,
      isEnabled: true
    },
    myApplications: {
      route: '/jobs/my-applications',
      PageLayout: JobsRoot,
      isLoading: isLoadingUser || isFeaturesLoading,
      isPrivateRoute: true,
      shouldRenderOnSSR: false
    },
    jobs: {
      route: '/jobs',
      PageLayout: JobsRoot,
      isLoading: isFeaturesLoading
    },
    profile: {
      route: '/profile',
      isLoading: isLoadingUser || isFeaturesLoading,
      isPrivateRoute: true,
      shouldRenderOnSSR: false
    },
    settings: {
      route: '/settings',
      isLoading: isLoadingUser || isFeaturesLoading,
      isPrivateRoute: true,
      shouldRenderOnSSR: false
    },
    faq: {
      route: '/faq',
      PageLayout: FaqPageLayout
    }
  }

  const routeConfig = getRouteConfig(routesUsingAppLayout, path)

  const isAppLayoutRequired =
    element && routeConfig && routeConfig.isEnabled !== false

  return (
    <AuthScreensProvider>
      {renderCookieBanner()}
      {requiresProfileLink && (
        <Modal>
          <ConnectAccounts resendEmailHandler={resendEmailHandler} isEmailSent={connectAccountsEmailSent} />
        </Modal>
      )}
      {isAppLayoutRequired ? (
        <AppLayoutContainer {...routeConfig}>{element}</AppLayoutContainer>
      ) : (
        element || null
      )}
    </AuthScreensProvider>
  )
}

export default WrapPageElement
