import React, { FunctionComponent } from 'react'

import { IJobTagProps } from './JobTag.types'

import { style_container, style_value } from './JobTag.module.css'

const JobTag: FunctionComponent<IJobTagProps> = ({
  backgroundColor,
  border,
  color,
  value
}) => (
  <div
    className={style_container}
    style={{ backgroundColor, border, color }}
    data-testid="job-tag"
  >
    <span className={style_value}>{value}</span>
  </div>
)

export default JobTag
