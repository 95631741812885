import React, { FunctionComponent } from 'react'
import { Router } from '@reach/router'

import { ApplicationHistoryLayoutContainer } from '@containers/ApplicationHistoryLayoutContainer'
import { JobsPageLayout } from '@containers/JobsPageLayout'

import { JobApplicationsProvider } from '@hooks/useJobApplicationsContext'
import { JobsProvider } from '@hooks/useJobsContext'

import { IJobsRootProps } from './JobsRoot.types'

const JobsRoot: FunctionComponent<IJobsRootProps> = ({ children }) => (
  <JobsProvider>
    <JobApplicationsProvider>
      <Router basepath="/jobs/">
        <JobsPageLayout default content={children} />
        <ApplicationHistoryLayoutContainer
          path="/my-applications/"
          content={children}
        />
      </Router>
    </JobApplicationsProvider>
  </JobsProvider>
)

export default JobsRoot
