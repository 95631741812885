// extracted by mini-css-extract-plugin
export var baseBrighter = "#FFF";
export var baseGreen = "#E1F4F2";
export var black = "#000";
export var style_break = "AvatarMenu-module--style_break--c85d5";
export var style_dialogMenu = "AvatarMenu-module--style_dialogMenu--427d5 theme--baseText--70927 theme--text--a5fab";
export var style_dialogMenuItem = "AvatarMenu-module--style_dialogMenuItem--421d8";
export var style_link = "AvatarMenu-module--style_link--c3c9a";
export var style_profileButton = "AvatarMenu-module--style_profileButton--14a90";
export var textFamily = "var(--textFamily)";
export var theme = "\"@theme.css\"";
export var turquish = "#01A390";