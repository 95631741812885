import React from 'react'
import { Link } from 'gatsby'

import { Svg, SVGS } from '@components/redesign/Svgs'

import { style_component } from './XTeamLogo.module.css'

const XTeamLogo = () => (
  <Link to="/" className={style_component}>
    <Svg name={SVGS.XTeamLogo} />
  </Link>
)

export default XTeamLogo
