import React, { FunctionComponent } from 'react'

import { IInputSectionProps } from './InputSection.types'

import {
  style_component,
  style_content,
  style_label,
  style_description,
  style_section,
  style_invalid,
  style_compact,
  style_error
} from './InputSection.module.css'

const InputSection: FunctionComponent<IInputSectionProps> = ({
  label,
  description,
  invalid,
  children,
  compact
}) => {
  if (!children) {
    return null
  }

  const componentClassName = [
    style_component,
    invalid ? style_invalid : '',
    compact ? style_compact : ''
  ].join(' ')

  return (
    <div data-testid="InputSection" className={componentClassName}>
      {(label || description) && (
        <div className={style_section}>
          {label && <div className={style_label}>{label}</div>}
          {description && (
            <div className={style_description}>{description}</div>
          )}
        </div>
      )}
      <div className={style_content}>{children}</div>
      {invalid && <div className={style_error}>{invalid}</div>}
    </div>
  )
}

export default InputSection
