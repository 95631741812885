import React from 'react'

import { Green_1, Green_5 } from '@theme/colors.module.css'

const SmallCheckmark = ({
  fillColor = Green_1,
  strokeColor = Green_1,
  checkmarkStrokeColor = Green_5,
  ...props
}) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
      fill={fillColor}
      stroke={strokeColor}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5 7.33301L8.16667 10.6663L6.5 8.99967"
      stroke={checkmarkStrokeColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SmallCheckmark
