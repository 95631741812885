import React, { FunctionComponent } from 'react'

import { IApplicationHistoryLayoutProps } from './ApplicationHistoryLayout.types'

import {
  style_component,
  style_centered,
  style_heading,
  style_description,
  style_content,
  style_footer
} from './ApplicationHistoryLayout.module.css'

const ApplicationHistoryLayout: FunctionComponent<IApplicationHistoryLayoutProps> = ({
  children,
  title,
  description,
  isCentered
}) => {
  if (!children) {
    return null
  }

  const componentClassNames = [
    style_component,
    isCentered ? style_centered : ''
  ].join(' ')

  return (
    <div data-testid="ApplicationHistoryLayout" className={componentClassNames}>
      {title && <h1 className={style_heading}>{title}</h1>}
      {description && isCentered && (
        <p className={style_description}>{description}</p>
      )}
      <div className={style_content}>
        {children}
        <p className={style_footer}>
          Previous applications older than 12 months are archived.
        </p>
      </div>
    </div>
  )
}

export default ApplicationHistoryLayout
