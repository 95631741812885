// extracted by mini-css-extract-plugin
export var disabled = "InputText-module--disabled--f31db";
export var formBorderColor = "#E6E6ED";
export var formDisabledBackground = "#F9F9F9";
export var formDisabledBorder = "#E9EDEE";
export var formDisabledText = "#333";
export var formErrorBorder = "#F63954";
export var formErrorText = "#F63954";
export var formPlaceholderColor = "#918CA0";
export var formTextColor = "#34323B";
export var input = "InputText-module--input--de997 theme--baseText--70927 theme--text--a5fab";
export var invalid = "InputText-module--invalid--d7b39";
export var theme = "\"@theme.css\"";