import React, { FunctionComponent } from 'react'
import { navigate } from 'gatsby'

import { CreateAccountForm } from '@containers/CreateAccountForm'
import { LoginForm } from '@containers/LoginForm'
import { AuthModal } from '@components/redesign/AuthModal'

import { IAuthModalContainerProps } from './AuthModalContainer.types'

const AuthModalContainer: FunctionComponent<IAuthModalContainerProps> = ({
  onClose,
  canBeClosed,
  isCreateAccountFormVisible = true,
  handleLoginCallback,
  handleAccountCreationCallback
}) => {
  const handleNavigateToForgorPassword = e => {
    e.preventDefault()
    onClose?.()
    navigate('/forgot-password/')
  }

  const LOGIN_TAB = 'Login'
  const CREATE_ACCOUNT_TAB = 'Create Account'

  const contents = [
    {
      title: LOGIN_TAB,
      render: (
        <LoginForm
          handleAuthenticatedSession={handleLoginCallback}
          handleForgotPasswordClick={handleNavigateToForgorPassword}
        />
      )
    },
    {
      title: CREATE_ACCOUNT_TAB,
      render: (
        <CreateAccountForm
          handleAuthenticatedSession={handleAccountCreationCallback}
        />
      )
    }
  ]

  const handleonBrandClick = () => {
    navigate('/')
    onClose?.()
  }

  return (
    <AuthModal
      onBrandClick={handleonBrandClick}
      onClose={canBeClosed ? onClose : undefined}
      contents={contents}
      activeTab={isCreateAccountFormVisible ? CREATE_ACCOUNT_TAB : LOGIN_TAB}
    />
  )
}

export default AuthModalContainer
