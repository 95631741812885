import { AxiosResponse } from 'axios'
import queryString from 'query-string'

import { getUserInfoByToken, IGetUserInfoByTokenData } from '@services/auth'
import { isSSR } from '@utils/helpers'
import { REDIRECT_ROUTES } from '@config/constants'

import { IGetCurrentPageConfigProps } from './redirectHelpers.types'
import { IUser } from '@type/user'

export const REDIRECT_URL_PARAM_KEY = 'redirecturl'

export function getRedirectURLParam () {
  const { search = '', hash = '' } = isSSR ? {} : window.location
  const { redirecturl = '' } = queryString.parse(search)
  return `${redirecturl}${hash}`
}

export function getInitialRedirectRoute (isLinkedInUser?: boolean) {
  const REDIRECT_URL_PARAM = getRedirectURLParam()
  if (REDIRECT_URL_PARAM) {
    return REDIRECT_URL_PARAM
  }

  return REDIRECT_ROUTES.INITIAL_JOB_APPLICATIONS
}

function getPagesConfig ({ user, token, hasUserSignedOut, isLinkedInUser }) {
  return {
    index: {
      route: new RegExp(/^\/$/),
      redirectCondition: false,
      redirectTo: REDIRECT_ROUTES.INITIAL_JOB_APPLICATIONS,
      redirectBackTo: ''
    },
    settings: {
      route: new RegExp(/^\/settings\/?$/),
      redirectCondition: !user && hasUserSignedOut,
      redirectTo: getInitialRedirectRoute(isLinkedInUser),
      redirectBackTo: ''
    },
    profile: {
      route: new RegExp(/^\/profile\/?/),
      redirectCondition: !user && hasUserSignedOut,
      redirectTo: getInitialRedirectRoute(isLinkedInUser),
      redirectBackTo: ''
    },
    login: {
      route: new RegExp(/^\/login\/?$/),
      redirectCondition: user,
      redirectTo: getInitialRedirectRoute(isLinkedInUser),
      redirectBackTo: ''
    },
    linkedin: {
      route: new RegExp(/^\/linkedin\/?$/),
      redirectCondition: user,
      redirectTo: getInitialRedirectRoute(isLinkedInUser),
      redirectBackTo: ''
    },
    signUp: {
      route: new RegExp(/^\/signup\/?$/),
      redirectCondition: user,
      redirectTo: `${getInitialRedirectRoute(isLinkedInUser)}?signup`,
      redirectBackTo: ''
    },
    signUpWorkExperiences: {
      route: new RegExp(/^\/signup\/experience\/?$/),
      redirectCondition: !user && !hasUserSignedOut,
      redirectTo: getInitialRedirectRoute(isLinkedInUser),
      redirectBackTo: ''
    },
    signUpAbout: {
      route: new RegExp(/^\/signup\/about\/?$/),
      redirectCondition: !user && !hasUserSignedOut,
      redirectTo: getInitialRedirectRoute(isLinkedInUser),
      redirectBackTo: ''
    },
    jobsMyApplication: {
      route: new RegExp(/^\/jobs\/my-applications\/?$/),
      redirectCondition: !user && hasUserSignedOut,
      redirectTo: getInitialRedirectRoute(isLinkedInUser),
      redirectBackTo: ''
    },
    jobsDetails: {
      route: new RegExp(/^\/jobs\/((?!my-applications).+)\/?$/),
      redirectCondition: false,
      shouldAllowPublicAccess: true,
      redirectBackTo: ''
    },
    jobs: {
      route: new RegExp(/^\/jobs\/?$/),
      redirectCondition: false,
      redirectBackTo: ''
    },
    resetPassword: {
      route: new RegExp(/^\/reset-password\/?$/),
      redirectCondition: user,
      redirectTo: getInitialRedirectRoute(isLinkedInUser),
      redirectBackTo: ''
    },
    activate: {
      route: new RegExp(/^\/activate\/?$/),
      redirectCondition: !token,
      redirectTo: getInitialRedirectRoute(isLinkedInUser),
      redirectBackTo: ''
    },
    unsubscribe: {
      route: new RegExp(/^\/unsubscribe\/?$/),
      redirectCondition: false,
      shouldAllowPublicAccess: true,
      redirectBackTo: ''
    },
    unsubscribeJobAlerts: {
      route: new RegExp(/^\/unsubscribe\/job-alerts\/?$/),
      redirectCondition: false,
      shouldAllowPublicAccess: true,
      redirectBackTo: ''
    }
  }
}

/**
 * If the user is not activated, then navigate the user to path `/activate`
 * with `token` in the url query param.
 * @param {Object} param0
 */
function getIsNotActivatedCurrentPageConfig ({
  currentPageConfig,
  token,
  redirecturl
}) {
  currentPageConfig.redirectCondition = true
  currentPageConfig.redirectTo = REDIRECT_ROUTES.ACTIVATE

  const tokenParamPair = `token=${token}`
  currentPageConfig.redirectBackTo = currentPageConfig.redirectBackTo
    ? `${currentPageConfig.redirectBackTo}&${tokenParamPair}`
    : `?${redirecturl ? `redirecturl=${redirecturl}&` : ''}${tokenParamPair}`

  return currentPageConfig
}

/**
 * If the user is activated, navigate the user to path `/login` or
 * `/linkedin` based on provider type with
 *  proper `redirecturl` query param in the url.
 *
 * @param {Object} param0
 */
function getIsActivatedCurrentPageConfig ({
  currentPageConfig,
  provider,
  redirecturl
}) {
  currentPageConfig.redirectCondition = true
  currentPageConfig.redirectTo = REDIRECT_ROUTES.INITIAL_JOB_APPLICATIONS

  currentPageConfig.redirectBackTo =
    currentPageConfig.redirectBackTo ||
    (redirecturl ? `?${REDIRECT_URL_PARAM_KEY}=${redirecturl}` : '')

  return currentPageConfig
}

async function checkAndGetUserActivationCurrentPageConfig ({
  currentPageConfig,
  token,
  redirecturl
}) {
  try {
    const {
      data: { isActivated, provider }
    } = (await getUserInfoByToken(token)) as AxiosResponse<
      IGetUserInfoByTokenData
    >

    if (!isActivated) {
      currentPageConfig = getIsNotActivatedCurrentPageConfig({
        currentPageConfig,
        token,
        redirecturl
      })
    } else {
      currentPageConfig = getIsActivatedCurrentPageConfig({
        currentPageConfig,
        provider,
        redirecturl
      })
    }
  } catch (error) {
    console.error(error)
  }

  return currentPageConfig
}

async function getCurrentPageConfig ({
  pagesConfig,
  pathname,
  user,
  token,
  redirecturl
}: IGetCurrentPageConfigProps) {
  const config = Object.keys(pagesConfig).find(page =>
    (pathname as string).match(pagesConfig[page].route)
  )
  let currentPageConfig = pagesConfig[config as string] || {}

  if (currentPageConfig.shouldAllowPublicAccess) {
    return currentPageConfig
  }

  if (!user && token) {
    currentPageConfig = await checkAndGetUserActivationCurrentPageConfig({
      currentPageConfig,
      token,
      redirecturl
    })
  }

  if (currentPageConfig.redirectCondition) {
    currentPageConfig.navigateTo = `${currentPageConfig.redirectTo}${currentPageConfig.redirectBackTo}`
  }

  return currentPageConfig
}

export async function getPageRedirectConfig (
  user?: IUser,
  hasUserSignedOut?: boolean,
  pathname?: string,
  search?: string,
  isLinkedInUser?: boolean
) {
  const { token, redirecturl = '' } = queryString.parse(search as string) || {}

  const pagesConfig = getPagesConfig({
    user,
    token,
    hasUserSignedOut,
    isLinkedInUser
  })

  return await getCurrentPageConfig({
    pagesConfig,
    pathname,
    user,
    token,
    redirecturl
  })
}
