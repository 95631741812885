import React, { FunctionComponent } from 'react'
import { Router } from '@reach/router'

import { FaqCardListContainer } from '@containers/FaqCardListContainer'
import { TwoColumnLayout } from '@components/redesign/TwoColumnLayout'
import SEO from '@components/SEO'

import { IFaqPageLayoutProps } from './FaqPageLayout.types'

const FaqPageLayout: FunctionComponent<IFaqPageLayoutProps> = ({
  children
}) => (
  <>
    <SEO title="FAQ" />
    <Router basepath="/faq/">
      <TwoColumnLayout
        default
        sidebar={FaqCardListContainer}
        content={children}
      />
    </Router>
  </>
)

export default FaqPageLayout
