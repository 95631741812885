import {
  IObjectIsNull,
  ISaveOnLocalStorage,
  IRemoveFromLocalStorage,
  IGetUTMParamsFromCookies,
  IRemoveUTMParamsFromCookies,
  IGetUTMParamsFromLocalStorage,
  IGetUTMParamsFromQueryParams,
  IRemoveUTMParamsFromQueryParams,
  ISaveUTMParamsOnLocalStorage,
  IRemoveUTMParamsFromLocalStorage
} from './useUTMParamsContext.types'

export const objectIsNull: IObjectIsNull = object => {
  for (const key in object) {
    if (object[key] !== null) {
      return false
    }
  }
  return true
}

export const saveOnLocalStorage: ISaveOnLocalStorage = (key, value) => {
  if (value !== null && value !== undefined) {
    localStorage.setItem(key, value)
  }
}

export const removeFromLocalStorage: IRemoveFromLocalStorage = key => {
  if (key) {
    localStorage.removeItem(key)
  }
}

export const getUTMParamsFromCookies: IGetUTMParamsFromCookies = cookies => {
  const params = {
    ...(cookies.utm_source && { utmSource: cookies.utm_source }),
    ...(cookies.utm_medium && { utmMedium: cookies.utm_medium }),
    ...(cookies.utm_campaign && { utmCampaign: cookies.utm_campaign }),
    ...(cookies.utm_content && { utmContent: cookies.utm_content }),
    ...(cookies.utm_term && { utmTerm: cookies.utm_term }),
    ...(cookies.utm_referral && { utmReferral: cookies.utm_referral })
  }

  return params
}

export const removeUTMParamsFromCookies: IRemoveUTMParamsFromCookies = removeCookies => {
  removeCookies('utm_source')
  removeCookies('utm_medium')
  removeCookies('utm_campaign')
  removeCookies('utm_content')
  removeCookies('utm_term')
  removeCookies('utm_referral')
}

export const getUTMParamsFromLocalStorage: IGetUTMParamsFromLocalStorage = () => {
  const params = {
    ...(localStorage.getItem('utm_source') && {
      utmSource: localStorage.getItem('utm_source')
    }),
    ...(localStorage.getItem('utm_medium') && {
      utmMedium: localStorage.getItem('utm_medium')
    }),
    ...(localStorage.getItem('utm_campaign') && {
      utmCampaign: localStorage.getItem('utm_campaign')
    }),
    ...(localStorage.getItem('utm_content') && {
      utmContent: localStorage.getItem('utm_content')
    }),
    ...(localStorage.getItem('utm_term') && {
      utmTerm: localStorage.getItem('utm_term')
    }),
    ...(localStorage.getItem('utm_referral') && {
      utmReferral: localStorage.getItem('utm_referral')
    })
  }

  return params
}

export const getUTMParamsFromQueryParams: IGetUTMParamsFromQueryParams = () => {
  const queryParams = new URLSearchParams(window.location.search)

  const params = {
    ...(queryParams.get('utm_source') && {
      utmSource: queryParams.get('utm_source')
    }),
    ...(queryParams.get('utm_medium') && {
      utmMedium: queryParams.get('utm_medium')
    }),
    ...(queryParams.get('utm_campaign') && {
      utmCampaign: queryParams.get('utm_campaign')
    }),
    ...(queryParams.get('utm_content') && {
      utmContent: queryParams.get('utm_content')
    }),
    ...(queryParams.get('utm_term') && {
      utmTerm: queryParams.get('utm_term')
    }),
    ...(queryParams.get('utm_referral') && {
      utmReferral: queryParams.get('utm_referral')
    })
  }

  return params
}

export const removeUTMParamsFromQueryParams: IRemoveUTMParamsFromQueryParams = () => {
  const queryParams = new URLSearchParams(window.location.search)
  queryParams.delete('utm_source')
  queryParams.delete('utm_medium')
  queryParams.delete('utm_campaign')
  queryParams.delete('utm_content')
  queryParams.delete('utm_term')
  queryParams.delete('utm_referral')

  return queryParams.toString()
}

export const saveUTMParamsOnLocalStorage: ISaveUTMParamsOnLocalStorage = utmParams => {
  saveOnLocalStorage('utm_source', utmParams.utmSource)
  saveOnLocalStorage('utm_medium', utmParams.utmMedium)
  saveOnLocalStorage('utm_campaign', utmParams.utmCampaign)
  saveOnLocalStorage('utm_content', utmParams.utmContent)
  saveOnLocalStorage('utm_term', utmParams.utmTerm)
  saveOnLocalStorage('utm_referral', utmParams.utmReferral)
}

export const removeUTMParamsFromLocalStorage: IRemoveUTMParamsFromLocalStorage = () => {
  removeFromLocalStorage('utm_source')
  removeFromLocalStorage('utm_medium')
  removeFromLocalStorage('utm_campaign')
  removeFromLocalStorage('utm_content')
  removeFromLocalStorage('utm_term')
  removeFromLocalStorage('utm_referral')
}
