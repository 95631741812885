import React from 'react'

import { Neutral_8 } from '@theme/colors.module.css'

const LeftCircleArrow = ({
  strokeColor = 'white',
  fill = Neutral_8,
  height = 56,
  width = 56,
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    {...rest}
  >
    <rect width={width} height={height} rx="28" fill={fill} />
    <path
      d="M33.8337 28L22.167 28"
      stroke={strokeColor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.167 33L22.167 28L27.167 23"
      stroke={strokeColor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default LeftCircleArrow
