import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'

import { fetchFeatureFlags } from '@services/featureFlag'

import { IUseFeatureFlags } from './useFeatureFlags.types'
import { IFeatureFlag } from '@type/featureFlag'

const useFeatureFlags: IUseFeatureFlags = () => {
  const { data: response, isLoading } = useQuery({
    queryKey: ['featureFlags'],
    queryFn: () => fetchFeatureFlags() as Promise<AxiosResponse<IFeatureFlag[]>>
  })

  const features = useMemo(
    () =>
      response?.data.reduce((acc, currentValue) => {
        acc[currentValue.flag] = currentValue.enabled
        return acc
      }, {}),
    [response]
  )

  return { features, isLoading }
}

export default useFeatureFlags
