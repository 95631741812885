import React, { FunctionComponent } from 'react'

import { Button } from '@components/redesign/Button'

import { IGetStartedCardProps } from './GetStartedCard.types'

import {
  style_component,
  style_containerText,
  style_containerButton,
  style_text
} from './GetStartedCard.module.css'

const GetStartedCard: FunctionComponent<IGetStartedCardProps> = ({
  text,
  ButtonEnhancer,
  showLoginForm,
  showCreateAccountForm
}) => {
  if (!text && !ButtonEnhancer) {
    return null
  }

  const handleKeyPress = event => {
    event.stopPropagation()
  }

  const EnhancedButton = props =>
    ButtonEnhancer ? <ButtonEnhancer {...props} /> : props.children

  return (
    <div
      className={style_component}
      data-testid="GetStartedCard"
      onKeyDown={handleKeyPress}
      tabIndex={0}
    >
      <div className={style_containerText}>
        <p className={style_text}>{text}</p>
      </div>
      <div className={style_containerButton}>
        <EnhancedButton showAuthScreens={showLoginForm}>
          <Button data-value="login-button-footer" color="default" pill block>
            Log in
          </Button>
        </EnhancedButton>
        <EnhancedButton showAuthScreens={showCreateAccountForm}>
          <Button
            data-value="get-started-button-footer"
            color="default"
            outlined
            pill
            block
          >
            Get Started
          </Button>
        </EnhancedButton>
      </div>
    </div>
  )
}

export default GetStartedCard
