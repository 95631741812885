import React from 'react'

import { bleachedSilk, squant } from '@theme/colors.module.css'

const Camera = props => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.5 11.6667C15.5 12.0203 15.3563 12.3594 15.1006 12.6095C14.8449 12.8595 14.498 13 14.1364 13H1.86364C1.50198 13 1.15513 12.8595 0.8994 12.6095C0.643668 12.3594 0.5 12.0203 0.5 11.6667V4.33333C0.5 3.97971 0.643668 3.64057 0.8994 3.39052C1.15513 3.14048 1.50198 3 1.86364 3H4.59091L5.95455 1H10.0455L11.4091 3H14.1364C14.498 3 14.8449 3.14048 15.1006 3.39052C15.3563 3.64057 15.5 3.97971 15.5 4.33333V11.6667Z"
      fill={squant}
      stroke={squant}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.00071 10.3333C9.50694 10.3333 10.728 9.13943 10.728 7.66667C10.728 6.19391 9.50694 5 8.00071 5C6.49448 5 5.27344 6.19391 5.27344 7.66667C5.27344 9.13943 6.49448 10.3333 8.00071 10.3333Z"
      fill={squant}
      stroke={bleachedSilk}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Camera
