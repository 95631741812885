import React, { FunctionComponent, Fragment } from 'react'

import { AdditionalPanels } from '@components/redesign/AdditionalPanels'
import { JobCard } from '@components/redesign/JobCard'
import { CardList } from '@components/redesign/TwoColumnLayout/CardList'

import { IJobCardListProps } from './JobCardList.types'

const JobCardList: FunctionComponent<IJobCardListProps> = ({
  jobs = [],
  selectedJob,
  jobsNavigate,
  panels,
  interval = 5,
  paddingBottom,
  getIsSuccessfulApplication
}) => {
  const handleJobNavigate = job => () => {
    jobsNavigate && jobsNavigate(job)
  }

  return (
    <CardList selectedCard={selectedJob?.id} paddingBottom={paddingBottom}>
      {jobs?.map((job, index) => (
        <Fragment key={`job-card-${job.id}`}>
          <AdditionalPanels
            position={index}
            panels={panels}
            count={jobs.length}
            interval={interval}
          />
          <JobCard
            job={job}
            onCardClick={handleJobNavigate(job)}
            isSelected={selectedJob?.id === job.id}
            isSuccessfulApplication={
              getIsSuccessfulApplication &&
              getIsSuccessfulApplication(job.id as number)
            }
          />
        </Fragment>
      ))}
    </CardList>
  )
}

export default JobCardList
