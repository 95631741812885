// extracted by mini-css-extract-plugin
export var Display_40 = "typography-module--Display_40--118d0";
export var Green_2 = "#CCECDF";
export var Green_6 = "#1F553E";
export var breakpointSM = "768px";
export var breakpoints = "'@theme/breakpoints.module.css'";
export var cascadeGreen = "#A1C2B5";
export var colors = "'@theme/colors.module.css'";
export var fs18_fw500 = "typography-module--fs18_fw500--ab478";
export var style_button = "SuccessRegistration-module--style_button--13e5b";
export var style_component = "SuccessRegistration-module--style_component--913ea typography-module--typeface_base--8d193";
export var style_subtitle = "SuccessRegistration-module--style_subtitle--8fc6d typography-module--fs18_fw500--ab478";
export var style_title = "SuccessRegistration-module--style_title--683ff typography-module--Display_40--118d0";
export var style_xTeamLogo = "SuccessRegistration-module--style_xTeamLogo--3f935";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "'@theme/typography.module.css'";