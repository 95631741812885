import React, { FunctionComponent } from 'react'

import { ApplicationHistoryLayout } from '@components/redesign/ApplicationHistoryLayout'

import { useJobApplicationsContext } from '@hooks/useJobApplicationsContext'
import { size } from '@utils/helpers'

import { IApplicationHistoryLayoutContainerProps } from './ApplicationHistoryLayoutContainer.types'

const ApplicationHistoryLayoutContainer: FunctionComponent<IApplicationHistoryLayoutContainerProps> = ({
  content
}) => {
  const {
    jobApplications,
    isLoadingJobApplications
  } = useJobApplicationsContext()

  const hasApplications = size(jobApplications) > 0
  const layoutProps = hasApplications
    ? {
      title: 'Application History'
    }
    : {
      title: 'No Applications To Display',
      description: 'Here are a couple of Next Steps you can take:',
      isCentered: true
    }

  return (
    <ApplicationHistoryLayout {...layoutProps}>
      {isLoadingJobApplications ? null : content}
    </ApplicationHistoryLayout>
  )
}

export default ApplicationHistoryLayoutContainer
