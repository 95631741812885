import React, { ForwardRefRenderFunction, forwardRef, LegacyRef } from 'react'

import type { IMainMenuProps, INavigateTo, IHandleSignOut, IHandleshowAuthScreens } from './MainMenu.types'

import {
  style_component,
  style_heading,
  style_list,
  style_item,
  style_featuredItem,
  style_emailItem,
  style_divider,
  style_link,
  style_notification,
} from './MainMenu.module.css'

const MainMenu: ForwardRefRenderFunction<HTMLDivElement, IMainMenuProps> = ({
  onClick,
  navigate,
  signOut,
  profileEmail,
  hasProfileNotification,
  showAuthScreens,
}, ref) => {
  if (!navigate && !signOut) {
    return null
  }

  const navigateTo: INavigateTo = to => e => {
    e?.preventDefault()
    navigate?.(to)
    onClick?.()
  }

  const handleSignOut: IHandleSignOut = e => {
    e?.preventDefault()
    signOut?.()
    navigateTo('/')
    onClick?.()
  }

  const handleShowAuthScreens: IHandleshowAuthScreens = e => {
    e?.preventDefault()
    onClick?.()
    showAuthScreens?.()
  }

  return (
    <nav
      data-testid="MainMenu"
      className={style_component}
      ref={ref as LegacyRef<HTMLElement>}
    >
      <h2 className={style_heading}>Menu</h2>
      <ul className={style_list}>
        {profileEmail ? (
          <>
            <li className={style_emailItem}>
              Logged in as<br />
              <strong>{profileEmail}</strong>
            </li>
            <hr className={style_divider} />
            <li className={style_featuredItem}>
              <a
                href="/profile/"
                className={style_link}
                onClick={navigateTo('/profile/')}
              >
                Update Profile
                {hasProfileNotification && (
                  <span className={style_notification} />
                )}
              </a>
            </li>
            <li className={style_featuredItem}>
              <a
                href="/jobs/my-applications/"
                className={style_link}
                onClick={navigateTo('/jobs/my-applications/')}
              >
                Application History
              </a>
            </li>
            <hr className={style_divider} />
            <li className={style_item}>
              <a
                href="/settings/"
                className={style_link}
                onClick={navigateTo('/settings/')}
              >
                Settings
              </a>
            </li>
            {signOut && (
              <>
                <hr className={style_divider} />
                <li className={style_item}>
                  <a
                    className={style_link}
                    href="/"
                    onClick={handleSignOut}
                  >
                    Log Out
                  </a>
                </li>
              </>
            )}
          </>
        ) : (
          <>
            <li className={style_featuredItem}>
              <a
                href="/"
                className={style_link}
                onClick={handleShowAuthScreens}
              >
                Get Started/Login
              </a>
            </li>
            <li className={style_item}>
              <a
                href="/jobs/"
                className={style_link}
                onClick={navigateTo('/jobs/')}
              >
                Jobs Board
              </a>
            </li>
            <li className={style_item}>
              <a
                href="https://x-team.com/blog/unleash/"
                className={style_link}
                target="_blank"
                rel="noreferrer"
              >
                Unleash+
              </a>
            </li>
            <li className={style_item}>
              <a
                href="/faq/"
                className={style_link}
                onClick={navigateTo('/faq/')}
              >
                FAQ
              </a>
            </li>
            <li className={style_item}>
              <a
                href="https://x-team.com/blog/"
                className={style_link}
                target="_blank"
                rel="noreferrer"
              >
                Blog
              </a>
            </li>
          </>
        )}
      </ul>
    </nav>
  )
}

export default forwardRef(MainMenu)
