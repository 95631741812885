import React, { FunctionComponent, useState } from 'react'
import {
  DropzoneRootProps,
  DropzoneInputProps
} from 'react-dropzone/typings/react-dropzone'

import { ErrorMessage } from '@components/redesign/ErrorMessage'
import { ModalBox } from '@components/redesign/Modal/ModalBox'
import { Svg, SVGS } from '@components/redesign/Svgs'

import {
  style_component,
  style_instructionsImage,
  style_instructions,
  style_icons,
  style_infoLine,
  style_fileInfo,
  style_fileInfoDefault,
  style_title,
  style_subtitle,
  style_heading,
  style_arrowLeft
} from './UploadLinkedinCV.module.css'
import { Neutral_8, blueDiamond } from '@theme/colors.module.css'

type UploadLinkedinCVProps = {
  handleBrowseFile?: () => void
  handleGoBackClick?: () => void
  getRootProps?: DropzoneRootProps
  getInputProps?: DropzoneInputProps
  somethingWrong?: boolean
  isGoBackArrowVisible?: boolean
  uploadPercent?: number
  errorMessage?: string
}

const UploadLinkedinCV: FunctionComponent<UploadLinkedinCVProps> = ({
  handleBrowseFile,
  handleGoBackClick,
  getRootProps,
  getInputProps,
  somethingWrong,
  uploadPercent = 0,
  isGoBackArrowVisible,
  errorMessage
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false)

  const handleOnHovered = () => setIsHovered(true)

  const handleOnHoverOut = () => setIsHovered(false)

  const title = somethingWrong ? 'Something went wrong.' : 'Drop it here'
  const hasUploadError = Boolean(errorMessage)
  const subtitle = somethingWrong
    ? 'Wrong file format, please try again with another format.'
    : 'or click to browse'

  const uploadPercentInfo = (
    <p className={style_infoLine}>Uploading... {uploadPercent}%</p>
  )

  const isNotUploading = uploadPercent <= 0

  const hoveredView = (
    <div>
      {isNotUploading ? <Svg name={SVGS.ThinPlus} /> : uploadPercentInfo}
    </div>
  )

  const notHoveredView = (
    <div className={style_fileInfoDefault}>
      {isNotUploading ? (
        <>
          {title && <h2 className={style_title}>{title}</h2>}
          {subtitle && <p className={style_subtitle}>{subtitle}</p>}
        </>
      ) : (
        uploadPercentInfo
      )}
    </div>
  )

  return (
    <div data-testid="UploadLinkedinCV" className={style_component}>
      <ModalBox>
        {handleGoBackClick && isGoBackArrowVisible && (
          <div className={style_arrowLeft} onClick={handleGoBackClick}>
            <Svg name={SVGS.ArrowLeftThicker} stroke={Neutral_8} />
          </div>
        )}
        <h1 className={style_heading}>Use my linkedin CV</h1>
        <div className={style_instructionsImage} />
        <div className={style_instructions}>
          <p>
            Go to your{' '}
            <a
              href="https://www.linkedin.com/in/"
              target="_blank"
              rel="noreferrer"
            >
              LinkedIn Profile <Svg name={SVGS.OpenUrl} stroke={blueDiamond} />
            </a>
          </p>
          <p>
            and click on <strong>‘Save to PDF’</strong>
          </p>
        </div>
        <div className={style_icons}>
          <div>
            <Svg name={SVGS.SmallFile} />
          </div>
          <div>
            <Svg name={SVGS.ArrowDown} />
          </div>
        </div>
        {hasUploadError && <ErrorMessage message={errorMessage} block />}

        <div
          onClick={handleBrowseFile}
          onMouseEnter={handleOnHovered}
          onMouseOut={handleOnHoverOut}
          onDragEnter={handleOnHovered}
          onDragLeave={handleOnHoverOut}
        >
          <div {...getRootProps} className={style_fileInfo}>
            {getInputProps && <input {...getInputProps} />}
            {isHovered ? hoveredView : notHoveredView}
          </div>
        </div>
      </ModalBox>
    </div>
  )
}

export default UploadLinkedinCV
