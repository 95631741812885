import React from 'react'

import { hornetSting } from '@theme/colors.module.css'

const ExclamationCircle = ({ stroke = hornetSting, ...rest }) => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" {...rest}>
    <rect
      width="17"
      height="17"
      rx="8.5"
      fill={hornetSting}
      fillOpacity="0.1"
    />
    <path
      d="M8.5 5.5V8.5"
      stroke={hornetSting}
      strokeOpacity="0.9"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 11.5H8.49878"
      stroke={hornetSting}
      strokeOpacity="0.9"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ExclamationCircle
