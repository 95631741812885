import Modal from './Modal'

const storiesConfig = {
  title: 'Generic components/Modal',
  component: Modal,
  argTypes: {
    hasMargin: { control: 'boolean' },
    onClose: { action: true }
  },
  parameters: {
    layout: 'fullscreen',
    controls: {
      exclude: ['children', 'onClose']
    }
  }
}

export { Modal, storiesConfig }
