import React, { FunctionComponent, useState } from 'react'
import { navigate } from 'gatsby'

import { Button } from '@components/redesign/Button'
import { SVGS, Svg } from '@components/redesign/Svgs'

import whatToDoStepOption1Image from '@assets/whatToDoStep1.png'
import whatToDoStepOption2Image from '@assets/whatToDoStep2.png'

import {
  IRenderContinueButtonProps,
  IRenderOptionBlockProps,
  IWhatToDoProps
} from './WhatToDo.types'

import {
  style_component,
  style_title,
  style_subTitle,
  style_options,
  style_whatToDoOption,
  style_optionTitle,
  style_optionImage,
  style_blurOption,
  style_actionButton,
  style_xTeamLogo,
  style_closeIcon
} from './WhatToDo.module.css'

const WhatToDo: FunctionComponent<IWhatToDoProps> = ({ onClose }) => {
  const [blurDiv, setBlurDiv] = useState<number | null>(null)

  const handleClick = (url: string) => {
    navigate(url)
    onClose?.()
  }

  const renderContinueButton = ({ type, url }: IRenderContinueButtonProps) => (
    <Button
      data-value={`continue-registration-apply-${type}`}
      block
      color="default"
      className={style_actionButton}
    >
      Continue
    </Button>
  )

  const fadeOption = (id: number | null) => () => setBlurDiv(id)

  const renderOptionBlock = ({
    divToFade,
    redirectUrl,
    type,
    title
  }: IRenderOptionBlockProps) => (
    <div
      className={[
        style_whatToDoOption,
        blurDiv && divToFade !== blurDiv ? style_blurOption : ''
      ].join(' ')}
      onMouseEnter={fadeOption(divToFade)}
      onMouseLeave={fadeOption(null)}
      onClick={() => handleClick(redirectUrl)}
    >
      <div className={style_optionTitle}>{title}</div>
      <div className={style_optionImage}>
        <img
          src={
            divToFade === 1
              ? whatToDoStepOption2Image
              : whatToDoStepOption1Image
          }
          alt={title}
        />
      </div>
      {renderContinueButton({ type, url: redirectUrl })}
    </div>
  )

  return (
    <div data-testid="WhatToDo" className={style_component}>
      <Svg name={SVGS.XTeamLogo} className={style_xTeamLogo} />
      {onClose && (
        <Svg name={SVGS.Close} className={style_closeIcon} onClick={onClose} />
      )}
      <div className={style_title}>What would you like to do now?</div>
      <div className={style_subTitle}>
        Don’t worry, you can come back to the other options later.
      </div>
      <div className={style_options}>
        {renderOptionBlock({
          divToFade: 2,
          redirectUrl: '/profile/',
          type: 'profile',
          title: 'Complete your profile'
        })}
        {renderOptionBlock({
          divToFade: 1,
          redirectUrl: '/jobs/',
          type: 'apply',
          title: 'Apply for a job'
        })}
      </div>
    </div>
  )
}

export default WhatToDo
