import React from 'react'
import { Link } from 'gatsby'

import {
  link,
  menuButton,
  menuButtonActive
} from './NavigationMenuButton.module.css'

const NavigationMenuButton = ({
  label,
  to,
  active,
  target,
  isExternalLink
}) => {
  const className = active ? menuButtonActive : menuButton
  return isExternalLink ? (
    <a className={link} href={to} target={target} rel="noreferrer">
      <button className={className}>{label}</button>
    </a>
  ) : (
    <Link className={link} to={to} target={target}>
      <button className={className}>{label}</button>
    </Link>
  )
}

export default NavigationMenuButton
