import React, { FunctionComponent } from 'react'

import { IModalBoxProps } from './ModalBox.types'

import {
  style_component,
  style_bright,
  style_dark
} from './ModalBox.module.css'

const ModalBox: FunctionComponent<IModalBoxProps> = ({
  color = 'bright',
  children
}) => {
  if (!children) {
    return null
  }

  const availableColors = ['bright', 'dark']
  const colorClassName = {
    bright: style_bright,
    dark: style_dark
  }

  const classNames = [
    style_component,
    availableColors.includes(color)
      ? colorClassName[color]
      : colorClassName.bright
  ].join(' ')

  return (
    <div className={classNames} data-testid="ModalBox">
      {children}
    </div>
  )
}

export default ModalBox
