// extracted by mini-css-extract-plugin
export var breakpointSM = "768px";
export var breakpoints = "\"@theme/breakpoints.module.css\"";
export var colors = "\"@theme/colors.module.css\"";
export var gradient_armorWash_carbonFiber = "linear-gradient(222.76deg, #040404 0%, #2D2D2D 100%)";
export var squant = "#666666";
export var style_bright = "ModalBox-module--style_bright--02f9b";
export var style_component = "ModalBox-module--style_component--0a989 typography-module--typeface_base--8d193";
export var style_dark = "ModalBox-module--style_dark--aaf9b";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "\"@theme/typography.module.css\"";
export var white = "#FFFFFF";