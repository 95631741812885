// extracted by mini-css-extract-plugin
export var Green_1 = "#EEF9F4";
export var Green_2 = "#CCECDF";
export var Green_3 = "#88D3B4";
export var Green_4 = "#44BB89";
export var Green_5 = "#2C7757";
export var Green_6 = "#1F553E";
export var Green_7 = "#133325";
export var Green_8 = "#06110C";
export var Neutral_1 = "#EFEFEF";
export var Neutral_2 = "#CFCFCF";
export var Neutral_3 = "#AFAFAF";
export var Neutral_4 = "#8F8F8F";
export var Neutral_5 = "#707070";
export var Neutral_6 = "#505050";
export var Neutral_7 = "#303030";
export var Neutral_8 = "#0C0C0C";
export var Ocean_1 = "#E8E6F8";
export var Ocean_2 = "#BBB5E9";
export var Ocean_3 = "#8E84DB";
export var Ocean_4 = "#6052CD";
export var Ocean_5 = "#4132AD";
export var Ocean_6 = "#2E247B";
export var Ocean_7 = "#1C164A";
export var Ocean_8 = "#08051B";
export var Red_1 = "#FAE4E4";
export var Red_2 = "#F0AEAE";
export var Red_3 = "#E67878";
export var Red_4 = "#DC4343";
export var Red_5 = "#BC2323";
export var Red_6 = "#871919";
export var Red_7 = "#510F0F";
export var Red_8 = "#1B0505";
export var Sun_1 = "#FFF6DF";
export var Sun_2 = "#FFE49F";
export var Sun_3 = "#FFD260";
export var Sun_4 = "#FFC020";
export var Sun_5 = "#DFA000";
export var Sun_6 = "#9F7300";
export var Sun_7 = "#604500";
export var Sun_8 = "#201700";
export var alpha_Neutral_8_040 = "rgba(12, 12, 12, 0.04)";
export var alpha_Neutral_8_08 = "rgba(12, 12, 12, 0.08)";
export var alpha_Neutral_8_10 = "rgba(12, 12, 12, 0.1)";
export var alpha_Neutral_8_12 = "rgba(12, 12, 12, 0.12)";
export var alpha_Neutral_8_20 = "rgba(12, 12, 12, 0.2)";
export var alpha_Neutral_8_40 = "rgba(12, 12, 12, 0.4)";
export var alpha_Neutral_8_50 = "rgba(12, 12, 12, 0.5)";
export var alpha_Neutral_8_60 = "rgba(12, 12, 12, 0.6)";
export var alpha_Neutral_8_80 = "rgba(12, 12, 12, 0.8)";
export var alpha_Ocean_6_80 = "rgba(46, 36, 123, 0.8)";
export var alpha_bitcoin_15 = "rgba(255, 187, 13, 0.15)";
export var alpha_black_7 = "rgba(0,0,0,0.7)";
export var alpha_bleachedSilk_20 = "rgba(242, 242, 242, 0.2)";
export var alpha_hornetSting_05 = "rgba(255, 0, 53, 0.05)";
export var alpha_hornetSting_09 = "rgba(255, 0, 53, 0.9)";
export var alpha_hornetSting_10 = "rgba(255, 0, 53, 0.1)";
export var alpha_hornetSting_50 = "rgba(255, 0, 53, 0.5)";
export var alpha_white_04 = "rgba(255, 255, 255, 0.04)";
export var alpha_white_08 = "rgba(255, 255, 255, 0.08)";
export var alpha_white_10 = "rgba(255, 255, 255, 0.1)";
export var alpha_white_12 = "rgba(255, 255, 255, 0.12)";
export var alpha_white_20 = "rgba(255, 255, 255, 0.2)";
export var alpha_white_40 = "rgba(255, 255, 255, 0.4)";
export var alpha_white_50 = "rgba(255, 255, 255, 0.5)";
export var amaranth = "#DE2B50";
export var ardentCoral = "#E37568";
export var armorWash = "#040404";
export var arubaGreen = "#50B691";
export var baingani = "#8971F4";
export var bee = "#F5BE53";
export var bielTanGreen = "#16A163";
export var bitcoin = "#FFBB0D";
export var black = "#000000";
export var blackPanther = "#424242";
export var bleachedSilk = "#F2F2F2";
export var blueDiamond = "#0A66C2";
export var boneChilling = "#E1F4F2";
export var butteredPopcorn = "#FFF1A7";
export var calcareousSinter = "#DFE9FF";
export var candyGrapeFizz = "#7A4EF6";
export var candyGrapeFizz2 = "#7E55F3";
export var caramelizedOrange = "#ED8E4C";
export var carbonFiber = "#2D2D2D";
export var cascadeGreen = "#A1C2B5";
export var cerebralGrey = "#CCCCCC";
export var chineseLantern = "#F19454";
export var christmasSilver = "#E0E0E0";
export var coldMorning = "#E5E5E5";
export var creamAndButter = "#FCEEA5";
export var desertLily = "#FFF5DB";
export var devilsGrass = "#46B057";
export var devilsGrass2 = "#45AF56";
export var direWolf = "#272727";
export var dirtySnow = "#CDCDD6";
export var doveGrey = "#6D6C6C";
export var earlyBlossom = "#FFE3EB";
export var extraordinaryAbundanceOfTinge = "#E6E6E6";
export var flyAway = "#80AEF0";
export var fruitDove = "#D4587A";
export var glimpseOfPink = "#fff3f5";
export var gloomyPurple = "#9148DA";
export var gradient_Neutral_8_Neutral_8 = "linear-gradient(0deg, #0C0C0C, #0C0C0C)";
export var gradient_Ocean_6_Ocean_6 = "linear-gradient(266.57deg, rgba(46, 36, 123, 0.8) 2.06%, rgba(46, 36, 123, 0) 85.27%)";
export var gradient_armorWash_carbonFiber = "linear-gradient(222.76deg, #040404 0%, #2D2D2D 100%)";
export var gradient_blackAlpha_black = "linear-gradient(86.07deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%)";
export var gradient_blackWash_Neutral_8 = "linear-gradient(180deg, rgba(12, 12, 12, 0.6) 21.84%, #0C0C0C 71.03%)";
export var gradient_black_Neutral_8 = "linear-gradient(156.48deg, rgba(255, 255, 255, 0.08) -0.36%, rgba(12, 12, 12, 0.08) 67.51%), #0C0C0C";
export var gradient_bleachedSilk_white = "linear-gradient(94.99deg, #F2F2F2 0%, #FFFFFF 100%)";
export var gradient_caramelizedOrange_bee = "linear-gradient(269.23deg, #ed8e4c -1.67%, #f5be53 89.7%), linear-gradient(222.76deg, #040404 0%, #2d2d2d 100%)";
export var gradient_christmasSilver_black = "linear-gradient(114.24deg, #E0E0E0 57.83%, #000000 105.88%)";
export var gradient_devilsGrass2_arubaGreen = "linear-gradient(269.53deg, #45AF56 15.74%, #50B691 84.75%)";
export var gradient_highBlue_guillimanBlue = "linear-gradient(269.33deg, #45A4E9 23.46%, #699BFE 67.41%), linear-gradient(317.24deg, #FFFFFF 0%, #F2F2F2 100%)";
export var gradient_venetianNights_purpleIllusionist = "linear-gradient(269.23deg, #7A4EF7 -2.63%, #A180FF 89.7%)";
export var gradient_white_bleachedSilk = "linear-gradient(317.24deg, #FFFFFF 0%, #F2F2F2 100%)";
export var gradient_wildDove_06_black_86 = "linear-gradient(164.27deg, rgba(139, 139, 139, 0.6) 7.3%, rgba(0, 0, 0, 0) 85.97%)";
export var greekSea = "#74A0E0";
export var greenPawPaw = "#075E45";
export var guillimanBlue = "#699BFE";
export var guillimanBlue2 = "#5C90F4";
export var halloweenPunch = "#D91F11";
export var hangingGardensOfBabylon = "#0CAA41";
export var harborBlue = "#52649A";
export var highBlue = "#45A4E9";
export var hornetSting = "#FF0035";
export var husky = "#E3EBFF";
export var irishBeauty = "#077D55";
export var ivoryBuff = "#E7DA97";
export var jadeMountain = "#2FC3A9";
export var lighthouse = "#F4F4F4";
export var linen = "#FFECEC";
export var mayaBlue = "#6EC1FD";
export var mechriteRed = "#A1160A";
export var mediumCandyAppleRed = "#E50030";
export var mikadoYellow = "#EDAB03";
export var niagara = "#29A892";
export var nosegay = "#FFE5EB";
export var ogreOdor = "#FA5343";
export var orangeSoda = "#F45538";
export var palaceBlue = "#3A6BB2";
export var parkwater = "#4979BF";
export var pearlBay = "#7DC6CB";
export var placeboPurple = "#F2E5FF";
export var purpleIllusionist = "#A180FF";
export var quicksilver = "#A6A6A6";
export var ravenBlack = "#3D3D3D";
export var richRed = "#FF0D3F";
export var roseFrost = "#FFEBE9";
export var sereniTeal = "#78BDA4";
export var sonicSilver = "#757575";
export var soothingWhite = "#E2E2E3";
export var squant = "#666666";
export var starflowerBlue = "#4F97AD";
export var starflowerBlue2 = "#5399AF";
export var turquish = "#01A390";
export var uniqueGrey = "#C9C9C9";
export var venetianNights = "#7A4EF7";
export var veteransDayBlue = "#2E64E2";
export var weatheredStone = "#C4C4C4";
export var white = "#FFFFFF";
export var whiteAsHeaven = "#FEFEFE";
export var wildDove = "#8B8B8B";
export var wildnessMint = "#609969";
export var winterChill = "#8ED2D7";
export var yinBaiSilver = "#E0E0E1";
export var yingGuangSePurple = "#5724E5";