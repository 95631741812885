import React, { FunctionComponent } from 'react'

import {
  style_component,
  style_linkedCVButton
} from './LinkedinUploadButton.module.css'

type LinkedinUploadButtonProps = {
  onClick?: () => void
  label?: string
}

const LinkedinUploadButton: FunctionComponent<LinkedinUploadButtonProps> = ({
  onClick,
  label = 'Use my linkedin CV'
}) => {
  if (!onClick) {
    return null
  }

  return (
    <div data-testid="LinkedinUploadButton" className={style_component}>
      <button
        className={style_linkedCVButton}
        data-testid={'UploadLinkedinCV-Button'}
        onClick={onClick}
        type="button"
      >
        {label}
      </button>
    </div>
  )
}

export default LinkedinUploadButton
