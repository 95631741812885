import { AxiosError } from 'axios'
import queryString from 'query-string'

import { api, handleAPIError } from '@utils/apiHelpers'
import { size } from '@utils/helpers'

import { IApplyForJobArgs, IWithdrawFromJobArgs } from './jobApplication.types'
import { IJobApplication } from '@type/jobApplication'
import { IRequestQueryParams } from '@type/requestQueryParams'

export const fetchOwnJobApplicationsByQuery = async (
  query: IRequestQueryParams = {}
) => {
  const queryParams = size(query) > 0 ? `?${queryString.stringify(query)}` : ''
  try {
    return await api.get<IJobApplication[]>(`/job-applications${queryParams}`)
  } catch (error) {
    return handleAPIError(error as AxiosError)
  }
}

export const applyForJob = async (jobId: number) => {
  try {
    return await api.post<IApplyForJobArgs, IJobApplication>(
      '/job-applications',
      { job: jobId }
    )
  } catch (error) {
    return handleAPIError(error as AxiosError)
  }
}

export const withdrawFromJob = async (jobApplicationId: number) => {
  try {
    return await api.put<IWithdrawFromJobArgs, IJobApplication>(
      `/job-applications/${jobApplicationId}`,
      {
        applied: false
      }
    )
  } catch (error) {
    return handleAPIError(error as AxiosError)
  }
}

export const reapplyForJob = async (jobApplicationId: number) => {
  try {
    return await api.put<unknown, IJobApplication>(
      `/job-applications/${jobApplicationId}/reapply`,
      {}
    )
  } catch (error) {
    return handleAPIError(error as AxiosError)
  }
}
