import React from 'react'

import { squant } from '@theme/colors.module.css'

const Star = props => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.5 1L8.1995 4.6204L12 5.20452L9.25 8.02103L9.899 12L6.5 10.1204L3.101 12L3.75 8.02103L1 5.20452L4.8005 4.6204L6.5 1Z"
      stroke={squant}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Star
