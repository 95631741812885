import React from 'react'

import { Neutral_4 } from '@theme/colors.module.css'

const Eye = ({ color = Neutral_4, ...rest }) => (
  <svg
    width="20"
    height="13"
    viewBox="0 0 20 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M10.0013 11.5C14.6037 11.5 18.3346 6.5 18.3346 6.5C18.3346 6.5 14.6037 1.5 10.0013 1.5C5.39893 1.5 1.66797 6.5 1.66797 6.5C1.66797 6.5 5.39893 11.5 10.0013 11.5Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.9987 8.16634C10.9192 8.16634 11.6654 7.42015 11.6654 6.49967C11.6654 5.5792 10.9192 4.83301 9.9987 4.83301C9.07822 4.83301 8.33203 5.5792 8.33203 6.49967C8.33203 7.42015 9.07822 8.16634 9.9987 8.16634Z"
      fill={color}
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Eye
