import React, { FunctionComponent } from 'react'

import { Button } from '@components/redesign/Button'

import { IReceiveRelevantJobOpportunitiesProps } from './ReceiveRelevantJobOpportunities.types'

import {
  style_card,
  style_cardTitle,
  style_cardSubTitle
} from './ReceiveRelevantJobOpportunities.module.css'

const ReceiveRelevantJobOpportunities: FunctionComponent<IReceiveRelevantJobOpportunitiesProps> = ({
  buttonContainer: ButtonContainer
}) => (
  <div className={style_card}>
    <span className={style_cardTitle}>RECEIVE RELEVANT JOB OPPORTUNITIES.</span>
    <span className={style_cardSubTitle}>
      Subscribe and receive opportunities that are relevant to your skills and
      interests.
    </span>
    {ButtonContainer ? (
      <ButtonContainer>
        <Button href="/settings/" color="transparent" size="extra_small">
          SUBSCRIBE NOW
        </Button>
      </ButtonContainer>
    ) : (
      <Button href="/settings/" color="transparent" size="extra_small">
        SUBSCRIBE NOW
      </Button>
    )}
  </div>
)

export default ReceiveRelevantJobOpportunities
