import { useState, useLayoutEffect } from 'react'

import { isSSR } from '@utils/helpers'

import { IUseMediaQuery } from './useMediaQuery.types'

const useMediaQuery: IUseMediaQuery = query => {
  const [matches, setMatches] = useState(false)

  // eslint-disable-next-line prefer-const
  let media

  useLayoutEffect(() => {
    if (media) {
      const listener = () => {
        setMatches(media.matches)
      }

      media.addEventListener('change', listener)
      return () => media.removeEventListener('change', listener)
    }
  }, [media])

  if (isSSR) {
    return false
  }

  media = window?.matchMedia(query)

  if (!media) {
    return false
  }

  if (media.matches !== matches) {
    setMatches(media.matches)
  }

  return media?.matches !== matches ? media.matches : matches
}

export default useMediaQuery
