import React, { FunctionComponent } from 'react'

import { IJobResultsPanelProps } from './JobResultsPanel.types'

import {
  styles_resultsTitle,
  styles_resultsSubTitle,
  styles_divisor,
  styles_divisorContainer
} from './JobResultsPanel.module.css'

const Divisor = ({ children }) => (
  <div className={styles_divisorContainer}>
    <hr className={styles_divisor} />
    <p className={styles_resultsSubTitle}>{children}</p>
    <hr className={styles_divisor} />
  </div>
)

const JobResultsPanel: FunctionComponent<IJobResultsPanelProps> = ({
  searchValue,
  isSearchApplied,
  resultsLength
}) => {
  const hasFoundResults = isSearchApplied && resultsLength > 0
  const hasEmptyResults = isSearchApplied && resultsLength === 0

  if (hasFoundResults) {
    return (
      <>
        <p className={styles_resultsTitle}>
          {resultsLength} jobs were found for &quot;{searchValue}&quot;
        </p>

        <Divisor>SEARCH RESULTS</Divisor>
      </>
    )
  }

  if (hasEmptyResults) {
    return (
      <>
        <p className={styles_resultsTitle}>
          No jobs were found for this search.
        </p>

        <Divisor>OTHER JOBS YOU MIGHT LIKE</Divisor>
      </>
    )
  }

  return null
}

export default JobResultsPanel
