import React, { useEffect } from 'react'
import * as Sentry from '@sentry/gatsby'

import { title, paragraph, wrapper } from './NetworkError.module.css'

const NetworkError = ({ reconnectTimer = 0 }) => {
  useEffect(() => {
    Sentry.captureMessage('The server seems to be offline.')
  }, [])

  return (
    <div className={wrapper}>
      <h1 className={title}>The server seems to be offline.</h1>
      <p className={paragraph}>Sorry, something went wrong.</p>
      <p className={paragraph}>
        {reconnectTimer === 0
          ? `Trying to reconnect now...`
          : `Trying to reconnect in ${reconnectTimer} seconds...`}
      </p>
    </div>
  )
}

export default NetworkError
