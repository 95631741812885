import React from 'react'

import { Neutral_7 } from '@theme/colors.module.css'

const Profile = ({
  height = 13,
  width = 10,
  stroke = Neutral_7,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    {...props}
  >
    <path
      d="M1 11.6667C1 10.1939 2.79086 9 5 9C7.20914 9 9 10.1939 9 11.6667"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.00004 7.00002C6.4728 7.00002 7.66671 5.80611 7.66671 4.33335C7.66671 2.86059 6.4728 1.66669 5.00004 1.66669C3.52728 1.66669 2.33337 2.86059 2.33337 4.33335C2.33337 5.80611 3.52728 7.00002 5.00004 7.00002Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Profile
