import React from 'react'

const Battery = props => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 15 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.88889 9H2.44444C2.06135 9 1.69395 8.85952 1.42307 8.60947C1.15218 8.35943 1 8.02029 1 7.66667V2.33333C1 1.97971 1.15218 1.64057 1.42307 1.39052C1.69395 1.14048 2.06135 1 2.44444 1H4.74833M11.1111 1H12.5556C12.9386 1 13.306 1.14048 13.5769 1.39052C13.8478 1.64057 14 1.97971 14 2.33333V7.66667C14 8.02029 13.8478 8.35943 13.5769 8.60947C13.306 8.85952 12.9386 9 12.5556 9H10.2517"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.22483 1L5.33594 5H9.66927L6.78038 9"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Battery
