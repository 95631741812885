// extracted by mini-css-extract-plugin
export var Heading_Bold_24 = "typography-module--Heading_Bold_24--f0295";
export var Interface_SemiBold_M = "typography-module--Interface_SemiBold_M--61495";
export var Neutral_1 = "#EFEFEF";
export var Neutral_4 = "#8F8F8F";
export var Neutral_6 = "#505050";
export var Neutral_7 = "#303030";
export var Neutral_8 = "#0C0C0C";
export var Red_4 = "#DC4343";
export var alpha_Neutral_8_08 = "rgba(12, 12, 12, 0.08)";
export var breakpointSM = "768px";
export var breakpoints = "\"@theme/breakpoints.module.css\"";
export var colors = "\"@theme/colors.module.css\"";
export var style_component = "MainMenu-module--style_component--b9890 typography-module--typeface_base--8d193 typography-module--Interface_SemiBold_M--61495";
export var style_divider = "MainMenu-module--style_divider--727bb";
export var style_emailItem = "MainMenu-module--style_emailItem--41d16";
export var style_featuredItem = "MainMenu-module--style_featuredItem--d5a75";
export var style_heading = "MainMenu-module--style_heading--6e1eb typography-module--Heading_Bold_24--f0295";
export var style_item = "MainMenu-module--style_item--b4ad7";
export var style_link = "MainMenu-module--style_link--1a9c0";
export var style_list = "MainMenu-module--style_list--bebe8";
export var style_notification = "MainMenu-module--style_notification--61676";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "\"@theme/typography.module.css\"";
export var white = "#FFFFFF";