import React, { FunctionComponent } from 'react'
import {
  createMemorySource,
  createHistory,
  LocationProvider
} from '@reach/router'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import AppLayout from './AppLayout'

const source = createMemorySource('/')
const history = createHistory(source)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity
    }
  }
})

const storiesConfig = {
  title: 'Layout components/AppLayout',
  component: AppLayout,
  parameters: {
    layout: 'fullscreen'
  },
  decorators: [
    (Story: FunctionComponent) => (
      <QueryClientProvider client={queryClient}>
        <LocationProvider history={history}>
          <Story />
        </LocationProvider>
      </QueryClientProvider>
    )
  ]
}

export { AppLayout, storiesConfig }
