import React, { FunctionComponent } from 'react'

import { ErrorMessage } from '@components/redesign/ErrorMessage'

import { IFormLayoutProps } from './FormLayout.types'

import { style_content, style_component } from './FormLayout.module.css'

const FormLayout: FunctionComponent<IFormLayoutProps> = ({
  error,
  children,
  onSubmit
}) => (
  <form
    className={style_component}
    onSubmit={onSubmit}
    data-testid="FormLayout"
  >
    {error && <ErrorMessage message={error} />}
    <div className={style_content}>{children}</div>
  </form>
)

export default FormLayout
