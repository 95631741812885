import React from 'react'

const LinkedIn = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.5 1.60294V14.3971C15.5 14.6896 15.3838 14.9701 15.177 15.177C14.9701 15.3838 14.6896 15.5 14.3971 15.5H1.60294C1.31042 15.5 1.02989 15.3838 0.823044 15.177C0.616202 14.9701 0.5 14.6896 0.5 14.3971V1.60294C0.5 1.31042 0.616202 1.02989 0.823044 0.823044C1.02989 0.616202 1.31042 0.5 1.60294 0.5L14.3971 0.5C14.6896 0.5 14.9701 0.616202 15.177 0.823044C15.3838 1.02989 15.5 1.31042 15.5 1.60294ZM4.91176 6.23529H2.70588V13.2941H4.91176V6.23529ZM5.11029 3.80882C5.11146 3.64197 5.07974 3.47651 5.01696 3.32191C4.95418 3.16731 4.86156 3.02659 4.7444 2.90779C4.62723 2.78898 4.48781 2.69442 4.3341 2.6295C4.18038 2.56458 4.01539 2.53057 3.84853 2.52941H3.80882C3.4695 2.52941 3.14408 2.66421 2.90414 2.90414C2.66421 3.14408 2.52941 3.4695 2.52941 3.80882C2.52941 4.14814 2.66421 4.47357 2.90414 4.7135C3.14408 4.95344 3.4695 5.08824 3.80882 5.08824C3.97569 5.09234 4.14173 5.06353 4.29746 5.00344C4.45319 4.94335 4.59555 4.85317 4.71642 4.73804C4.83728 4.62291 4.93427 4.4851 5.00186 4.33247C5.06944 4.17985 5.10629 4.0154 5.11029 3.84853V3.80882ZM13.2941 9.00588C13.2941 6.88382 11.9441 6.05882 10.6029 6.05882C10.1638 6.03684 9.72659 6.13037 9.33489 6.33009C8.94319 6.52981 8.6107 6.82874 8.37059 7.19706H8.30882V6.23529H6.23529V13.2941H8.44118V9.53971C8.40929 9.15519 8.53041 8.77362 8.77823 8.47789C9.02605 8.18217 9.38054 7.99616 9.76471 7.96029H9.84853C10.55 7.96029 11.0706 8.40147 11.0706 9.51324V13.2941H13.2765L13.2941 9.00588Z"
      fill="white"
    />
  </svg>
)

export default LinkedIn
