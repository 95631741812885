import React, { useMemo } from 'react'
import { navigate } from 'gatsby'
import { sampleSize } from 'lodash'

import { AuthButton } from '@containers/AuthButton'
import {
  WorkWithTopBrands,
  GlassdoorMostLovedCompany,
  NotFindingTheRightJob,
  ReceiveRelevantJobOpportunities,
  DidYouKnow
} from '@components/redesign/AdditionalPanels'
import { GetStartedCard } from '@components/redesign/GetStartedCard'
import { JobCardList } from '@components/redesign/JobCardList'
import { JobResultsPanel } from '@components/redesign/JobResultsPanel'
import { JobSearch } from '@components/redesign/JobSearch'
import { TwoColumnListLoader } from '@components/redesign/TwoColumnLayout/TwoColumnListLoader'

import { useAuthContext } from '@hooks/useAuthContext'
import { useAuthScreensContext } from '@hooks/useAuthScreensContext'
import { useJobApplicationsContext } from '@hooks/useJobApplicationsContext'
import { useJobsContext } from '@hooks/useJobsContext'
import { useMediaQuery } from '@hooks/useMediaQuery'
import { size } from '@utils/helpers'

import { breakpointSM } from '@theme/breakpoints.module.css'

const JobCardListContainer = () => {
  const isDesktop = useMediaQuery(`(min-width: ${breakpointSM})`)
  const { user } = useAuthContext()
  const { getIsSuccessfulApplication } = useJobApplicationsContext()

  const {
    jobs,
    filteredJobs,
    searchValue,
    isSearchApplied,
    selectedJob
  } = useJobsContext()

  const { showLoginForm, showCreateAccountForm } = useAuthScreensContext()

  const handleCardClick = job => {
    navigate(`/jobs/${job?.slug?.toLowerCase()}/`)
  }

  const isJobsEmpty = size(jobs) === 0
  const randomJobs = sampleSize(jobs, 8)
  const jobListValue = filteredJobs?.length > 0 ? filteredJobs : randomJobs

  const renderGetStartedCardOnMobile = useMemo(
    () =>
      !selectedJob && !isDesktop && !user ? (
        <GetStartedCard
          text="Get unlimited access to exclusive remote jobs for free."
          ButtonEnhancer={AuthButton}
          showLoginForm={showLoginForm}
          showCreateAccountForm={showCreateAccountForm}
        />
      ) : (
        undefined
      ),
    [isDesktop, selectedJob, showCreateAccountForm, showLoginForm, user]
  )

  const panels = [
    <GlassdoorMostLovedCompany key="GlassdoorMostLovedCompany" />,
    <WorkWithTopBrands key="WorkWithTopBrands" />,
    <NotFindingTheRightJob
      key="NotFindingTheRightJob"
      buttonContainer={AuthButton}
    />,
    <ReceiveRelevantJobOpportunities
      key="ReceiveRelevantJobOpportunities"
      buttonContainer={AuthButton}
    />,
    <DidYouKnow key="DidYouKnow" />
  ]

  return (
    <>
      <JobSearch hideOnMobile />
      <JobResultsPanel
        searchValue={searchValue}
        isSearchApplied={isSearchApplied}
        resultsLength={size(filteredJobs)}
      />
      {isJobsEmpty ? (
        <TwoColumnListLoader />
      ) : (
        <JobCardList
          jobs={jobListValue}
          jobsNavigate={handleCardClick}
          selectedJob={selectedJob}
          panels={panels}
          getIsSuccessfulApplication={getIsSuccessfulApplication}
          paddingBottom={Boolean(renderGetStartedCardOnMobile)}
        />
      )}
      {renderGetStartedCardOnMobile}
    </>
  )
}

export default JobCardListContainer
