import React from 'react'

import { squant } from '@theme/colors.module.css'

const File = props => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" {...props}>
    <path
      d="M27 31.5H9C8.17158 31.5 7.5 30.8284 7.5 30L7.5 6C7.5 5.17157 8.17157 4.5 9 4.5L20.3446 4.5C20.7625 4.5 21.1614 4.67429 21.4453 4.98089L28.1006 12.1687C28.3574 12.446 28.5 12.8099 28.5 13.1878L28.5 30C28.5 30.8284 27.8284 31.5 27 31.5Z"
      stroke={squant}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.5 13.5L21 13.5C20.1716 13.5 19.5 12.8284 19.5 12L19.5 4.5"
      stroke={squant}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default File
