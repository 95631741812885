import {
  style_password,
  style_checkbox,
  style_toggle
} from './InputFieldNew.module.css'

export const availableTypes = [
  'text',
  'button',
  'checkbox',
  'color',
  'date',
  'datetime',
  'email',
  'file',
  'hidden',
  'image',
  'month',
  'number',
  'password',
  'radio',
  'range',
  'reset',
  'search',
  'submit',
  'tel',
  'time',
  'url',
  'week',
  'toggle' // This is a custom style for a checkbox type
]

export const mapValidPrefixTypes = [
  'text',
  'button',
  'date',
  'datetime',
  'email',
  'file',
  'month',
  'number'
]

export const typesClassNames = {
  checkbox: style_checkbox,
  toggle: style_toggle,
  password: style_password
}
