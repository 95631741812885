/* eslint-disable @typescript-eslint/no-explicit-any */

import { ReactNode, createElement } from 'react'
import slugify, { Options } from '@sindresorhus/slugify'
import { format } from 'fecha'

import { NOTIFICATION_TYPES } from '@config/constants'

import {
  IGetNotificationMessageArgs,
  IFakeLocalStorage,
  GetAuthErrorMessageArg,
  MapAlternateFunctionParam
} from './helpers.types'

export const MAX_NUMBER_OF_RETRIES = 3

export function size (collection?: any) {
  if (collection == null) {
    return 0
  }

  if (Array.isArray(collection) || typeof collection === 'string') {
    return collection.length
  }

  return Object.keys(collection).length
}

export const isSSR: boolean = typeof window === 'undefined'

const noop = () => {}
const fakeLocalStorage: IFakeLocalStorage = {
  getItem: noop,
  setItem: noop,
  removeItem: noop
}

export function getLocalStorage (): IFakeLocalStorage | Storage {
  return isSSR ? fakeLocalStorage : window.localStorage
}

export function isPlainObject (value: any) {
  return Object.prototype.toString.call(value) === '[object Object]'
}

/**
 * Takes a function to call for odd elements, and a function to call for even
 * elements in our array.
 *
 * The odd items are literal strings, and the even parts are the stuff between the brackets {{x}}.
 */
export function mapAlternate (
  array: any[],
  fn1: MapAlternateFunctionParam,
  fn2: MapAlternateFunctionParam,
  thisArg: any = null
) {
  let targetFn = fn1
  const output: any[] = []

  for (let i = 0; i < array.length; i++) {
    output[i] = targetFn.call(thisArg, array[i], i, array)
    // Toggle between the two functions
    targetFn = targetFn === fn1 ? fn2 : fn1
  }

  return output
}

export function filledObjectProps (object: any) {
  if (typeof object !== 'object') {
    return null
  }

  return Object.keys(object).reduce((retObj, field) => {
    const value = object[field]

    // Filter out "null" and "undefined" values
    if (value != null) {
      retObj[field] = value
    }

    return retObj
  }, {})
}

export function arrayUniqueByKey (array: any[], key: string) {
  return [...new Map(array.map(item => [item[key], item])).values()]
}

function isValidDate (date: Date | string) {
  return date instanceof Date && !isNaN(Number(date))
}

export function formatDate (
  date: string | Date,
  dateFormat = 'D / MMM / YYYY, h:mm a'
) {
  if (isNaN(Date.parse(date?.toString()))) {
    return undefined
  }

  if (!(date instanceof Date)) {
    date = new Date(date)
  }

  return format(date, dateFormat)
}

export function isDateNewerThanSpecifiedTime (date: Date | string, ms: number) {
  const targetDate = isValidDate(date) ? (date as Date) : new Date(date)
  return targetDate.getTime() + ms > new Date().getTime()
}

export function nameToSlug (
  name: string,
  options: Options = {
    separator: '-',
    customReplacements: [
      ['&', ' and '],
      ['@', ' at '],
      ['#', ' sharp '],
      ['+', ' plus ']
    ]
  }
) {
  return slugify(name, options)
}

export function throttle (func: FunctionConstructor, duration: number) {
  let shouldWait = false
  return function (...args) {
    if (!shouldWait) {
      func(...args)
      shouldWait = true
      setTimeout(function () {
        shouldWait = false
      }, duration)
    }
  }
}

export const emailValidationPattern = {
  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  message: 'Invalid email address.'
}

export function parseVideoUrl (url: string) {
  // - Supported YouTube URL formats:
  //   - http://www.youtube.com/watch?v=R6NUFRNEai4
  //   - http://youtu.be/R6NUFRNEai4
  //   - https://youtube.googleapis.com/v/R6NUFRNEai4
  //   - https://www.youtube.com/embed/R6NUFRNEai4
  // - Supported Vimeo URL formats:
  //   - http://vimeo.com/25451551
  //   - http://player.vimeo.com/video/25451551
  // - Also supports relative URLs:
  //   - //player.vimeo.com/video/25451551

  url.match(
    /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/
  )

  const videoId = RegExp.$6

  let service
  let poster

  if (RegExp.$3.indexOf('youtu') > -1) {
    service = 'youtube'
    poster = `//img.youtube.com/vi/${videoId}/maxresdefault.jpg`
  } else if (RegExp.$3.indexOf('vimeo') > -1) {
    service = 'vimeo'
  }

  return {
    videoId,
    service,
    poster
  }
}

export const isNetworkError = (error: Error | null) =>
  (error?.message || error) === 'Network Error'

export const scrollToTop = (): void => {
  window?.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}

export const getNotificationMessage = ({
  notification
}: IGetNotificationMessageArgs): string | undefined => {
  switch (notification) {
    case NOTIFICATION_TYPES.PROFILE_UPDATED.name:
      return 'Profile updated successfully.'
    case NOTIFICATION_TYPES.SETTINGS_UPDATED.name:
      return 'Settings updated successfully.'
    case NOTIFICATION_TYPES.JOB_PREFERENCES.name:
      return 'Add your job preferences to get matched with jobs that fit you best.'
    case NOTIFICATION_TYPES.UPLOAD_RESUME.name:
      return 'You can now upload a CV to your profile. This will help us make better job recommendations for you.'
    default:
      return undefined
  }
}

export const timeFormatter = (
  value: number,
  unit: string,
  suffix: string
): string =>
  unit === 'second'
    ? 'Less than a minute ago'
    : value + ' ' + unit + (value > 1 ? 's' : '') + ' ' + suffix

export const retry = async (
  fn: () => void,
  errorMessage?: string,
  retriesLeft: number = MAX_NUMBER_OF_RETRIES
) => {
  try {
    return await fn()
  } catch (error) {
    if (retriesLeft > 1) {
      console.warn(`Attempt: ${retriesLeft - 1}, ${errorMessage || ''} `, error)
      return await retry(fn, errorMessage, retriesLeft - 1)
    } else {
      if (errorMessage) {
        console.error(errorMessage, error)
      }
      throw error
    }
  }
}

export const generateRandomBetween = (
  min: number,
  max: number,
  exclude: number[]
): number => {
  let randomNumber = Math.floor(Math.random() * (max - min)) + min

  if (exclude.includes(randomNumber)) {
    randomNumber = generateRandomBetween(min, max, exclude)
  }

  return randomNumber
}

export const getAuthErrorMessage = (
  error: GetAuthErrorMessageArg
): string | undefined => {
  if (typeof error === 'string') {
    return error
  }

  if (typeof error?.name === 'string') {
    return error?.name
  }

  if (Array.isArray(error?.name) && typeof error?.name[0] === 'string') {
    return error?.name[0]
  }

  return undefined
}

export const replacePlaceholders = (
  errorRes = '',
  replacements: { [placeholder: string]: ReactNode } = {}
): ReactNode => {
  if (!errorRes) {
    return null
  }
  const splitStrings = errorRes.split(/\{\{.*?\}\}/)
  const placeholders = Array.from(errorRes.matchAll(/\{\{.*?\}\}/g)).map(
    match => match[0]
  )

  const jsxElements: ReactNode[] = []

  placeholders.forEach((placeholder, index) => {
    if (splitStrings[index]) {
      jsxElements.push(
        createElement('span', { key: index + 'text' }, splitStrings[index])
      )
    }

    if (replacements[placeholder]) {
      jsxElements.push(replacements[placeholder])
    }
  })

  if (splitStrings[placeholders.length]) {
    jsxElements.push(
      createElement(
        'span',
        { key: 'end-text' },
        splitStrings[placeholders.length]
      )
    )
  }

  return jsxElements
}
