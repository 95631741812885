import React from 'react'

import { Neutral_6 } from '@theme/colors.module.css'

const MenuHamburger = ({
  strokeColor = Neutral_6,
  strokeWidth = 1.5,
  width = 12,
  height = 10,
  ...props
}) => (
  <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.33337 8.33331H10.6667"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.33337 5H10.6667"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.33337 1.66669H10.6667"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default MenuHamburger
