import React from 'react'

import { Neutral_1 } from '@theme/colors.module.css'

const ArrowInclinedRight = ({ stroke = Neutral_1, ...props }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.83398 10.1668L10.1673 1.8335"
      stroke={Neutral_1}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.5 1.8335H10.1667V8.50016"
      stroke={Neutral_1}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ArrowInclinedRight
