// extracted by mini-css-extract-plugin
export var Body_Medium_M = "typography-module--Body_Medium_M--7b04f";
export var Body_Medium_S = "typography-module--Body_Medium_S--0eee9";
export var Heading_Bold_28 = "typography-module--Heading_Bold_28--6ae56";
export var Neutral_4 = "#8F8F8F";
export var Neutral_6 = "#505050";
export var colors = "'@theme/colors.module.css'";
export var style_centered = "ApplicationHistoryLayout-module--style_centered--79cf8";
export var style_component = "ApplicationHistoryLayout-module--style_component--f0814 typography-module--typeface_base--8d193";
export var style_content = "ApplicationHistoryLayout-module--style_content--24d15";
export var style_description = "ApplicationHistoryLayout-module--style_description--f9c7c typography-module--Body_Medium_M--7b04f";
export var style_footer = "ApplicationHistoryLayout-module--style_footer--d5eab typography-module--Body_Medium_S--0eee9";
export var style_heading = "ApplicationHistoryLayout-module--style_heading--bb331 typography-module--Heading_Bold_28--6ae56";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "\"@theme/typography.module.css\"";