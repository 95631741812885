// extracted by mini-css-extract-plugin
export var Neutral_8 = "#0C0C0C";
export var boneChilling = "#E1F4F2";
export var breakpointSM = "768px";
export var breakpoints = "\"@theme/breakpoints.module.css\"";
export var colors = "\"@theme/colors.module.css\"";
export var fs13_fw600 = "typography-module--fs13_fw600--ee3e2";
export var fs18_fw700 = "typography-module--fs18_fw700--08853";
export var soothingWhite = "#E2E2E3";
export var style_component = "UploadCV-module--style_component--a2fe6 typography-module--typeface_base--8d193";
export var style_dragging = "UploadCV-module--style_dragging--cb3b9";
export var style_dropArea = "UploadCV-module--style_dropArea--dc16a";
export var style_heading = "UploadCV-module--style_heading--2462c typography-module--fs18_fw700--08853";
export var style_infoLine = "UploadCV-module--style_infoLine--48a5e typography-module--fs13_fw600--ee3e2";
export var style_recommended = "UploadCV-module--style_recommended--b570d";
export var style_subtitle = "UploadCV-module--style_subtitle--96a9e typography-module--fs13_fw600--ee3e2";
export var style_tips = "UploadCV-module--style_tips--48fa6 typography-module--fs13_fw600--ee3e2";
export var style_tipsButton = "UploadCV-module--style_tipsButton--eaa82";
export var style_title = "UploadCV-module--style_title--5ee95 typography-module--fs18_fw700--08853";
export var turquish = "#01A390";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "\"@theme/typography.module.css\"";