import React, { useState, useEffect, FunctionComponent } from 'react'
import { navigate } from 'gatsby'
import { useCookies } from 'react-cookie'

import UTMParamsContext from './UTMParamsContext'
import {
  objectIsNull,
  getUTMParamsFromCookies,
  getUTMParamsFromQueryParams,
  getUTMParamsFromLocalStorage,
  saveUTMParamsOnLocalStorage,
  removeUTMParamsFromCookies,
  removeUTMParamsFromLocalStorage,
  removeUTMParamsFromQueryParams
} from './UTMParamsHelpers'

import {
  IUTMParamsProviderProps,
  IUTMParams
} from './useUTMParamsContext.types'

/*
 * Provider created to share UTM parameters between pages.
 * This Provider checks for UTM params from URL Query Params,
 * LocalStorage, and Cookies.
 *
 * On initial render, this Provider check if the URL Query Params
 * contains UTM params, and save them on LocalStorage to be used
 * when create/update a profile.
 *
 * If neither URL Query Params nor LocalStorage have UTM Params,
 * the Provider will get the UTM Params from cookies.
 */

const UTMParamsProvider: FunctionComponent<IUTMParamsProviderProps> = ({
  children
}) => {
  const [utmParams, setUTMParams] = useState<IUTMParams>({})

  const [cookies, , removeCookies] = useCookies([
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
    'utm_referral'
  ])

  const clearUtmParams = () => {
    const params = removeUTMParamsFromQueryParams()
    navigate(`${window.location.pathname}${params ? `?${params}` : params}`)
    removeUTMParamsFromLocalStorage()
    removeUTMParamsFromCookies(removeCookies)
    setUTMParams({})
  }

  useEffect(() => {
    const urlUTMParams = getUTMParamsFromQueryParams()
    const localStorageUTMParams = getUTMParamsFromLocalStorage()
    const cookiesUTMParams = getUTMParamsFromCookies(cookies)

    if (!objectIsNull(urlUTMParams)) {
      setUTMParams(urlUTMParams)
      saveUTMParamsOnLocalStorage(urlUTMParams)
    } else if (!objectIsNull(localStorageUTMParams)) {
      setUTMParams(localStorageUTMParams)
    } else if (!objectIsNull(cookiesUTMParams)) {
      setUTMParams(cookiesUTMParams)
    }
  }, [cookies])

  return (
    <UTMParamsContext.Provider value={{ utmParams, clearUtmParams }}>
      {children}
    </UTMParamsContext.Provider>
  )
}

export default UTMParamsProvider
