// extracted by mini-css-extract-plugin
export var Interface_SemiBold_M = "typography-module--Interface_SemiBold_M--61495";
export var Interface_SemiBold_XL = "typography-module--Interface_SemiBold_XL--876a5";
export var Neutral_7 = "#303030";
export var Neutral_8 = "#0C0C0C";
export var blueDiamond = "#0A66C2";
export var breakpointSM = "768px";
export var breakpointXS = "480px";
export var breakpoints = "\"@theme/breakpoints.module.css\"";
export var colors = "\"@theme/colors.module.css\"";
export var style_component = "GetStartedCard-module--style_component--ccd12 typography-module--typeface_base--8d193";
export var style_containerButton = "GetStartedCard-module--style_containerButton--1d9dc typography-module--Interface_SemiBold_M--61495";
export var style_containerText = "GetStartedCard-module--style_containerText--2d190";
export var style_text = "GetStartedCard-module--style_text--b42fc typography-module--Interface_SemiBold_XL--876a5";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "\"@theme/typography.module.css\"";