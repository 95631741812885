// extracted by mini-css-extract-plugin
export var Interface_SemiBold_S = "typography-module--Interface_SemiBold_S--af7a9";
export var Neutral_8 = "#0C0C0C";
export var alpha_Neutral_8_20 = "rgba(12, 12, 12, 0.2)";
export var alpha_Neutral_8_50 = "rgba(12, 12, 12, 0.5)";
export var alpha_Neutral_8_80 = "rgba(12, 12, 12, 0.8)";
export var alpha_hornetSting_10 = "rgba(255, 0, 53, 0.1)";
export var alpha_white_10 = "rgba(255, 255, 255, 0.1)";
export var alpha_white_20 = "rgba(255, 255, 255, 0.2)";
export var alpha_white_40 = "rgba(255, 255, 255, 0.4)";
export var alpha_white_50 = "rgba(255, 255, 255, 0.5)";
export var boneChilling = "#E1F4F2";
export var coldMorning = "#E5E5E5";
export var colors = "\"@theme/colors.module.css\"";
export var fs11_fw600 = "typography-module--fs11_fw600--fce4e";
export var fs13_fw600 = "typography-module--fs13_fw600--ee3e2";
export var hornetSting = "#FF0035";
export var jadeMountain = "#2FC3A9";
export var mediumCandyAppleRed = "#E50030";
export var niagara = "#29A892";
export var quicksilver = "#A6A6A6";
export var ravenBlack = "#3D3D3D";
export var soothingWhite = "#E2E2E3";
export var squant = "#666666";
export var style_block = "Button-module--style_block--d4008";
export var style_block_link = "Button-module--style_block_link--7d7ef";
export var style_block_link_inner = "Button-module--style_block_link_inner--d9d88";
export var style_component = "Button-module--style_component--de349 typography-module--typeface_base--8d193";
export var style_default = "Button-module--style_default--18f9b";
export var style_default_outlined = "Button-module--style_default_outlined--43dcd";
export var style_href = "Button-module--style_href--08b16";
export var style_link = "Button-module--style_link--58e12";
export var style_outlined = "Button-module--style_outlined--438bc";
export var style_pill = "Button-module--style_pill--1e64e";
export var style_primary = "Button-module--style_primary--44cad";
export var style_primary_outlined = "Button-module--style_primary_outlined--eb14d";
export var style_secondary = "Button-module--style_secondary--9e3c8";
export var style_secondary_outlined = "Button-module--style_secondary_outlined--1ba77";
export var style_size_extra_small = "Button-module--style_size_extra_small--bf16c typography-module--fs11_fw600--fce4e";
export var style_size_large = "Button-module--style_size_large--2e950 typography-module--fs13_fw600--ee3e2";
export var style_size_normal = "Button-module--style_size_normal--a3ca4 typography-module--Interface_SemiBold_S--af7a9";
export var style_size_small = "Button-module--style_size_small--97800 typography-module--fs11_fw600--fce4e";
export var style_transparent = "Button-module--style_transparent--863ab";
export var style_transparent_outlined = "Button-module--style_transparent_outlined--74bd8";
export var style_uppercase = "Button-module--style_uppercase--bc21d";
export var style_warning = "Button-module--style_warning--45347";
export var style_warning_outlined = "Button-module--style_warning_outlined--46b8d";
export var style_whiteBackground = "Button-module--style_whiteBackground--cbb14";
export var turquish = "#01A390";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "\"@theme/typography.module.css\"";
export var white = "#FFFFFF";