import React, { FunctionComponent, useEffect, useState } from 'react'

import { AuthModalContainer } from '@containers/AuthModalContainer'
import { SuccessRegistration } from '@components/redesign/SuccessRegistration'
import { WhatToDo } from '@components/redesign/WhatToDo'

import AuthScreensContext, { initialState } from './AuthScreensContext'
import { TRIGGERS } from './useAuthScreensContext.constants'

import { IAuthScreensProvider } from './useAuthScreensContext.types'

const AuthScreensProvider: FunctionComponent<IAuthScreensProvider> = ({
  children
}) => {
  const [state, setState] = useState(initialState)
  const [isAuthScreensVisible, setAuthScreensVisible] = useState(false)
  const [
    isSuccessRegistrationModalVisible,
    setSuccessRegistrationModalVisible
  ] = useState(false)
  const [isWhatToDoModalVisible, setWhatToDoModalVisible] = useState(false)

  const handleCloseAuthModals = () => {
    setAuthScreensVisible(false)
    setSuccessRegistrationModalVisible(false)
    setWhatToDoModalVisible(false)
  }

  const handleSuccessRegistrationClick = () => {
    const { AuthScreensTrigger } = state
    if (AuthScreensTrigger === TRIGGERS.JOB_APPLICATION) {
      handleCloseAuthModals?.()
    } else {
      setWhatToDoModalVisible(true)
      setSuccessRegistrationModalVisible(false)
    }
  }

  const handleWhatToDoClick = () => {
    handleCloseAuthModals?.()
  }

  const handleLoginCallback = () => {
    handleCloseAuthModals?.()
  }

  const handleAccountCreationCallback = () => {
    setSuccessRegistrationModalVisible(true)
  }

  const showLoginForm = props => {
    setState({
      ...initialState,
      ...props,
      isCreateAccountFormVisible: false
    })
    setAuthScreensVisible(true)
  }

  const showCreateAccountForm = props => {
    setState({
      ...initialState,
      ...props,
      isCreateAccountFormVisible: true
    })
    setAuthScreensVisible(true)
  }

  const resetAuthScreensState = () => {
    setState(initialState)
  }

  const handleCloseAuthScreens = () => {
    handleCloseAuthModals()
    resetAuthScreensState()
  }

  useEffect(() => {
    const isModalVisible =
      isWhatToDoModalVisible ||
      isSuccessRegistrationModalVisible ||
      isAuthScreensVisible
    const updateBodyOverflow = (visibility?: boolean) => {
      document.body.style.overflowY = visibility ? 'hidden' : ''
    }
    if (isModalVisible) {
      updateBodyOverflow(true)
    }
    return () => updateBodyOverflow(false)
  }, [
    isWhatToDoModalVisible,
    isSuccessRegistrationModalVisible,
    isAuthScreensVisible
  ])

  return (
    <AuthScreensContext.Provider
      value={{
        ...state,
        isAuthScreensVisible,
        showLoginForm,
        showCreateAccountForm,
        handleCloseAuthScreens,
        resetAuthScreensState
      }}
    >
      {isWhatToDoModalVisible && <WhatToDo onClose={handleWhatToDoClick} />}
      {isSuccessRegistrationModalVisible && (
        <SuccessRegistration onClick={handleSuccessRegistrationClick} />
      )}
      {isAuthScreensVisible && (
        <AuthModalContainer
          onClose={handleCloseAuthScreens}
          handleLoginCallback={handleLoginCallback}
          handleAccountCreationCallback={handleAccountCreationCallback}
          {...state}
        />
      )}
      {children}
    </AuthScreensContext.Provider>
  )
}

export { AuthScreensProvider }
