import React, { forwardRef } from 'react'

import { IInputTextProps } from './InputText.types'

import {
  disabled,
  input,
  invalid as invalidStyle
} from './InputText.module.css'

const InputText = forwardRef<HTMLInputElement, IInputTextProps>(
  (
    { validation, invalid, className, disabled: isDisabled, ...rest },
    inputRef
  ) => (
    <input
      ref={inputRef || validation}
      className={[
        input,
        invalid ? invalidStyle : '',
        isDisabled ? disabled : '',
        className
      ].join(' ')}
      type="text"
      {...rest}
    />
  )
)

export default InputText
