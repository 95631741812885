import { createContext } from 'react'

import { IAuthScreensContext } from './useAuthScreensContext.types'

export const initialState = {
  AuthScreensTrigger: undefined,
  handleAfterAuthenticate: () => {},
  isAuthScreensVisible: false,
  canBeClosed: true,
  isCreateAccountFormVisible: false,
  showLoginForm: () => {},
  showCreateAccountForm: () => {},
  handleCloseAuthScreens: () => {},
  resetAuthScreensState: () => {}
}

const AuthScreensContext = createContext<IAuthScreensContext>(initialState)

export default AuthScreensContext
