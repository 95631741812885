/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import { useSiteMetadata } from '@hooks/useSiteMetadata'

function SEO ({
  description = '',
  lang = 'en',
  meta = [],
  keywords = [],
  title,
  indexed = false
}) {
  const {
    title: defaultTitle,
    description: defaultDescription,
    author,
    image
  } = useSiteMetadata()

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${process.env.GATSBY_SITE_URL}/${image}`,
    author
  }

  return (
    <Helmet
      defer={false}
      htmlAttributes={{ lang }}
      title={title}
      meta={[
        {
          name: `description`,
          content: seo.description
        },
        {
          name: `image`,
          content: seo.image
        },
        {
          property: `og:title`,
          content: seo.title
        },
        {
          property: `og:description`,
          content: seo.description
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:image`,
          content: seo.image
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`
        },
        {
          name: `twitter:site`,
          content: `@xteam`
        },
        {
          name: `twitter:creator`,
          content: seo.author
        },
        {
          name: `twitter:title`,
          content: seo.title
        },
        {
          name: `twitter:description`,
          content: seo.description
        },
        {
          name: `twitter:image`,
          content: seo.image
        }
      ]
        .concat(
          keywords.length > 0
            ? {
              name: 'keywords',
              content: keywords.join(', ')
            }
            : []
        )
        .concat(
          indexed
            ? []
            : {
              name: 'robots',
              content: 'noindex,nofollow'
            }
        )
        .concat(meta)}
    />
  )
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  indexed: PropTypes.bool
}

export default SEO
