import React from 'react'

import { Button } from '@components/redesign/Button'
import { ModalBox } from '@components/redesign/Modal/ModalBox'

import { ILockedFeaturesProps } from './LockedFeatures.types'

import {
  style_component,
  style_title,
  style_message,
  style_button,
  style_extra
} from './LockedFeatures.module.css'

const LockedFeatures = ({ handleContinue }: ILockedFeaturesProps) => (
  <section className={style_component} data-testid="LockedFeatures">
    <ModalBox color="dark">
      <h1 className={style_title}>
        Upload your
        <br />
        resume/CV first.
      </h1>
      <p className={style_message}>
        And get the most powerful job application experience <br />
        X-Team has ever had with hand-picked job matchings.
      </p>
      <Button
        color="secondary"
        outlined
        block
        onClick={handleContinue}
        testid="LockedFeatures-continue"
        className={style_button}
      >
        Continue
      </Button>
      <p className={style_extra}>
        Your data will never be shared outside of X-Team.
      </p>
    </ModalBox>
  </section>
)

export default LockedFeatures
