import React from 'react'

const Game = props => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.7 1H2.3C1.58203 1 1 1.59695 1 2.33333V8.99996C1 9.73634 1.58203 10.3333 2.3 10.3333H12.7C13.418 10.3333 14 9.73634 14 8.99996V2.33333C14 1.59695 13.418 1 12.7 1Z"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.89844 13H10.0984"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.50391 10.3333V12.9999"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Game
