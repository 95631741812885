import { useState } from 'react'

import { IUseModal } from './useModal.types'

const useModal: IUseModal = () => {
  const [isVisible, setIsVisible] = useState(false)

  const toggleModal = () => {
    document.body.style.overflowY = !isVisible ? 'hidden' : ''
    setIsVisible(!isVisible)
  }

  return {
    isVisible,
    toggleModal
  }
}

export default useModal
