// extracted by mini-css-extract-plugin
export var Green_5 = "#2C7757";
export var Interface_Medium_M = "typography-module--Interface_Medium_M--2989f";
export var Neutral_4 = "#8F8F8F";
export var colors = "\"@theme/colors.module.css\"";
export var style_color_default = "ValidationList-module--style_color_default--5d9dc";
export var style_color_success = "ValidationList-module--style_color_success--496fb";
export var style_component = "ValidationList-module--style_component--e244a typography-module--typeface_base--8d193 typography-module--Interface_Medium_M--2989f";
export var style_item = "ValidationList-module--style_item--2b394";
export var style_itemSvgClassName = "ValidationList-module--style_itemSvgClassName--7c4f3";
export var style_list = "ValidationList-module--style_list--159e1";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "\"@theme/typography.module.css\"";