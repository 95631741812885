import React, { FunctionComponent } from 'react'
import CookieConsent from 'react-cookie-consent'

import { cookieBannerName } from './CookieBanner.constants'
import { ICookieBannerProps } from './CookieBanner.type'

import {
  style_acceptButton,
  style_rejectButton,
  style_component,
  style_mainText,
  style_subText
} from './CookieBanner.module.css'

const CookieBanner: FunctionComponent<ICookieBannerProps> = ({
  resetCookieBanner,
  setResetCookieBanner
}) => {
  if (!setResetCookieBanner) {
    return null
  }

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      enableDeclineButton
      declineButtonText="Reject"
      cookieName={cookieBannerName}
      buttonClasses={style_acceptButton}
      declineButtonClasses={style_rejectButton}
      containerClasses={style_component}
      visible={resetCookieBanner ? 'show' : 'byCookieValue'}
      onAccept={() => {
        setResetCookieBanner(true)
      }}
      onDecline={() => {
        setResetCookieBanner(false)
      }}
    >
      <div className={style_mainText} data-testid="CookieBanner-title">
        Your Privacy
      </div>
      <div className={style_subText}>
        We use cookies and other similar technologies to personalize our
        content, measure the effectiveness of our advertising and improve its
        relevance, and provide a better experience. By clicking Accept, you
        agree to the terms set forth in our{' '}
        <u>
          <a
            target="_blank"
            href="https://x-team.com/blog/privacy-policy/"
            rel="noreferrer"
          >
            Cookie Policy
          </a>
        </u>
        . To change your preferences or withdraw your consent, you must update
        your Cookie Preferences.
      </div>
    </CookieConsent>
  )
}

export default CookieBanner
