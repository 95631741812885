import React from 'react'

const Search = ({ strokeColor = '#666666', ...props }) => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.99991 9.00004C7.209 9.00004 8.99983 7.20917 8.99983 5.00002C8.99983 2.79087 7.209 1 4.99991 1C2.79082 1 1 2.79087 1 5.00002C1 7.20917 2.79082 9.00004 4.99991 9.00004Z"
      stroke={strokeColor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0001 9.99996L7.8252 7.82495"
      stroke={strokeColor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Search
