import { createContext } from 'react'

import { IUseAuthContextReturn } from './useAuthContext.types'

export const initialState: IUseAuthContextReturn = {
  loading: true,
  isLoadingUser: false,
  isLoadingProfile: false,
  isUserUpdated: false,
  isProfileUpdated: false,
  user: null,
  profile: null,
  hasUserSignedOut: false,
  reFetchTokenCountdownValue: 0,
  wasActivated: false,
  error: null,
  requiresProfileLink: false,
  updating: false,
  updated: false,
  updateState: () => {},
  updateMe: () => {},
  signIn: () => {},
  signUp: () => {},
  signOut: () => {},
  clearErrors: () => {},
  resendEmailHandler: () => Object,
  connectAccountsEmailSent: false
}

const AuthContext = createContext<IUseAuthContextReturn>(initialState)

export default AuthContext
