// extracted by mini-css-extract-plugin
export var Interface_SemiBold_XL = "typography-module--Interface_SemiBold_XL--876a5";
export var Interface_SemiBold_XS = "typography-module--Interface_SemiBold_XS--e8ce0";
export var Neutral_8 = "#0C0C0C";
export var alpha_Neutral_8_12 = "rgba(12, 12, 12, 0.12)";
export var black = "#000000";
export var bleachedSilk = "#F2F2F2";
export var breakpointSM = "768px";
export var breakpoints = "\"@theme/breakpoints.module.css\"";
export var colors = "'@theme/colors.module.css'";
export var fs11_fw600 = "typography-module--fs11_fw600--fce4e";
export var squant = "#666666";
export var style_bottom = "JobCard-module--style_bottom--27e45";
export var style_card = "JobCard-module--style_card--dc197 typography-module--typeface_base--8d193";
export var style_card_selected = "JobCard-module--style_card_selected--31527";
export var style_header_container = "JobCard-module--style_header_container--c2db5";
export var style_jobLink = "JobCard-module--style_jobLink--7532d";
export var style_name = "JobCard-module--style_name--65d41 typography-module--Interface_SemiBold_XL--876a5";
export var style_nameLink = "JobCard-module--style_nameLink--c10ac";
export var style_selected_jobLink = "JobCard-module--style_selected_jobLink--19cfc";
export var style_skills = "JobCard-module--style_skills--a7987";
export var style_timezoneAndArrangement = "JobCard-module--style_timezoneAndArrangement--a3593 typography-module--Interface_SemiBold_XS--e8ce0";
export var style_viewJobBtn = "JobCard-module--style_viewJobBtn--27da2 typography-module--fs11_fw600--fce4e";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "'@theme/typography.module.css'";
export var white = "#FFFFFF";