import WhatToDo from './WhatToDo'

const storiesConfig = {
  title: 'Shared components/WhatToDo',
  component: WhatToDo,
  parameters: {
    layout: 'fullscreen'
  }
}

export { WhatToDo, storiesConfig }
