import React from 'react'

import { squant } from '@theme/colors.module.css'

const Love = props => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.1518 1.88407C10.883 1.6038 10.5639 1.38146 10.2126 1.22977C9.86141 1.07808 9.48493 1 9.10473 1C8.72453 1 8.34806 1.07808 7.99682 1.22977C7.64558 1.38146 7.32645 1.6038 7.05767 1.88407L6.49986 2.46547L5.94205 1.88407C5.39913 1.3182 4.66278 1.00029 3.89498 1.00029C3.12719 1.00029 2.39084 1.3182 1.84792 1.88407C1.30501 2.44995 1 3.21744 1 4.0177C1 4.81797 1.30501 5.58546 1.84792 6.15134L2.40573 6.73274L6.49986 11L10.594 6.73274L11.1518 6.15134C11.4207 5.87119 11.634 5.53857 11.7796 5.17247C11.9251 4.80638 12 4.41398 12 4.0177C12 3.62143 11.9251 3.22903 11.7796 2.86293C11.634 2.49684 11.4207 2.16422 11.1518 1.88407V1.88407Z"
      stroke={squant}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Love
