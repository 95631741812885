// extracted by mini-css-extract-plugin
export var Interface_SemiBold_S = "typography-module--Interface_SemiBold_S--af7a9";
export var alpha_black_7 = "rgba(0,0,0,0.7)";
export var bleachedSilk = "#F2F2F2";
export var breakpointSM = "768px";
export var breakpoints = "'@theme/breakpoints.module.css'";
export var colors = "'@theme/colors.module.css'";
export var soothingWhite = "#E2E2E3";
export var style_backdrop = "JobSearch-module--style_backdrop--dbb12";
export var style_backdropVisible = "JobSearch-module--style_backdropVisible--df26f";
export var style_inputButton = "JobSearch-module--style_inputButton--01481";
export var style_inputContainer = "JobSearch-module--style_inputContainer--cae14 typography-module--Interface_SemiBold_S--af7a9 typography-module--typeface_base--8d193";
export var style_inputContainerWithIcon = "JobSearch-module--style_inputContainerWithIcon--ff03b";
export var style_searchInput = "JobSearch-module--style_searchInput--9186d";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "'@theme/typography.module.css'";
export var white = "#FFFFFF";