import React, { FunctionComponent } from 'react'

import { Svg, SVGS } from '@components/redesign/Svgs'

import { ILinkedInButtonProps } from './LinkedInButton.types'

import { style_component } from './LinkedInButton.module.css'

const LinkedInButton: FunctionComponent<ILinkedInButtonProps> = ({
  label = '',
  onClick
}) => {
  if (!onClick) {
    return null
  }

  return (
    <button
      data-testid="LinkedInButton"
      className={style_component}
      onClick={onClick}
      type="button"
    >
      <Svg name={SVGS.LinkedIn} />
      {label}
    </button>
  )
}

export default LinkedInButton
