import { useEffect, useCallback, useReducer } from 'react'

import { useAuthContext } from '@hooks/useAuthContext'
import { useLocalStorage } from '@hooks/useLocalStorage'
import { STORAGE_KEYS, NOTIFICATION_TYPES } from '@config/constants'

import {
  IDismissNotification,
  IUseNotificationBar
} from './useNotificationBar.types'
import { IDismissedNotifications } from '@type/dismissedNotifications'

const dismissedNotificationsInitialValues: IDismissedNotifications = {
  [NOTIFICATION_TYPES.JOB_PREFERENCES.name]: false,
  [NOTIFICATION_TYPES.UPLOAD_RESUME.name]: false
}

const reducer = (state, action) => {
  switch (action.type) {
    case NOTIFICATION_TYPES.JOB_PREFERENCES.name:
      return { ...state, JOB_PREFERENCES: action.payload }
    case NOTIFICATION_TYPES.UPLOAD_RESUME.name:
      return { ...state, UPLOAD_RESUME: action.payload }
    default:
      throw new Error()
  }
}

const useNotificationBar: IUseNotificationBar = () => {
  const { user, profile } = useAuthContext()

  const [notificationsVisibility, dispatch] = useReducer(
    reducer,
    dismissedNotificationsInitialValues
  )

  const {
    storedValue: localStorageDismissed,
    setValue: setLocalStorageDismissed
  } = useLocalStorage(
    STORAGE_KEYS.DISMISSED_NOTIFICATION_BARS,
    dismissedNotificationsInitialValues
  )

  const updateShouldDisplayNotification = useCallback(
    (type, payload) => {
      if (notificationsVisibility[type] !== payload) {
        dispatch({ type, payload })
      }
    },
    [notificationsVisibility]
  )

  const getShouldShowNotification = useCallback(
    (notificationName, shouldViewNotification) =>
      Boolean(
        !localStorageDismissed[notificationName] &&
          user &&
          profile &&
          shouldViewNotification
      ),
    [localStorageDismissed, user, profile]
  )

  // Prompt Applicants to enable Job Preferences Notifications
  useEffect(() => {
    const notificationName = NOTIFICATION_TYPES.JOB_PREFERENCES.name

    const isRateRequirementMatched = !profile?.rateRange

    const shouldViewNotification =
      !profile?.preferredJobTypes?.length ||
      !profile?.preferredSkills?.length ||
      isRateRequirementMatched

    updateShouldDisplayNotification(
      notificationName,
      getShouldShowNotification(notificationName, shouldViewNotification)
    )
  }, [profile, updateShouldDisplayNotification, getShouldShowNotification])

  // Prompt Applicants to Upload Resume
  useEffect(() => {
    const notificationName = NOTIFICATION_TYPES.UPLOAD_RESUME.name
    const shouldShowUploadResumeNotification = !profile?.resume?.id
    updateShouldDisplayNotification(
      notificationName,
      getShouldShowNotification(
        notificationName,
        shouldShowUploadResumeNotification
      )
    )
  }, [profile, updateShouldDisplayNotification, getShouldShowNotification])

  const dismissNotification: IDismissNotification = name => {
    setLocalStorageDismissed({ ...localStorageDismissed, [name]: true })
  }

  const visibleNotifications: Array<string> = Object.keys(
    notificationsVisibility
  ).reduce(
    (acc: Array<string>, cur: string) =>
      notificationsVisibility[cur] ? [...acc, cur] : acc,
    []
  )

  return {
    visibleNotifications,
    dismissNotification
  }
}

export default useNotificationBar
