class APIError extends Error {
  status: number
  statusCode: number
  code: string

  /**
   * Construct a new API Error object with a status code and descriptive
   * message.
   *
   * @param {Number} statusCode The HTTP status code.
   * @param {String} code       The error code (src/constants/ErrorTypes).
   * @param {String} message    The descriptive message.
   */
  constructor (statusCode: number, code: string, message: string) {
    super()
    this.status = statusCode
    this.statusCode = statusCode
    this.code = code
    this.message = message
  }

  static invalidCredentials () {
    return new this(
      400,
      'INVALID_CREDENTIALS',
      'Email address or password invalid.'
    )
  }

  static offlineServer () {
    return new this(
      403,
      'OFFLINE_CONNECTION',
      `Your internet connection seems to be offline. Please try again later.`
    )
  }
}

export default APIError
