/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import * as React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { GatsbyBrowser } from 'gatsby'

import { WrapPageElement } from '@containers/WrapPageElement'

import { AuthProvider } from '@hooks/useAuthContext'
import { UTMParamsProvider } from '@hooks/useUTMParamsContext'

import '@fontsource/open-sans'
import '@fontsource/open-sans/600.css'
import '@fontsource/open-sans/700.css'
import '@fontsource/open-sans/800.css'

import '@fontsource/inter'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'
import '@fontsource/inter/800.css'
import '@fontsource/inter/900.css'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity
    }
  }
})

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element
}) => (
  <QueryClientProvider client={queryClient}>
    <UTMParamsProvider>
      <AuthProvider>{element}</AuthProvider>
    </UTMParamsProvider>
  </QueryClientProvider>
)

export function wrapPageElement ({ element }) {
  const { path = '' } = element?.props || {}
  return <WrapPageElement element={element} path={path} />
}
