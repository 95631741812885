import { useContext } from 'react'

import JobsContext from './JobsContext'

import { IUseJobsContextReturn } from './useJobsContext.types'

const useJobsContext = () =>
  useContext<IUseJobsContextReturn | null>(JobsContext) as IUseJobsContextReturn

export default useJobsContext
