import React, { FunctionComponent } from 'react'

import { Button } from '@components/redesign/Button'
import { ModalBox } from '@components/redesign/Modal/ModalBox'

import { IUpdateCVProps } from './UpdateCV.types'

import {
  style_component,
  style_title,
  style_subtitle,
  style_fileInfo,
  style_infoLine,
  style_warning,
  style_buttons,
  style_secondary
} from './UpdateCV.module.css'

const UpdateCV: FunctionComponent<IUpdateCVProps> = ({
  updatedAt,
  fileName,
  handleConfirmation,
  confirmationText = 'Submit application',
  handleBrowseFile,
  getRootProps = {},
  getInputProps,
  somethingWrong,
  hasUploadError,
  uploadPercent = 0
}) => {
  const title = somethingWrong ? 'Something went wrong.' : 'Update your CV'

  const subtitle = somethingWrong
    ? 'Wrong file format, please try again with another format.'
    : 'Updating your CV could lead to more interviews.'

  const renderFileInfoBlock = () => {
    if (uploadPercent > 0) {
      return <p className={style_infoLine}>Uploading... {uploadPercent}%</p>
    }

    return (
      <>
        {hasUploadError && !somethingWrong && (
          <p className={style_warning}>
            Warning issues were found in the uploaded CV. We recommend you
            follow these{' '}
            <a
              href="https://x-team.com/blog/make-your-x-team-profile-stand-out/"
              target="_blank"
              rel="noreferrer"
            >
              CV guidelines
            </a>{' '}
            to make sure your profile stands out.
          </p>
        )}
        <p className={style_infoLine}>
          <strong>Updated at:</strong> {updatedAt}
        </p>
        <p className={style_infoLine}>
          <strong>File:</strong> {fileName}
        </p>
      </>
    )
  }

  return (
    <section className={style_component} data-testid="UpdateCV">
      <ModalBox>
        <h1 className={style_title}>{title}</h1>
        <p className={style_subtitle}>{subtitle}</p>
        <div {...getRootProps} className={style_fileInfo}>
          {getInputProps && <input {...getInputProps} />}
          {renderFileInfoBlock()}
        </div>
        <div className={style_buttons}>
          <Button
            color="secondary"
            outlined
            className={style_secondary}
            onClick={handleBrowseFile}
            testid="UpdateCV-browse"
          >
            Upload new CV
          </Button>
          <Button block onClick={handleConfirmation} testid="UpdateCV-keep">
            {confirmationText}
          </Button>
        </div>
      </ModalBox>
    </section>
  )
}

export default UpdateCV
