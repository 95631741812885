import axios, { AxiosError, AxiosResponse } from 'axios'

import { APIError } from '@utils/apiError'
import { api, handleAPIError } from '@utils/apiHelpers'
import { isAuthenticatedUser } from '@utils/authHelpers'
import { getLocalStorage } from '@utils/helpers'
import { STORAGE_KEYS } from '@config/constants'

import {
  ISignInArgs,
  ISignUpArgs,
  IAuthData,
  ILinkedInAuthData,
  IAuthResponseData,
  IResetPasswordArgs,
  IResetPasswordResponse,
  IGetUserInfoByTokenData
} from './auth.types'
import { IUser } from '@type/user'

const { AUTH_TOKEN } = STORAGE_KEYS

const localStorage = getLocalStorage()

export const getAuthToken = (): string | null => localStorage[AUTH_TOKEN]

export const initAuthHeader = (token: string | null) => {
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  } else {
    delete axios.defaults.headers.common.Authorization
  }
}

export const setAuthToken = (token: string | null) => {
  if (token) {
    localStorage.setItem(AUTH_TOKEN, token)
  } else {
    localStorage.removeItem(AUTH_TOKEN)
  }

  initAuthHeader(token)
}

export const checkAuth = async () => {
  try {
    return await api.get<IUser>('/users/me')
  } catch (error) {
    return handleAPIError(error as AxiosError)
  }
}

export const signUp = async ({
  password,
  email,
  fullName,
  jobAlertsNotification
}: ISignUpArgs) => {
  try {
    const response = await api.post<ISignUpArgs, IAuthData>(
      '/auth/local/register',
      {
        password,
        email,
        fullName,
        jobAlertsNotification
      }
    )

    const { data } = response

    setAuthToken(data.jwt)

    return {
      ...response,
      data: {
        user: data.user
      }
    } as AxiosResponse<IAuthResponseData>
  } catch (error) {
    return handleAPIError(error as AxiosError)
  }
}

export const signIn = async ({
  identifier,
  password,
  utmParams
}: ISignInArgs) => {
  try {
    const response = await api.post<ISignInArgs, IAuthData>('/auth/local', {
      identifier,
      password,
      referrer: window.location.href,
      utmParams
    })

    const { data } = response

    // Ensure User is of "Authenticated" role
    const userRole = data && data.user && data.user.role
    if (!isAuthenticatedUser(userRole?.type)) {
      throw APIError.invalidCredentials()
    }

    setAuthToken(data.jwt)

    return {
      ...response,
      data: {
        user: data.user
      }
    } as AxiosResponse<IAuthResponseData>
  } catch (error) {
    return handleAPIError(error as AxiosError)
  }
}

export const signOut = () => {
  setAuthToken(null)
}

export const resetPassword = async ({
  password,
  passwordConfirmation,
  code
}: IResetPasswordArgs) => {
  try {
    const response = await api.post<IResetPasswordArgs, IAuthData>(
      '/auth/reset-password',
      {
        password,
        passwordConfirmation,
        code
      }
    )

    const { data } = response

    let ok = false
    if (data.jwt) {
      ok = true
    }

    return {
      ...response,
      data: {
        ok
      }
    } as AxiosResponse<IResetPasswordResponse>
  } catch (error) {
    return handleAPIError(error as AxiosError)
  }
}

export const getUserInfoByToken = async (token: string) => {
  try {
    return await api.get<IGetUserInfoByTokenData>(
      `/profile/user-token/${token}`
    )
  } catch (error) {
    return handleAPIError(error as AxiosError)
  }
}

export const resendConnectAccountEmail = async () => {
  try {
    return await api.get(`/profile/resend-connect-email`)
  } catch (error) {
    return handleAPIError(error as AxiosError)
  }
}

export const getLinkedInCallback = async (queryString, usePhoto, utmParams) => {
  try {
    return await api.get<ILinkedInAuthData>(
      `/auth/linkedin/callback${queryString}${
        usePhoto ? `&usePhoto=${usePhoto}` : ''
      }&utmParams=${JSON.stringify(utmParams)}`
    )
  } catch (error) {
    return handleAPIError(error as AxiosError)
  }
}
