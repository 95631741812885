import { AxiosError, AxiosResponse } from 'axios'

import { api, handleAPIError } from '@utils/apiHelpers'
import { retry } from '@utils/helpers'

import {
  IUnsubscribeArgs,
  IUnsubscribeData,
  IUpdateMeArgs,
  ILegacyRegisterArgs,
  ILegacyRegisterData,
  IPasswordResetArgs,
  IPasswordResetData
} from './profile.types'
import { IParsedResume } from '@type/parsedResume'
import { IProfile } from '@type/profile'

export const fetchMe = async () => {
  try {
    return await api.get<IProfile>(
      '/profiles/me?populate=preferredJobTypes&populate=preferredSkills&populate=resume&populate=image&populate=country'
    )
  } catch (error) {
    return handleAPIError(error as AxiosError)
  }
}

export const unsubscribe = async (token = '') => {
  try {
    return await api.post<IUnsubscribeArgs, IUnsubscribeData>(
      '/profiles/unsubscribe',
      {
        token
      }
    )
  } catch (error) {
    return handleAPIError(error as AxiosError)
  }
}

export const jobAlertsUnsubscribe = async (token = '') => {
  try {
    return await api.post<IUnsubscribeArgs, IUnsubscribeData>(
      '/profiles/unsubscribe',
      {
        token,
        type: 'jobAlerts'
      }
    )
  } catch (error) {
    return handleAPIError(error as AxiosError)
  }
}

export const updateMe = async (body: IUpdateMeArgs) => {
  try {
    return await api.put<IUpdateMeArgs, IProfile>('/profiles/me', {
      ...body
    })
  } catch (error) {
    return handleAPIError(error as AxiosError)
  }
}

export const legacyRegister = async ({
  token,
  password
}: ILegacyRegisterArgs) => {
  try {
    return await api.post<ILegacyRegisterArgs, ILegacyRegisterData>(
      '/profiles/legacy-register',
      {
        token,
        password
      }
    )
  } catch (error) {
    return handleAPIError(error as AxiosError)
  }
}

export const requestPasswordReset = async ({ email }: IPasswordResetArgs) => {
  try {
    return await api.post<IPasswordResetArgs, IPasswordResetData>(
      '/profiles/forgot-password',
      {
        email
      },
      {
        headers: {
          Authorization: ''
        }
      }
    )
  } catch (error) {
    return handleAPIError(error as AxiosError)
  }
}

export const parseProfileResume = async (profileId: number) =>
  await api.post<undefined, IParsedResume>(
    `/profiles/${profileId}/parse-resume`
  )

export const parseProfileResumeWithRetries = async (
  profileId: number
): Promise<AxiosResponse<IParsedResume>> =>
  await retry(async () => await parseProfileResume(profileId))

export const deleteProfileResume = async profileId => {
  try {
    return await api.delete<number>(`/profile/${profileId}/resume`)
  } catch (error) {
    return handleAPIError(error as AxiosError)
  }
}
