import React from 'react'

import { Neutral_8 } from '@theme/colors.module.css'

const AlertOctagon = ({ stroke = Neutral_8, ...props }) => (
  <svg
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.72 3H31.28L43 14.72V31.28L31.28 43H14.72L3 31.28V14.72L14.72 3Z"
      stroke={stroke}
      strokeOpacity="0.6"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23 15V23"
      stroke={stroke}
      strokeOpacity="0.6"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23 31H23.02"
      stroke={stroke}
      strokeOpacity="0.6"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default AlertOctagon
