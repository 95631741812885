import React from 'react'

import { Svg, SVGS } from '@components/redesign/Svgs'

import { availableColors } from './ValidationList.constants'

import { IValidationListProps, IItemType } from './ValidationList.types'

import {
  style_component,
  style_list,
  style_item,
  style_itemSvgClassName,
  style_color_default,
  style_color_success
} from './ValidationList.module.css'
import { Neutral_4, Green_5 } from '@theme/colors.module.css'

const ValidationList = ({ items = [] }: IValidationListProps) => {
  if (items?.length === 0) {
    return null
  }

  const statusConfig = {
    default: {
      className: style_color_default,
      colorValue: Neutral_4
    },
    success: {
      className: style_color_success,
      colorValue: Green_5
    },
    error: {
      className: style_color_default,
      colorValue: Neutral_4
    }
  }

  const renderItem = (item: IItemType = {}, i: number): JSX.Element => {
    const validColorConfig =
      item.status && availableColors.includes(item.status)
        ? statusConfig[item.status]
        : statusConfig.default
    const itemClassName = [style_item, validColorConfig.className].join(' ')

    return (
      <li key={i} className={itemClassName}>
        <Svg
          name={SVGS.Checkmark}
          strokeColor={validColorConfig.colorValue}
          className={style_itemSvgClassName}
        />
        {item.label}
      </li>
    )
  }

  return (
    <div className={style_component}>
      <ul className={style_list} data-testid="ValidationList">
        {items?.map(renderItem)}
      </ul>
    </div>
  )
}

export default ValidationList
