import React, { cloneElement } from 'react'

const AuthButton = ({ children, showAuthScreens }) => {
  const ClonedChildren = () =>
    cloneElement(children, {
      onClick: event => {
        event.preventDefault()
        showAuthScreens?.()
      }
    })

  return <ClonedChildren />
}

export default AuthButton
