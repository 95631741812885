import React, { forwardRef } from 'react'
import { useLocation } from '@reach/router'
import { Link } from 'gatsby'

import { AuthButton } from '@containers/AuthButton'

import {
  style_dialogMenu,
  style_dialogMenuItem,
  style_link,
  style_profileButton
} from './MobileUnauthenticatedHeaderMenu.module.css'

const MobileUnauthenticatedHeaderMenu = ({ showAuthScreens }, ref) => {
  const { pathname } = useLocation()

  return (
    <ul ref={ref} className={style_dialogMenu}>
      <li className={style_dialogMenuItem}>
        <AuthButton
          isCreateAccountFormVisible
          showAuthScreens={showAuthScreens}
        >
          <Link className={style_link} to={`/login/?redirecturl=${pathname}`}>
            <button className={style_profileButton}>SIGN UP / SIGN IN</button>
          </Link>
        </AuthButton>
      </li>
      <li className={style_dialogMenuItem}>
        <Link className={style_link} to="/jobs/">
          Jobs
        </Link>
      </li>
      <li className={style_dialogMenuItem}>
        <a
          className={style_link}
          href="https://x-team.com/blog/"
          target="_blank"
          rel="noreferrer"
        >
          Blog
        </a>
      </li>
      <li className={style_dialogMenuItem}>
        <a
          className={style_link}
          href="https://x-team.com/blog/unleash/"
          target="_blank"
          rel="noreferrer"
        >
          Unleash+
        </a>
      </li>
      <li className={style_dialogMenuItem}>
        <Link className={style_link} to="/faq/">
          FAQ
        </Link>
      </li>
    </ul>
  )
}

export default forwardRef(MobileUnauthenticatedHeaderMenu)
