import React, { FunctionComponent } from 'react'

import placeholder from '@assets/redesign/placeholder.png'
import placeholderFooter from '@assets/redesign/placeholderFooter.png'

import {
  style_component,
  style_placeholder
} from './TwoColumnListLoader.module.css'

const TwoColumnListLoader: FunctionComponent = () => (
  <div className={style_component}>
    <img className={style_placeholder} src={placeholder} alt="Loading Job Ad" />
    <img className={style_placeholder} src={placeholder} alt="Loading Job Ad" />
    <img src={placeholderFooter} alt="Loading Job Ad" />
    <img className={style_placeholder} src={placeholder} alt="Loading Job Ad" />
  </div>
)

export default TwoColumnListLoader
