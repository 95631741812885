import React, { FunctionComponent } from 'react'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'

import { FaqCardList } from '@components/redesign/FaqCardList'

const FaqCardListContainer: FunctionComponent = () => {
  const { pathname } = useLocation()
  const handleNavigateTo = (slug: string) => {
    navigate(`/faq/${slug}/`)
  }
  const [slug] = pathname.match(/faq\/([^/?#]+)/)?.slice(1) || []
  return <FaqCardList selectedCard={slug} navigateTo={handleNavigateTo} />
}

export default FaqCardListContainer
