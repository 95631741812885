import React from 'react'

import { devilsGrass } from '@theme/colors.module.css'

const Stars = props => (
  <svg width="246" height="30" viewBox="0 0 246 30" fill="none" {...props}>
    <path
      fill={devilsGrass}
      d="M0 24.7411V19.24L11.9754 0.399201H16.8712V7.8671H14.0308L7.02254 18.9549V19.183H24.3648V24.7411H0ZM14.1165 29.5867V23.0594L14.2592 20.6509V0.399201H20.8678V29.5867H14.1165Z"
    />
    <path
      fill={devilsGrass}
      d="M29.3647 30C28.337 30 27.4568 29.639 26.7241 28.9169C26.0009 28.1948 25.644 27.3207 25.6536 26.2946C25.644 25.2874 26.0009 24.4276 26.7241 23.715C27.4568 22.9929 28.337 22.6319 29.3647 22.6319C30.3352 22.6319 31.1917 22.9929 31.9339 23.715C32.6856 24.4276 33.0662 25.2874 33.0758 26.2946C33.0662 26.9786 32.8854 27.601 32.5334 28.1615C32.1908 28.7221 31.7388 29.1686 31.1774 29.5012C30.6255 29.8337 30.0212 30 29.3647 30Z"
    />
    <path
      fill={devilsGrass}
      d="M45.5942 0.000153253C47.1928 0.000153253 48.72 0.261435 50.1759 0.783997C51.6318 1.29706 52.9307 2.11891 54.0726 3.24954C55.2145 4.37068 56.1137 5.8481 56.7703 7.68182C57.4269 9.51554 57.7551 11.7531 57.7551 14.3944C57.7647 16.8267 57.4792 19.0072 56.8987 20.9359C56.3183 22.8551 55.4857 24.4893 54.4009 25.8385C53.3256 27.1877 52.0315 28.2185 50.5185 28.9311C49.0055 29.6342 47.3165 29.9857 45.4514 29.9857C43.396 29.9857 41.5833 29.5915 40.0132 28.8029C38.4527 28.0048 37.2014 26.9311 36.2593 25.582C35.3268 24.2328 34.7701 22.7221 34.5893 21.0499H41.5405C41.7593 22.019 42.2209 22.7506 42.925 23.2447C43.6292 23.7292 44.4713 23.9715 45.4514 23.9715C47.2404 23.9715 48.5773 23.1972 49.4623 21.6485C50.3472 20.0998 50.7897 17.9953 50.7897 15.335H50.6184C50.2092 16.2091 49.6288 16.9597 48.8771 17.5868C48.1348 18.2138 47.2737 18.6936 46.2935 19.0262C45.3134 19.3587 44.281 19.525 43.1962 19.525C41.4453 19.525 39.899 19.1259 38.5573 18.3279C37.2251 17.5298 36.1784 16.4324 35.4172 15.0357C34.6654 13.639 34.2848 12.0428 34.2753 10.2471C34.2658 8.23289 34.7368 6.45618 35.6884 4.91699C36.6399 3.36831 37.9674 2.16166 39.6707 1.29706C41.374 0.422954 43.3485 -0.00934818 45.5942 0.000153253ZM45.637 5.4158C44.7615 5.4158 43.9813 5.62008 43.2961 6.02863C42.6205 6.43717 42.0876 6.98824 41.6975 7.68182C41.3074 8.37541 41.117 9.1545 41.1266 10.0191C41.1361 10.8837 41.3311 11.6628 41.7118 12.3564C42.1019 13.05 42.63 13.601 43.2961 14.0096C43.9717 14.4181 44.7425 14.6224 45.6084 14.6224C46.2555 14.6224 46.855 14.5036 47.4069 14.2661C47.9588 14.0286 48.4393 13.7008 48.8485 13.2827C49.2577 12.8552 49.5765 12.3611 49.8048 11.8006C50.0427 11.24 50.1569 10.6414 50.1474 10.0049C50.1379 9.15925 49.938 8.38966 49.5479 7.69608C49.1578 7.00249 48.6201 6.45143 47.935 6.04288C47.2594 5.62483 46.4934 5.4158 45.637 5.4158Z"
    />
    <path
      fill={devilsGrass}
      d="M84.8752 0.820126C85.0515 0.467852 85.5549 0.467852 85.7312 0.820126L90.0706 9.49063C90.1407 9.63069 90.275 9.72756 90.4302 9.74994L100.125 11.1476C100.519 11.2044 100.675 11.689 100.388 11.9647L93.3816 18.6958C93.2671 18.8058 93.2148 18.9655 93.242 19.1218L94.8965 28.6363C94.9642 29.0259 94.5558 29.3243 94.2045 29.142L85.5238 24.6395C85.3855 24.5678 85.2209 24.5678 85.0826 24.6395L76.402 29.142C76.0506 29.3243 75.6422 29.0259 75.7099 28.6363L77.3645 19.1218C77.3916 18.9655 77.3393 18.8058 77.2248 18.6958L70.2179 11.9647C69.931 11.689 70.0872 11.2044 70.4813 11.1476L80.1762 9.74994C80.3314 9.72756 80.4657 9.63069 80.5358 9.49063L84.8752 0.820126Z"
    />
    <path
      fill={devilsGrass}
      d="M121.241 0.820126C121.418 0.467852 121.921 0.467852 122.097 0.820126L126.437 9.49063C126.507 9.63069 126.641 9.72756 126.796 9.74994L136.491 11.1476C136.885 11.2044 137.042 11.689 136.755 11.9647L129.748 18.6958C129.633 18.8058 129.581 18.9655 129.608 19.1218L131.263 28.6363C131.33 29.0259 130.922 29.3243 130.571 29.142L121.89 24.6395C121.752 24.5678 121.587 24.5678 121.449 24.6395L112.768 29.142C112.417 29.3243 112.008 29.0259 112.076 28.6363L113.731 19.1218C113.758 18.9655 113.705 18.8058 113.591 18.6958L106.584 11.9647C106.297 11.689 106.453 11.2044 106.847 11.1476L116.542 9.74994C116.697 9.72756 116.832 9.63069 116.902 9.49063L121.241 0.820126Z"
    />
    <path
      fill={devilsGrass}
      d="M157.607 0.820126C157.784 0.467852 158.287 0.467852 158.463 0.820126L162.803 9.49063C162.873 9.63069 163.007 9.72756 163.162 9.74994L172.857 11.1476C173.251 11.2044 173.408 11.689 173.121 11.9647L166.114 18.6958C165.999 18.8058 165.947 18.9655 165.974 19.1218L167.629 28.6363C167.696 29.0259 167.288 29.3243 166.937 29.142L158.256 24.6395C158.118 24.5678 157.953 24.5678 157.815 24.6395L149.134 29.142C148.783 29.3243 148.374 29.0259 148.442 28.6363L150.097 19.1218C150.124 18.9655 150.071 18.8058 149.957 18.6958L142.95 11.9647C142.663 11.689 142.819 11.2044 143.213 11.1476L152.908 9.74994C153.064 9.72756 153.198 9.63069 153.268 9.49063L157.607 0.820126Z"
    />
    <path
      fill={devilsGrass}
      d="M193.973 0.820126C194.15 0.467852 194.653 0.467852 194.829 0.820126L199.169 9.49063C199.239 9.63069 199.373 9.72756 199.528 9.74994L209.223 11.1476C209.617 11.2044 209.774 11.689 209.487 11.9647L202.48 18.6958C202.365 18.8058 202.313 18.9655 202.34 19.1218L203.995 28.6363C204.062 29.0259 203.654 29.3243 203.303 29.142L194.622 24.6395C194.484 24.5678 194.319 24.5678 194.181 24.6395L185.5 29.142C185.149 29.3243 184.74 29.0259 184.808 28.6363L186.463 19.1218C186.49 18.9655 186.438 18.8058 186.323 18.6958L179.316 11.9647C179.029 11.689 179.185 11.2044 179.58 11.1476L189.274 9.74994C189.43 9.72756 189.564 9.63069 189.634 9.49063L193.973 0.820126Z"
    />
    <path
      fill={devilsGrass}
      d="M230.339 0.820126C230.516 0.467852 231.019 0.467852 231.196 0.820126L235.535 9.49063C235.605 9.63069 235.739 9.72756 235.895 9.74994L245.589 11.1476C245.984 11.2044 246.14 11.689 245.853 11.9647L238.846 18.6958C238.731 18.8058 238.679 18.9655 238.706 19.1218L240.361 28.6363C240.428 29.0259 240.02 29.3243 239.669 29.142L230.988 24.6395C230.85 24.5678 230.685 24.5678 230.547 24.6395L221.866 29.142C221.515 29.3243 221.106 29.0259 221.174 28.6363L222.829 19.1218C222.856 18.9655 222.804 18.8058 222.689 18.6958L215.682 11.9647C215.395 11.689 215.551 11.2044 215.946 11.1476L225.64 9.74994C225.796 9.72756 225.93 9.63069 226 9.49063L230.339 0.820126Z"
    />
  </svg>
)

export default Stars
