// extracted by mini-css-extract-plugin
export var Interface_SemiBold_XL = "typography-module--Interface_SemiBold_XL--876a5";
export var Neutral_8 = "#0C0C0C";
export var bleachedSilk = "#F2F2F2";
export var colors = "\"@theme/colors.module.css\"";
export var fs11_fw600 = "typography-module--fs11_fw600--fce4e";
export var squant = "#666666";
export var styles_divisor = "JobResultsPanel-module--styles_divisor--b304a";
export var styles_divisorContainer = "JobResultsPanel-module--styles_divisorContainer--4c1d3";
export var styles_resultsSubTitle = "JobResultsPanel-module--styles_resultsSubTitle--62d4b typography-module--typeface_base--8d193 typography-module--fs11_fw600--fce4e";
export var styles_resultsTitle = "JobResultsPanel-module--styles_resultsTitle--0cfa7 typography-module--typeface_base--8d193 typography-module--Interface_SemiBold_XL--876a5";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "'@theme/typography.module.css'";