import React, { FunctionComponent } from 'react'

import { IDividerProps } from './Divider.types'

import {
  style_component,
  style_blank,
  style_divider
} from './Divider.module.css'

const Divider: FunctionComponent<IDividerProps> = ({ label, isBlank }) => {
  const dividerClassNames = [
    style_component,
    isBlank ? style_blank : style_divider
  ].join(' ')
  return (
    <div className={dividerClassNames} data-testid="Divider">
      {label && <span>{label}</span>}
    </div>
  )
}

export default Divider
