// extracted by mini-css-extract-plugin
export var Display_64 = "typography-module--Display_64--9d2f7";
export var Interface_SemiBold_L = "typography-module--Interface_SemiBold_L--52185";
export var Neutral_1 = "#EFEFEF";
export var Neutral_2 = "#CFCFCF";
export var Neutral_5 = "#707070";
export var Neutral_8 = "#0C0C0C";
export var breakpointSM = "768px";
export var breakpoints = "'@theme/breakpoints.module.css'";
export var colors = "\"@theme/colors.module.css\"";
export var gradient_black_Neutral_8 = "linear-gradient(156.48deg, rgba(255, 255, 255, 0.08) -0.36%, rgba(12, 12, 12, 0.08) 67.51%), #0C0C0C";
export var style_active = "AuthModal-module--style_active--25dff";
export var style_brand = "AuthModal-module--style_brand--fcd81";
export var style_brandMobile = "AuthModal-module--style_brandMobile--008d3";
export var style_close = "AuthModal-module--style_close--9f021";
export var style_component = "AuthModal-module--style_component--b46b8 typography-module--typeface_base--8d193";
export var style_header = "AuthModal-module--style_header--4322d";
export var style_heading = "AuthModal-module--style_heading--645eb typography-module--Display_64--9d2f7";
export var style_images = "AuthModal-module--style_images--8087d";
export var style_left = "AuthModal-module--style_left--825d2";
export var style_right = "AuthModal-module--style_right--ba331";
export var style_rightInner = "AuthModal-module--style_rightInner--74d29";
export var style_subheading = "AuthModal-module--style_subheading--0ae90";
export var style_tab = "AuthModal-module--style_tab--c1850 typography-module--Interface_SemiBold_L--52185";
export var style_tabs = "AuthModal-module--style_tabs--b9ed9";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "\"@theme/typography.module.css\"";
export var white = "#FFFFFF";