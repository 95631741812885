// extracted by mini-css-extract-plugin
export var Interface_SemiBold_M = "typography-module--Interface_SemiBold_M--61495";
export var Interface_SemiBold_XL = "typography-module--Interface_SemiBold_XL--876a5";
export var Ocean_4 = "#6052CD";
export var Ocean_6 = "#2E247B";
export var colors = "'@theme/colors.module.css'";
export var faqContactImage = "'@assets/faq-contact.png'";
export var gradient_Ocean_6_Ocean_6 = "linear-gradient(266.57deg, rgba(46, 36, 123, 0.8) 2.06%, rgba(46, 36, 123, 0) 85.27%)";
export var style_contact = "FaqCardList-module--style_contact--911f0 typography-module--typeface_base--8d193 typography-module--Interface_SemiBold_XL--876a5";
export var style_contactButton = "FaqCardList-module--style_contactButton--ea7b1 typography-module--Interface_SemiBold_M--61495";
export var typeface_base = "typography-module--typeface_base--8d193";
export var typography = "'@theme/typography.module.css'";
export var white = "#FFFFFF";