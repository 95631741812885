import React, { Suspense } from 'react'
import { navigate } from 'gatsby'

import { AuthButton } from '@containers/AuthButton'
import { AvatarMenu } from '@components/app/Header/Desktop/AvatarMenu'
import { Avatar } from '@components/redesign/Avatar'
import { Button } from '@components/redesign/Button'
import { MainMenu } from '@components/redesign/MainMenu'
import { ProfileAvatar } from '@components/redesign/ProfileAvatar'

import { useAuthContext } from '@hooks/useAuthContext'
import { useFeatureFlags } from '@hooks/useFeatureFlags'
import { getProfileUpdateProgress } from '@utils/profileHelpers'
import AvatarPlaceholder from '@assets/redesign/avatar.svg'

import {
  popperOpenAnimation,
  popperCloseAnimation,
  style_authButton,
  style_authButtons,
  style_avatar,
  style_avatarContainer,
  style_hambugerMenu
} from './HeaderAvatar.module.css'
import { black, bleachedSilk } from '@theme/colors.module.css'

const Popper = React.lazy(() => import('@components/generic/Popper/Popper'))

const HeaderAvatar = ({ showLoginForm, showCreateAccountForm }) => {
  const { user, profile, loading, signOut } = useAuthContext()
  const { features } = useFeatureFlags()

  const isMenuUpdateFeatureEnabled = Boolean(features?.RELEASE_MENU_UPDATE)

  const isLoading = !user && loading
  const isAuthorized = Boolean(user) && !loading

  if (!isAuthorized) {
    return (
      <div className={style_authButtons}>
        {isLoading ? (
          <Button
            className={style_authButton}
            color="default"
            pill
            disabled={loading}
          >
            Loading...
          </Button>
        ) : (
          <>
            <AuthButton showAuthScreens={showLoginForm}>
              <Button
                data-value="login-button-header"
                className={style_authButton}
                to="/login/"
                color="link"
                disabled={loading}
              >
                Log in
              </Button>
            </AuthButton>
            <AuthButton
              showAuthScreens={showCreateAccountForm}
              isCreateAccountFormVisible
            >
              <Button
                data-value="get-started-button-header"
                className={style_authButton}
                to="/signup/"
                color="default"
                pill
                disabled={loading}
              >
                Get Started
              </Button>
            </AuthButton>
          </>
        )}
      </div>
    )
  }

  const imageSrc = profile?.image?.url || profile?.socialProfileImageUrl
  const src = imageSrc || AvatarPlaceholder

  if (isMenuUpdateFeatureEnabled) {
    const hasProfileNotification = profile && getProfileUpdateProgress(profile) < 100
    const handleSignOut = e => {
      e?.preventDefault()
      navigate('/')
      signOut(true)
    }

    return (
      <Suspense fallback={<div />}>
        <Popper
          classes={{
            openAnimation: popperOpenAnimation,
            closeAnimation: popperCloseAnimation
          }}
          content={(
            <MainMenu
              navigate={navigate}
              signOut={handleSignOut}
              profileEmail={profile?.email}
              hasProfileNotification={hasProfileNotification}
            />
          )}
        >
          <ProfileAvatar
            fullname={profile?.fullName}
            imageSrc={imageSrc}
            hasProfileNotification={hasProfileNotification}
          />
        </Popper>
      </Suspense>
    )
  }

  return (
    <Suspense fallback={<div />}>
      <Popper
        classes={{
          openAnimation: popperOpenAnimation,
          closeAnimation: popperCloseAnimation
        }}
        content={<AvatarMenu />}
      >
        <div data-testid="avatar" className={style_avatarContainer}>
          <div className={style_hambugerMenu}></div>
          <Avatar
            alt={profile?.fullName}
            className={style_avatar}
            color={bleachedSilk}
            textColor={black}
            maxInitials={2}
            src={src}
            textSizeRatio={2.5}
            size={34}
            round
          />
        </div>
      </Popper>
    </Suspense>
  )
}

export default HeaderAvatar
