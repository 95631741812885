import React, {
  useCallback,
  useEffect,
  useMemo,
  FunctionComponent,
  PropsWithChildren,
  useState
} from 'react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosResponse, AxiosError } from 'axios'

import { useAuthContext } from '@hooks/useAuthContext'
import { useFeatureFlags } from '@hooks/useFeatureFlags'
import { useJobsContext } from '@hooks/useJobsContext'
import {
  applyForJob,
  fetchOwnJobApplicationsByQuery,
  reapplyForJob,
  withdrawFromJob
} from '@services/jobApplication'
import { IHandleApiErrorRes } from '@utils/apiHelpers'
import { isPendingJobApplication } from '@utils/jobHelpers'
import { JOB_APPLICATION_STAGES } from '@config/constants'

import JobApplicationsContext from './JobApplicationsContext'

import { MutationResType } from './JobApplicationProvider.types'
import { IJobApplication } from '@type/jobApplication'

const JobApplicationsProvider: FunctionComponent<PropsWithChildren> = ({
  children
}) => {
  const queryClient = useQueryClient()

  const { features } = useFeatureFlags()
  const { profile } = useAuthContext()
  const { selectedJob } = useJobsContext()

  const [previousJobApplication, setPreviousJobApplication] = useState<
    IJobApplication | undefined
  >()
  const [activeJobApplication, setActiveJobApplication] = useState<
    IJobApplication
  >()
  const [isSuccessfulApplication, setIsSuccessfulApplication] = useState(false)
  const [applyingJobId, setApplyingJobId] = useState<number>()
  const [reapplyingJobId, setReapplyingJobId] = useState<number>()
  const [jobApplicationsUpdated, setJobApplicationsUpdated] = useState<boolean>(
    false
  )

  const {
    data: jobApplicationsRes,
    isLoading: isLoadingJobApplications,
    error: jobApplicationsError
  } = useQuery({
    queryKey: ['jobApplications'],
    queryFn: () =>
      fetchOwnJobApplicationsByQuery({ populate: 'job' }) as Promise<
        AxiosResponse<IJobApplication[]>
      >,
    enabled: Boolean(profile?.id)
  })

  const jobApplications = useMemo(() => jobApplicationsRes?.data || [], [
    jobApplicationsRes
  ])

  const applyJobMutation = useMutation<
    MutationResType<IJobApplication>,
    IHandleApiErrorRes,
    number
  >({
    mutationFn: jobId => applyForJob(jobId),
    onSuccess: () => {
      setApplyingJobId(undefined)
      queryClient.invalidateQueries({ queryKey: ['jobApplications'] })
    },
    onError: error => {
      alert(error)
    }
  })

  const applyJob = (values: any, callbacks?: any) => {
    applyJobMutation.mutate(values, callbacks)
  }

  const reapplyJobMutation = useMutation<
    MutationResType<IJobApplication>,
    AxiosError,
    number
  >({
    mutationFn: jobApplicationId => reapplyForJob(jobApplicationId),
    onSuccess: () => {
      setReapplyingJobId(undefined)
      queryClient.invalidateQueries({ queryKey: ['jobApplications'] })
    },
    onError: error => {
      alert(error)
    }
  })

  const reapplyJob = (values: any, callbacks?: any) => {
    reapplyJobMutation.mutate(values, callbacks)
  }

  const withdrawJobMutation = useMutation<
    MutationResType<IJobApplication>,
    AxiosError,
    number
  >({
    mutationFn: jobApplicationId => withdrawFromJob(jobApplicationId),
    onSuccess: async () => {
      if (applyingJobId) {
        await applyJob(applyingJobId)
      } else if (reapplyingJobId) {
        await reapplyJob(reapplyingJobId)
      } else {
        queryClient.invalidateQueries({ queryKey: ['jobApplications'] })
      }
    },
    onError: error => {
      alert(error)
    }
  })

  const withdrawJob = (values: any, callbacks: any) => {
    withdrawJobMutation.mutate(values, callbacks)
  }

  const getIsSuccessfulApplication = useCallback(
    jobId =>
      jobApplications?.findIndex(
        ({ stage, job }) =>
          job?.id === jobId && stage === JOB_APPLICATION_STAGES.SUCCESSFUL
      ) >= 0,
    [jobApplications]
  )

  useEffect(() => {
    setJobApplicationsUpdated(false)

    if (!selectedJob || (!selectedJob && !jobApplications)) {
      return
    }

    if (jobApplications.length) {
      const previousJobApplication = jobApplications.find(
        ja => ja.job.id === selectedJob.id
      )

      const activeJobApplication = jobApplications.find(isPendingJobApplication)

      setPreviousJobApplication(previousJobApplication)
      setActiveJobApplication(activeJobApplication)
      setIsSuccessfulApplication(getIsSuccessfulApplication(selectedJob.id))
    }
    setJobApplicationsUpdated(true)
  }, [jobApplications, selectedJob, features, getIsSuccessfulApplication])

  useEffect(() => {
    if (jobApplicationsError) {
      alert(jobApplicationsError)
    }
  }, [jobApplicationsError])

  const contextValue = {
    jobApplicationsUpdated,
    isLoadingJobApplications,
    jobApplications,
    applyJob,
    withdrawJob,
    reapplyJob,
    reapplyingForJob: reapplyJobMutation.isLoading,
    processing: applyJobMutation.isLoading || withdrawJobMutation.isLoading,
    activeJobApplication,
    previousJobApplication,
    isSuccessfulApplication,
    setApplyingJobId,
    setReapplyingJobId
  }

  return (
    <JobApplicationsContext.Provider value={contextValue}>
      {children}
    </JobApplicationsContext.Provider>
  )
}

export default JobApplicationsProvider
