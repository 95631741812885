import React from 'react'

import { squant } from '@theme/colors.module.css'

const Plus = props => (
  <svg
    fill={squant}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="20px"
    height="20px"
    {...props}
  >
    <path
      d="M 12 0.960938 C 5.910156 0.960938 0.960938 5.910156 0.960938 12 C 0.960938 18.089844 5.910156 23.039062 12 23.039062 C 18.089844 23.039062 23.039062 18.089844 23.039062 12 C 23.039062 5.910156 18.089844 0.960938 12 0.960938 Z M 12 1.921875 C 17.574219 1.921875 22.078125 6.425781 22.078125 12 C 22.078125 17.574219 17.574219 22.078125 12 22.078125 C 6.425781 22.078125 1.921875 17.574219 1.921875 12 C 1.921875 6.425781 6.425781 1.921875 12 1.921875 Z M 11.519531 6.238281 L 11.519531 11.519531 L 6.238281 11.519531 L 6.238281 12.480469 L 11.519531 12.480469 L 11.519531 17.761719 L 12.480469 17.761719 L 12.480469 12.480469 L 17.761719 12.480469 L 17.761719 11.519531 L 12.480469 11.519531 L 12.480469 6.238281 Z M 11.519531 6.238281"
      stroke={squant}
    />
  </svg>
)

export default Plus
